import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';
import {FindToolsInText} from '../Tools/FindToolsInText';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SelectTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTools: [],
      tools: [],
      allToolsNotModified: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;

    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else {
      VideoHelper.setRoute(ServerRoutes.drafts);
    }

    HttpHelper.get(ServerRoutes.tools).then(tools => {
      const options = tools.map(tool => {
        return {
          label: tool.code + ' - ' + tool.frenchName + ' - ' + tool.englishName,
          displayedLabel: tool.code + ' - ' + tool.frenchName,
          value: tool.code,
        };
      });
      this.setState({tools: options, allToolsNotModified: tools});

      VideoHelper.getTemplate(code).then(template => {
        this.template = template;
        const selected = JSON.parse(template.tools).map(tool => {
          const toolObject = tools.filter(t => t.code === tool)[0];
          return {
            label:
              toolObject.code +
              ' - ' +
              toolObject.frenchName +
              ' - ' +
              toolObject.englishName,
            displayedLabel: toolObject.code + ' - ' + toolObject.frenchName,
            value: toolObject.code,
          };
        });
        this.setState({selectedTools: selected});
      });
    });
  }

  submit() {
    this.template.tools = JSON.stringify(
      this.state.selectedTools.map(t => t.value),
    );
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  addToSelectedTools = newTools => {
    // Goal of following function is to remove "douille" when "douille de 22" has been found
    const removedNestedTool = newTools.reduce((accumulator, currentValue) => {
      if (
        !newTools
          .filter(e => e !== currentValue)
          .some(el => {
            return (
              el.label
                .split('-')[1]
                .trim()
                .indexOf(currentValue.label.split('-')[1].trim()) > -1
            );
          })
      ) {
        accumulator.push(currentValue);
        return accumulator;
      } else {
        return accumulator;
      }
    }, []);

    const noDuplicates = removedNestedTool.filter(
      tool => !this.state.selectedTools.map(t => t.value).includes(tool.value),
    );
    const merged = [...this.state.selectedTools, ...noDuplicates];
    this.setState({
      selectedTools: merged,
    });
  };

  render() {
    return (
      <div style={{padding: 10}}>
        <form
          noValidate
          style={{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
          autoComplete="off">
          <Select
            options={this.state.tools}
            formatOptionLabel={option => option.displayedLabel || option.label}
            isMulti
            value={this.state.selectedTools}
            onChange={value => {
              this.setState({selectedTools: value});
            }}
            placeholder="Add tools"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 20,
              marginBottom: 40,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>
        <FindToolsInText
          allTools={this.state.allToolsNotModified}
          addToSelectedTools={this.addToSelectedTools}
        />
      </div>
    );
  }
}

SelectTools.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectTools);
