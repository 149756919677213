import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from './styles/theme';
import registerServiceWorker from './registerServiceWorker';
import 'normalize.css';

import AppBar from './AppBar/AppBar';
import {Router} from 'react-router-dom';
import Routing from './routing/Routing';
import {Provider} from 'react-redux';
import Toast from 'react-redux-toastr';
import history from './routing/history';
import {createStore, combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
const reducers = {
  toastr: toastrReducer,
};
const reducer = combineReducers(reducers);
const store = createStore(reducer);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <div>
              <AppBar />

              <Routing />
            </div>
          </Router>

          <Toast
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            closeOnToastrClick
          />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
