import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import TextField from '@material-ui/core/TextField';
import Delete from '@material-ui/icons/Clear';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

let that;

class Timestamps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timestamps: [],
      newTimestamp: '',
    };
    that = this;
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    VideoHelper.setRoute(ServerRoutes.drafts);
    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      const selected = template.timestamps
        ? JSON.parse(template.timestamps)
        : [];
      this.setState({timestamps: selected});
    });
  }

  updateTimestamp(value) {
    if (value[value.length - 1] === ' ' && value.trim().length) {
      let updated = this.state.timestamps;
      updated = updated.concat(
        value
          .trim()
          .split('\t')
          .map(v => v.trim()),
      );
      this.setState({timestamps: updated.sort(), newTimestamp: ''});
    } else {
      this.setState({newTimestamp: value.trim()});
    }
  }

  static handleDelete(e) {
    const updated = that.state.timestamps;
    updated.splice(e, 1);
    that.setState({timestamps: updated});
  }

  submit() {
    this.template.timestamps = JSON.stringify(this.state.timestamps);
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    const {classes} = this.props;
    return (
      <form
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        autoComplete="off">
        <h3>Add timestamp and press space to add</h3>
        <TextField
          id="outlined-name"
          value={this.state.newTimestamp}
          className={classes.textField}
          label={'add timestamp'}
          onChange={e => {
            this.updateTimestamp(e.target.value);
          }}
          margin="normal"
          variant="outlined"
        />
        {this.state.timestamps.map((t, key) => (
          <div
            style={{
              padding: 10,
              backgroundColor: '#e0e0e0',
              display: 'inline-flex',
              alignItems: 'center',
              margin: 8,
              borderRadius: 16,
            }}>
            {t} <Delete onClick={() => Timestamps.handleDelete(key)} />
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

Timestamps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Timestamps);
