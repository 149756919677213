import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
});

class CarParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carParts: [],
      filteredCarParts: [],
    };
  }

  componentDidMount() {
    HttpHelper.get(ServerRoutes.carParts).then(carParts => {
      this.setState({carParts: carParts, filteredCarParts: carParts});
    });
  }

  render() {
    const classes = this.props;
    return (
      <div className={classes.root}>
        <Link
          to={'/carPart'}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button style={{width: '250px'}}>Create new car part</Button>
        </Link>

        <TextField
          style={{margin: 20}}
          id="outlined-name"
          label={'filter'}
          value={this.state.filter}
          className={classes.textField}
          onChange={e => {
            const filtered =
              e.target.value.trim().length > 0
                ? this.state.carParts.filter(
                    t =>
                      (
                        t.code +
                        t.englishName.toLowerCase() +
                        t.frenchName.toLowerCase()
                      ).indexOf(e.target.value) > -1,
                  )
                : this.state.carParts;
            this.setState({filter: e.target.value, filteredCarParts: filtered});
          }}
          margin="normal"
          variant="outlined"
        />
        <List subheader={<ListSubheader>CarParts</ListSubheader>}>
          {this.state.filteredCarParts.map(carPart => (
            <ListItem>
              <ListItemText
                primary={carPart.code + ' ' + carPart.englishName}
              />
              <ListItemSecondaryAction>
                <Link to={'/carPart/' + carPart.code}>
                  <Edit />
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

CarParts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarParts);
