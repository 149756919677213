import React, {Component} from 'react';

import MotorizationDataService from './motorizationsDataService';
import {toastr} from 'react-redux-toastr';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

class NotificationsStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      motorizations: [],
      frenchTitle: '',
      frenchText: '',
      englishTitle: '',
      englishText: '',
      spanishTitle: '',
      spanishText: '',
      italianTitle: '',
      italianText: '',
      germanTitle: '',
      germanText: '',
    };
    this.areAllUsersSelected = false;
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      motorizations: MotorizationDataService.getData(),
    });
    this.areAllUsersSelected = MotorizationDataService.getAreAllUsersSelected();
  }

  onSubmit(e) {
    e.preventDefault();

    const token = window.localStorage.getItem('token');

    if (this.areAllUsersSelected) {
      fetch('/api/notifications/sendToAllUsers', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'X-Auth-Token': token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.ok) {
          toastr.success('Success', 'Notifications have been sent');
        } else {
          toastr.error('Error', 'Please retry later');
        }
      });
    } else {
      fetch('/api/notifications/send', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'X-Auth-Token': token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.ok) {
          toastr.success('Success', 'Notifications have been sent');
        } else {
          toastr.error('Error', 'Please retry later');
        }
      });
    }
  }

  render() {
    let reminder;

    if (this.areAllUsersSelected) {
      reminder = (
        <div>
          <b>Notification will be send to all users</b>
        </div>
      );
    } else {
      reminder = (
        <div style={{marginBottom: 20}}>
          <b>Reminder of the chosen motorizations:</b>
          <br />
          {this.state.motorizations.map(motorization => (
            <div key={motorization.motorization}>
              <span key={motorization.motorization} />
              <b>
                {_.upperFirst(motorization.carCode)} {motorization.motorization}
              </b>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 50,
          placeItems: 'center',
        }}>
        {reminder}

        <form onSubmit={this.onSubmit}>
          <TextField
            label="French title"
            name="title"
            type="text"
            value={this.state.frenchTitle}
            onChange={e => this.setState({frenchTitle: e.target.value})}
          />

          <TextField
            label="French text"
            name="text"
            type="text"
            value={this.state.frenchText}
            style={{marginLeft: 20}}
            onChange={e => this.setState({frenchText: e.target.value})}
          />

          <br />

          <TextField
            label="English title"
            name="title"
            type="text"
            value={this.state.englishTitle}
            onChange={e => this.setState({englishTitle: e.target.value})}
          />

          <TextField
            label="English text"
            name="text"
            type="text"
            value={this.state.englishText}
            style={{marginLeft: 20}}
            onChange={e => this.setState({englishText: e.target.value})}
          />

          <br />
          <TextField
            label="Spanish title"
            name="title"
            type="text"
            value={this.state.spanishTitle}
            onChange={e => this.setState({spanishTitle: e.target.value})}
          />

          <TextField
            label="Spanish text"
            name="text"
            type="text"
            value={this.state.spanishText}
            style={{marginLeft: 20}}
            onChange={e => this.setState({spanishText: e.target.value})}
          />

          <br />
          <TextField
            label="Italian title"
            name="title"
            type="text"
            value={this.state.italianTitle}
            onChange={e => this.setState({italianTitle: e.target.value})}
          />

          <TextField
            label="Italian text"
            name="text"
            type="text"
            value={this.state.italianText}
            style={{marginLeft: 20}}
            onChange={e => this.setState({italianText: e.target.value})}
          />

          <br />
          <TextField
            label="German title"
            name="title"
            type="text"
            value={this.state.germanTitle}
            onChange={e => this.setState({germanTitle: e.target.value})}
          />

          <TextField
            label="German text"
            name="text"
            type="text"
            value={this.state.germanText}
            style={{marginLeft: 20}}
            onChange={e => this.setState({germanText: e.target.value})}
          />

          <div style={{display: 'flex', marginTop: 20, placeContent: 'center'}}>
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </div>
    );
  }
}

export default NotificationsStep2;
