import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import _ from 'lodash';
import VideoHelper from './VideoHelper';

export const regenerateDraftTitlesFromTemplate = async draftCode => {
  const carCode = draftCode.split('.')[0];
  const templateCode = draftCode.split('.')[1] + '.' + draftCode.split('.')[2];

  const car = await HttpHelper.get(ServerRoutes.findByCode(carCode));
  const draft = await HttpHelper.get(ServerRoutes.drafts + '/' + draftCode);
  const template = await HttpHelper.get(
    ServerRoutes.templates + '/' + templateCode,
  );

  const templateTitles = JSON.parse(template.names);
  const youtubeTitles = templateTitles.youtube;

  const newDraft = {
    ...draft,
    names: JSON.stringify({
      youtube: _.mapValues(youtubeTitles, title =>
        title
          .replaceAll('{{model}}', car.name)
          .replaceAll('{{brand}}', car.brand),
      ),
      application: {
        ...templateTitles.application,
      },
    }),
  };

  VideoHelper.setRoute(ServerRoutes.drafts);
  VideoHelper.setTemplate(newDraft);
  await VideoHelper.save();
};
