import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SelectCarParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarParts: [],
      carParts: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);

    HttpHelper.get(ServerRoutes.carParts).then(carParts => {
      const options = carParts.map(carPart => {
        return {
          label: carPart.code + ' - ' + carPart.englishName,
          value: carPart.code,
        };
      });
      this.setState({carParts: options});
      VideoHelper.getTemplate(code).then(template => {
        this.template = template;
        const selected = JSON.parse(template.parts).map(carPart => {
          const carPartObject = carParts.filter(t => t.code === carPart)[0];
          return {
            label: carPartObject.code + ' - ' + carPartObject.englishName,
            value: carPartObject.code,
          };
        });
        this.setState({selectedCarParts: selected});
      });
    });
  }

  submit() {
    this.template.parts = JSON.stringify(
      this.state.selectedCarParts.map(t => t.value),
    );
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    return (
      <form
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        autoComplete="off">
        <Select
          options={this.state.carParts}
          isMulti
          value={this.state.selectedCarParts}
          onChange={value => {
            this.setState({selectedCarParts: value});
          }}
          placeholder="Add car parts"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

SelectCarParts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectCarParts);
