import React from 'react';
import history from '../routing/history';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {toastr} from 'react-redux-toastr';
import {Link} from 'react-router-dom';
import pkg from '../../package.json';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    fetch('/api/signIn', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then(handleJsonErrors)
      .then(jsonResponse => {
        window.localStorage.setItem('token', jsonResponse.token);
        history.push('/cars');
        window.location.href = '/cars';
      })
      .catch(() => toastr.error('Login error', 'Please retry later'));
  }

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <TextField
            label="Email"
            variant="outlined"
            value={this.state.email}
            onChange={e => this.setState({email: e.target.value})}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={this.state.password}
            onChange={e => this.setState({password: e.target.value})}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 400,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            <Link to={'/privacyPolicy'} style={{color: 'white'}}>
              Privacy policy
            </Link>
          </Button>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <p>{`v${pkg.version}`}</p>
        </div>
      </form>
    );
  }
}

function handleJsonErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  } else {
    return response.json();
  }
}

export default SignInForm;
