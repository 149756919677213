import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';
import CreateTag from './CreateTag';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class SelectTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      tags: [],
      open: false,
      // copiableTags: [],
    };
  }

  componentDidMount() {
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else {
      VideoHelper.setRoute(ServerRoutes.drafts);
    }

    this.initTags();
  }

  initTags() {
    HttpHelper.get(ServerRoutes.tags).then(tags => {
      const options = tags.map(tag => {
        return {label: JSON.parse(tag.names).en, value: tag.id};
      });
      // this.setState({
      //   tags: options,
      //   copiableTags: replaceVariables(
      //     (tags || []).map(t => t.label).join(', '),
      //   ),
      // });

      const id = this.props.match.params.code.replace(/_/g, '.');

      VideoHelper.getTemplate(id).then(template => {
        this.template = template;
        const selected =
          this.props.match.params.type === 'template'
            ? JSON.parse(template.tags).map(tag => {
                const tagObject = tags.filter(t => t.id === tag)[0];
                return {
                  label: JSON.parse(tagObject.names).en,
                  value: tagObject.id,
                };
              })
            : JSON.parse(template.tags).map(tag => {
                return {label: JSON.parse(tag.names).en, value: tag.id};
              });
        this.setState({selectedTags: selected});
      });
    });
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  submit() {
    this.template.tags = JSON.stringify(
      this.state.selectedTags.map(t => t.value),
    );
    VideoHelper.setTemplate(this.template);
    VideoHelper.save().then(() => {
      this.initTags();
    });
  }

  render() {
    const {fullScreen} = this.props;

    return (
      <div style={{margin: 20}}>
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
          <Button
            variant="contained"
            onClick={this.handleClickOpen}
            color="primary"
            style={{width: '250px'}}>
            Create a new tag
          </Button>
        </div>

        <form
          noValidate
          style={{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
          autoComplete="off">
          <Select
            options={this.state.tags}
            isMulti
            value={this.state.selectedTags}
            onChange={value => {
              this.setState({selectedTags: value});
            }}
            placeholder="Add tags"
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 50,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>
        {/*<div style={styles.tagsText}>*/}
        {/*  {console.log('this.state.tags', this.state.tags.label) ||*/}
        {/*    this.state.copiableTags}*/}
        {/*</div>*/}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'Create tag'}</DialogTitle>
          <DialogContent>
            <CreateTag
              onSubmit={() => {
                this.handleClose();
                this.initTags();
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

SelectTags.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

const styles = {
  tagsText: {
    marginTop: 50,
  },
};

export default withMobileDialog()(SelectTags);
