import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SelectUsefulVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsefulVideos: [],
      usefulVideos: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;

    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else {
      VideoHelper.setRoute(ServerRoutes.drafts);
    }

    const carCode = this.props.match.params.code.split('.')[0];

    HttpHelper.get(ServerRoutes.findCarDrafts(carCode)).then(carVideos => {
      HttpHelper.get(ServerRoutes.usefulVideos).then(usefulVideos => {
        const optionsUsefulVideos = usefulVideos.map(usefulVideo => {
          return {
            label: usefulVideo.code + ' - ' + usefulVideo.englishName,
            value: usefulVideo.code,
          };
        });

        const optionsCarVideos = carVideos.map(video => {
          return {
            label:
              video.code + ' - ' + JSON.parse(video.names).application['en'],
            value: video.code,
          };
        });

        this.setState({
          usefulVideos: optionsUsefulVideos.concat(optionsCarVideos),
        });

        VideoHelper.getTemplate(code).then(template => {
          this.template = template;

          const templateUsefulVideoCodes = JSON.parse(template.usefulVideos);

          const selectedUsefulVideos = templateUsefulVideoCodes
            .filter(code => code.split('.')[0] === '00')
            .map(usefulVideoCode => {
              const usefulVideoObject = usefulVideos.filter(
                t => t.code === usefulVideoCode,
              )[0];

              return {
                label:
                  usefulVideoObject.code +
                  ' - ' +
                  usefulVideoObject.englishName,
                value: usefulVideoObject.code,
              };
            });

          const selectedCarVideos = carVideos
            .filter(c => templateUsefulVideoCodes.includes(c.code))
            .map(video => {
              return {
                label:
                  video.code +
                  ' - ' +
                  JSON.parse(video.names).application['en'],
                value: video.code,
              };
            });

          this.setState({
            selectedUsefulVideos: selectedUsefulVideos.concat(
              selectedCarVideos,
            ),
          });
        });
      });
    });
  }

  submit() {
    this.template.usefulVideos = JSON.stringify(
      this.state.selectedUsefulVideos.map(t => t.value),
    );
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    return (
      <div style={{margin: 20}}>
        <h4>Only useful videos having been published are selectable</h4>

        <form
          noValidate
          style={{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
          autoComplete="off">
          <Select
            options={this.state.usefulVideos}
            isMulti
            value={this.state.selectedUsefulVideos}
            onChange={value => {
              this.setState({selectedUsefulVideos: value});
            }}
            placeholder="Add usefulVideos"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 50,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

SelectUsefulVideos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectUsefulVideos);
