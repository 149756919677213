import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button/Button';
import React, {useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Divider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import ServerRoutes from '../routing/ServerRoutes';
import {toastr} from 'react-redux-toastr';
import HttpHelper from '../routing/HttpHelper';
import uuidv1 from 'uuid';

const styles = theme => ({
  deleteButton: {
    marginLeft: 20,
    backgroundColor: theme.palette.text.primary,
  },
  resetButton: {
    margin: 10,
    backgroundColor: theme.palette.secondary.main,
  },
});

export const AddMotorizations = withStyles(styles)(
  ({carName, classes, addMotorizationsToState}) => {
    const [isCreating, setIsCreating] = useState(false);

    const {control, handleSubmit, reset} = useForm({});
    const {fields, append, remove} = useFieldArray({
      control,
      name: 'motorizations',
    });

    const onSubmit = data => {
      const motorizations = data.motorizations.map(d => ({
        uuid: uuidv1(),
        carCode: carName,
        motorizationType: d.type,
        motorization: d.name,
        periodicityCode: -1,
        isTutorialized: d.isTutorialized === 'true',
      }));

      Promise.all(
        motorizations.map(motorization =>
          HttpHelper.post(
            ServerRoutes.motorizations,
            JSON.stringify(motorization),
          ),
        ),
      )
        .then(responses => {
          if (responses.find(response => !response.ok)) {
            toastr.error('Error', 'An error occurred');
          } else {
            addMotorizationsToState(motorizations);
            toastr.success('Success', 'Motorizations added');
          }
        })
        .catch(() => toastr.error('Error', 'An error occurred'));

      setIsCreating(false);
    };

    return (
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'center',
        }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            {fields.map((item, index) => {
              return (
                <span
                  key={item.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginBottom: 20,
                    marginTop: 20,
                  }}>
                  <Controller
                    render={({field}) => (
                      <TextField
                        {...field}
                        style={{minWidth: 250}}
                        variant="outlined"
                        label={'Motorization name'}
                      />
                    )}
                    name={`motorizations.${index}.name`}
                    control={control}
                  />
                  <div style={{marginLeft: 20}}>
                    <FormLabel component="legend">Motorization type</FormLabel>
                    <Controller
                      render={({field}) => (
                        <RadioGroup
                          {...field}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}>
                          <FormControlLabel
                            value="diesel"
                            control={<Radio />}
                            label="Diesel"
                          />
                          <FormControlLabel
                            value="essence"
                            control={<Radio />}
                            label="Gasoline"
                          />
                        </RadioGroup>
                      )}
                      name={`motorizations.${index}.type`}
                      control={control}
                    />
                  </div>
                  <div style={{marginLeft: 20}}>
                    <FormLabel component="legend">
                      Is motorization tutorialized?
                    </FormLabel>
                    <Controller
                      render={({field}) => (
                        <RadioGroup
                          {...field}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}>
                          <FormControlLabel
                            value={'true'}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={'false'}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                      name={`motorizations.${index}.isTutorialized`}
                      control={control}
                    />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => remove(index)}>
                      Delete
                    </Button>
                  </div>
                  <Divider />
                </span>
              );
            })}
          </div>

          <section style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              style={{margin: 10}}
              onClick={() => {
                setIsCreating(true);
                append({name: '', type: '', isTutorialized: false});
              }}>
              {isCreating
                ? 'Add another motorization'
                : 'Add a new motorization'}
            </Button>

            {isCreating ? (
              <Button
                className={classes.resetButton}
                onClick={() =>
                  reset({
                    motorizations: [],
                  })
                }>
                reset
              </Button>
            ) : (
              <></>
            )}
          </section>

          {isCreating ? <Button type="submit">Submit</Button> : <></>}
        </form>
      </div>
    );
  },
);
