import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Input from '@material-ui/core/Input/Input';
import Modify from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button/Button';
import {toastr} from 'react-redux-toastr';
import MotorizationDashboard from './MotorizationDashboard';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import ListCarDrafts from './ListCarDrafts';
import {Divider} from '@material-ui/core';

class CarDashboard extends Component {
  state = {
    isLoading: true,
    carName: this.props.match.params.carName,
    isCarNameBeingUpdated: false,
    englishCarName: '',
    isEnglishCarNameBeingUpdated: false,
    carCode: '',
    carNumberOfUsers: 0,
    motorizations: [],
    affiliationLinks: {},
    isCarPublishedOnApp: false,
  };

  componentDidMount() {
    HttpHelper.get(ServerRoutes.findCarInfo(this.state.carName)).then(
      carInfo => {
        this.setState({
          isLoading: false,
          carCode: carInfo.car.code,
          carNumberOfUsers: carInfo.numberOfUsers,
          motorizations: carInfo.motorizations,
          isCarPublishedOnApp: carInfo.car.isPublic,
          affiliationLinks: carInfo.car.affiliationLinks,
          englishCarName: carInfo.car.englishName,
        });
      },
    );
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  updateCarName(carName) {
    HttpHelper.update(
      ServerRoutes.updateCarName(carName, this.state.carCode),
    ).then(response => {
      this.setState({isCarNameBeingUpdated: false});
      if (response.ok) {
        toastr.success('Success', 'Car name has been updated');
      } else {
        toastr.error('An error occurred', 'Car name has not been updated');
      }
    });
  }

  updateEnglishCarName(englishCarName) {
    HttpHelper.update(
      ServerRoutes.updateEnglishCarName(englishCarName, this.state.carCode),
    ).then(response => {
      this.setState({isEnglishCarNameBeingUpdated: false});
      if (response.ok) {
        toastr.success('Success', 'English car name has been updated');
      } else {
        toastr.error(
          'An error occurred',
          'English car name has not been updated',
        );
      }
    });
  }

  handleIsCarPublishedOnAppChange() {
    const newIsCarPublishedState = !this.state.isCarPublishedOnApp;

    HttpHelper.update(
      ServerRoutes.updateIsCarPublishedOnApp(
        this.state.carName,
        newIsCarPublishedState,
      ),
    ).then(response => {
      if (response.ok) {
        this.setState({isCarPublishedOnApp: newIsCarPublishedState});
        if (newIsCarPublishedState) {
          toastr.success('Success', 'Car is now published on app');
        } else {
          toastr.success('Success', 'Car is NO MORE published on app');
        }
      } else {
        toastr.error('An error occurred', 'Car status has not been changed');
      }
    });
  }

  render() {
    const carNameTitleOrInput = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        {this.state.isCarNameBeingUpdated ? (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Input
              placeholder="Car name"
              value={this.state.carName}
              name="carName"
              onChange={this.handleChange}
              style={{fontSize: 25}}
            />
            <Button
              type="button"
              onClick={() => this.updateCarName(this.state.carName)}
              style={{marginLeft: 8}}>
              Ok
            </Button>
          </div>
        ) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h1 style={{marginRight: 8, fontSize: 25}}>
              {this.state.carName} (<i>{this.state.carCode}</i>)
            </h1>
            <Modify
              onClick={() => this.setState({isCarNameBeingUpdated: true})}
            />
          </div>
        )}
        {this.state.isEnglishCarNameBeingUpdated ? (
          <div style={{display: 'flex', alignItems: 'center', marginLeft: 8}}>
            <Input
              placeholder="English car name"
              value={this.state.englishCarName}
              name="englishCarName"
              onChange={this.handleChange}
            />
            <Button
              type="button"
              onClick={() =>
                this.updateEnglishCarName(this.state.englishCarName)
              }
              style={{marginLeft: 20}}>
              Ok
            </Button>
          </div>
        ) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: 8, marginLeft: 20}}>
              {this.state.englishCarName || 'No english car name'}
            </span>
            <Modify
              onClick={() =>
                this.setState({isEnglishCarNameBeingUpdated: true})
              }
            />
          </div>
        )}
      </div>
    );

    const isCarPublishedOnApp = (
      <div style={{marginLeft: 20}}>
        <FormLabel component="legend">Is car published on app?</FormLabel>
        <RadioGroup
          aria-label="Is car published on app?"
          name="isCarPublishedOnApp"
          value={this.state.isCarPublishedOnApp ? 'yes' : 'no'}
          onChange={() => this.handleIsCarPublishedOnAppChange()}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
    );

    const numberOfUsers = (
      <p style={{margin: 20}}>
        <b>{this.state.carNumberOfUsers}</b> user
        {this.state.carNumberOfUsers > 1 ? 's' : ''} using this car
      </p>
    );

    return (
      <div>
        {carNameTitleOrInput}

        {isCarPublishedOnApp}

        {numberOfUsers}

        {this.state.isLoading ? null : (
          <div>
            <ListCarDrafts
              carCode={this.state.carCode}
              carName={this.state.carName}
            />

            <Divider />

            <MotorizationDashboard
              carName={this.state.carName}
              motorizations={this.state.motorizations}
            />
          </div>
        )}
      </div>
    );
  }
}

export default CarDashboard;
