import React from 'react';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import HttpHelper from '../routing/HttpHelper';
import DisplayVariables from './TemplateHelper';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

let that;

class CreateTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTag: this.props.tag
        ? VideoHelper.oldTranslationsToNew(JSON.parse(this.props.tag.names))
        : VideoHelper.newTranslation(),
    };
    that = this;
  }

  updateTag(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.newTag));
    updated[key] = value;
    this.setState({newTag: updated});
  }

  submit() {
    let body =
      this.props.tag && this.props.tag.id
        ? JSON.stringify({
            id: this.props.tag.id,
            names: JSON.stringify(this.state.newTag),
          })
        : JSON.stringify({names: JSON.stringify(this.state.newTag)});
    HttpHelper.post(ServerRoutes.tags, body).then(() => {
      if (that.props.onSubmit) {
        that.props.onSubmit();
      }
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        autoComplete="off">
        <div style={{flexDirection: 'row'}}>
          {Object.keys(this.state.newTag).map(key => {
            return (
              <div style={{float: 'left'}}>
                <TextField
                  key={key}
                  id="outlined-name"
                  label={key}
                  value={this.state.newTag[key]}
                  className={classes.textField}
                  onChange={e => {
                    this.updateTag(key, e.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <DisplayVariables
                  value={this.state.newTag[key]}
                  onChange={value => this.updateTag(key, value)}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

CreateTag.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateTag);
