import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
});

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      filteredTools: [],
    };
  }

  componentDidMount() {
    HttpHelper.get(ServerRoutes.tools).then(tools => {
      this.setState({tools: tools, filteredTools: tools});
    });
  }

  render() {
    const classes = this.props;
    return (
      <div className={classes.root}>
        <Link
          to={'/tool'}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button>Create new tool</Button>
        </Link>

        <TextField
          id="outlined-name"
          label={'filter'}
          value={this.state.filter}
          className={classes.textField}
          style={{margin: 20}}
          onChange={e => {
            const filtered =
              e.target.value.trim().length > 0
                ? this.state.tools.filter(
                    t =>
                      (t.code + t.englishName + t.frenchName).indexOf(
                        e.target.value,
                      ) > -1,
                  )
                : this.state.tools;

            this.setState({filter: e.target.value, filteredTools: filtered});
          }}
          variant="outlined"
        />

        <List subheader={<ListSubheader>Tools</ListSubheader>}>
          {this.state.filteredTools.map(tool => (
            <ListItem key={tool.code}>
              <ListItemText primary={tool.code + ' ' + tool.englishName} />

              <ListItemSecondaryAction>
                <Link to={'/tool/' + tool.code}>
                  <Edit />
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

Tools.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tools);
