import React, {useEffect, useState} from 'react';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import {toastr} from 'react-redux-toastr';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});

const UsefulVideo = props => {
  const [operation, setOperation] = useState();

  const updateVideo = async () => {
    try {
      await HttpHelper.update(
        ServerRoutes.usefulVideos,
        JSON.stringify({
          ...operation,
          names: operation.names,
          links: operation.links,
        }),
      );
      toastr.success('Video updated');
    } catch (error) {
      toastr.error('An error occurred saving the video');
    }
  };

  useEffect(() => {
    async function initData() {
      const op = await HttpHelper.get(
        ServerRoutes.findOperations(props.match.params.code),
      );
      setOperation(op);
    }

    initData();
  }, [props.match.params.code]);

  if (!operation) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}>
        <div>
          <Typography variant="h4">Names</Typography>
          {Object.keys(operation.names).map(key => {
            return (
              <div key={key}>
                <TextField
                  key={key}
                  style={{width: '350px'}}
                  id="outlined-name"
                  label={key}
                  value={operation.names[key]}
                  onChange={e =>
                    setOperation({
                      ...operation,
                      names: {...operation.names, [key]: e.target.value},
                    })
                  }
                  margin="normal"
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>

        <div>
          <Typography variant="h4">Links</Typography>
          {Object.keys(operation.links).map(key => {
            return (
              <div key={key}>
                <TextField
                  key={key}
                  style={{width: '350px'}}
                  id="outlined-name"
                  label={key}
                  value={operation.links[key]}
                  onChange={e =>
                    setOperation({
                      ...operation,
                      links: {...operation.links, [key]: e.target.value},
                    })
                  }
                  margin="normal"
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>
      <Button
        style={{marginTop: 30}}
        type="button"
        onClick={() => updateVideo(operation)}>
        Update video
      </Button>
    </div>
  );
};

export default withStyles(styles)(UsefulVideo);
