const removeEmojisFromString = string => {
  try {
    return string
      .replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        '',
      )
      .trim();
  } catch (e) {
    return string || '';
  }
};

export default removeEmojisFromString;
