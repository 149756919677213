export default async function client(endpoint, {body, ...customConfig} = {}) {
  console.info('Fetch called endpoint: ', endpoint);

  const headers = {
    'content-type': 'application/json',
  };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(endpoint, config);
  return response.json();
}
