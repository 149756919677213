import client from '../util/fetch';
import {cloudFunctionsURL} from '../util/constants';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import removeEmojisFromString from '../util/removeEmojisFromString';

const getBitlyLinksFromCarPartsCode = async (
  carCode,
  carPartsCode,
  operationFrenchName,
) => {
  const allCarParts = await HttpHelper.get(ServerRoutes.carParts);

  const links = await Promise.all(
    carPartsCode.map(carPartCode =>
      client(
        cloudFunctionsURL() +
          'getCarPartLinks?carPartCode=' +
          carPartCode +
          '&carCode=' +
          carCode,
      ).catch(e => ({error: 'NotFoundLink', carPartCode})),
    ),
  );

  let customLinks = [];

  const bitLinksPromise = await Promise.all(
    links.map(link => {
      if (link.error === 'NotFoundLink') {
        return {
          error: link.error,
          carPartCode: link.carPartCode,
          links: {
            fr: '',
            en: '',
            es: '',
            de: '',
            it: '',
            pl: '',
          },
        };
      }

      const {carPartCode, ...allLanguagesLink} = link;

      const carPartEnglishName = allCarParts.find(p => p.code === carPartCode)
        .englishName;
      const carPartFrenchName = allCarParts.find(p => p.code === carPartCode)
        .frenchName;
      const carPartSpanishName = allCarParts.find(p => p.code === carPartCode)
        .spanishName;
      const carPartItalianName = allCarParts.find(p => p.code === carPartCode)
        .italianName;
      const carPartGermanName = allCarParts.find(p => p.code === carPartCode)
        .germanName;
      const carPartPolishName = allCarParts.find(p => p.code === carPartCode)
        .polishName;

      const partReference = allLanguagesLink.fr.link.split('/')[
        allLanguagesLink.fr.link.split('/').length - 2
      ];

      customLinks.push({
        en: (carPartEnglishName + partReference + 'en').replaceAll(' ', '-'),
        fr: (carPartFrenchName + partReference + 'fr').replaceAll(' ', '-'),
        es: (carPartSpanishName + partReference + 'es').replaceAll(' ', '-'),
        it: (carPartItalianName + partReference + 'it').replaceAll(' ', '-'),
        de: (carPartGermanName + partReference + 'de').replaceAll(' ', '-'),
        pl: (carPartPolishName + partReference + 'pl').replaceAll(' ', '-'),
      });

      const tags = {
        en: [
          'en',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ].filter(t => t),
        fr: [
          'fr',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ].filter(t => t),
        es: [
          'es',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ].filter(t => t),
        it: [
          'it',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ].filter(t => t),
        de: [
          'de',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ].filter(t => t),
        pl: [
          'pl',
          carPartFrenchName,
          removeEmojisFromString(operationFrenchName),
          partReference,
        ],
      };

      const requestBody = JSON.stringify({
        carPartCode,
        links: allLanguagesLink,
        tags,
      });

      return HttpHelper.post('/api/shortenUrls', requestBody);
    }),
  );

  const bitLinks = await Promise.all(
    bitLinksPromise.map(r => {
      if (r.error === 'NotFoundLink') return r;
      else return r.json();
    }),
  );

  const bitLinksWithCarPartsNames = bitLinks.map(link => {
    const carPartEnglishName = allCarParts.find(
      p => p.code === link.carPartCode,
    ).englishName;
    const carPartFrenchName = allCarParts.find(p => p.code === link.carPartCode)
      .frenchName;
    const carPartSpanishName = allCarParts.find(
      p => p.code === link.carPartCode,
    ).spanishName;
    const carPartItalianName = allCarParts.find(
      p => p.code === link.carPartCode,
    ).italianName;
    const carPartGermanName = allCarParts.find(p => p.code === link.carPartCode)
      .germanName;
    return {
      ...link,
      carPartFrenchName,
      carPartEnglishName,
      carPartSpanishName,
      carPartGermanName,
      carPartItalianName,
    };
  });

  return {bitLinks: bitLinksWithCarPartsNames, customLinks};
};

export default getBitlyLinksFromCarPartsCode;
