import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import {toastr} from 'react-redux-toastr';
import history from '../routing/history';

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

let that;

class GenerateOperation extends React.Component {
  state = {
    selectedMotorizationTypes: [],
    operationCode: '',
    type: '',
  };

  handleChange = name => event => {
    if (!event.target.checked) {
      const newList = JSON.parse(
        JSON.stringify(this.state.selectedMotorizationTypes),
      );
      newList.splice(this.state.selectedMotorizationTypes.indexOf(name), 1);
      this.setState({selectedMotorizationTypes: newList});
    } else {
      const newList = JSON.parse(
        JSON.stringify(this.state.selectedMotorizationTypes),
      );
      newList.push(name);
      this.setState({selectedMotorizationTypes: newList});
    }
  };

  changeCode(e) {
    this.setState({operationCode: e.target.value});
  }

  componentDidMount() {
    that = this;
    const type = this.props.match.params.type;
    that.setState({type: type});
  }

  submit(e) {
    e.preventDefault();

    if (that.state.operationCode.length) {
      const code = that.state.operationCode;
      const draft = VideoHelper.newdraft();
      const firstCode = that.state.type === 'usefulVideo' ? '00' : '99';
      const motorizationCode = () => {
        if (
          that.state.selectedMotorizationTypes.indexOf('essence') &&
          that.state.selectedMotorizationTypes.indexOf('diesel')
        ) {
          return '00';
        } else if (that.state.selectedMotorizationTypes.indexOf('essence')) {
          return '02';
        } else if (that.state.selectedMotorizationTypes.indexOf('diesel')) {
          return '01';
        } else {
          return '00';
        }
      };
      draft.code = firstCode + '.' + motorizationCode() + '.' + code;

      VideoHelper.setRoute(ServerRoutes.drafts);
      VideoHelper.setTemplate(draft);
      VideoHelper.saveWithoutRedirect()
        .then(() => {
          toastr.success('Success', 'Video added');
          history.push('/drafts/' + draft.code);
        })
        .catch(() => {
          toastr.error('Error', 'Please retry later');
        });
    }
  }

  render() {
    const {classes} = this.props;
    const motorizationTypes = ['essence', 'diesel'];

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <b>
          Do not select any motorization if you want the video to be available
          for both of them (code 00)
        </b>
        <form
          onSubmit={this.submit}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <FormGroup row>
            {motorizationTypes.map(motorization => {
              return (
                <FormControlLabel
                  key={motorization}
                  control={
                    <Checkbox
                      checked={
                        this.state.selectedMotorizationTypes.indexOf(
                          motorization,
                        ) > -1
                      }
                      onChange={this.handleChange(motorization)}
                      value={motorization}
                    />
                  }
                  label={motorization}
                />
              );
            })}
          </FormGroup>

          <TextField
            id="outlined-name"
            label={'operation code'}
            value={this.state.code}
            className={classes.textField}
            onChange={e => this.changeCode(e)}
            margin="normal"
            variant="outlined"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 50,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

GenerateOperation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenerateOperation);
