import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {emojify} from 'react-emojione';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class PreviewYoutubeDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageTabValue: 0,
    };
  }

  changeLanguageTab(languageTabValue) {
    this.setState({languageTabValue});
  }

  render() {
    return (
      <div>
        <AppBar style={{position: 'relative', zIndex: 0}}>
          <Tabs
            value={this.state.languageTabValue}
            centered
            style={{width: '100%'}}>
            <Tab onClick={() => this.changeLanguageTab(0)} label="English" />
            <Tab onClick={() => this.changeLanguageTab(1)} label="French" />
            <Tab onClick={() => this.changeLanguageTab(2)} label="Spanish" />
            <Tab onClick={() => this.changeLanguageTab(3)} label="Italian" />
            <Tab onClick={() => this.changeLanguageTab(4)} label="German" />
            <Tab onClick={() => this.changeLanguageTab(5)} label="Polish" />
          </Tabs>
        </AppBar>

        {this.state.languageTabValue === 0 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.en)}
          </pre>
        )}

        {this.state.languageTabValue === 1 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.fr)}
          </pre>
        )}

        {this.state.languageTabValue === 2 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.es)}
          </pre>
        )}

        {this.state.languageTabValue === 3 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.it)}
          </pre>
        )}

        {this.state.languageTabValue === 4 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.de)}
          </pre>
        )}

        {this.state.languageTabValue === 5 && (
          <pre style={{margin: '20px', whiteSpace: 'pre-wrap'}}>
            {emojify(this.props.draft.youtubeDescription.pl)}
          </pre>
        )}
      </div>
    );
  }
}

PreviewYoutubeDescription.propTypes = {
  draft: PropTypes.object.isRequired,
};

export default PreviewYoutubeDescription;
