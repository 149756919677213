import React, {Component} from 'react';

import Users from '../users/users';
import MotorizationDataService from './motorizationsDataService';
import history from '../routing/history';
import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      brands: [],
      cars: [],
      motorizations: [],
      areAllUsersSelected: false,
    };
    this.validateChoices = this.validateChoices.bind(this);
  }

  componentDidMount() {
    Users.getBrands().then(brands => {
      const brandsNotSelected = brands.map(brand => {
        return {name: brand, isSelected: false};
      });
      this.setState({
        brands: brandsNotSelected,
      });
    });
  }

  mergeByProperty(a, b, prop) {
    const reduced = a.filter(
      aitem => !b.find(bitem => aitem[prop] === bitem[prop]),
    );
    return reduced.concat(b);
  }

  handleBrandCheck(brandName, all = false) {
    const newBrands = !all
      ? this.state.brands.map(brand => {
          if (brand.name === brandName) {
            return {name: brand.name, isSelected: !brand.isSelected};
          } else {
            return brand;
          }
        })
      : this.state.brands.map(brand => {
          const selected =
            this.state.brands.map(c => c.isSelected).indexOf(false) > -1;
          return {name: brand.name, isSelected: selected};
        });

    this.setState(
      {
        brands: newBrands,
      },
      () => {
        const selectedBrands = this.state.brands.filter(b => b.isSelected);

        Users.getCars(selectedBrands).then(cars => {
          const newCars = cars.map(car => {
            return {name: car, isSelected: false};
          });

          const newCarsState = this.mergeByProperty(
            this.state.cars,
            newCars,
            'name',
          );

          this.setState({
            cars: newCarsState,
          });
        });
      },
    );
  }

  handleCarCheck(carName, all = false) {
    const newCars = !all
      ? this.state.cars.map(car => {
          if (car.name === carName) {
            return {name: car.name, isSelected: !car.isSelected};
          } else {
            return car;
          }
        })
      : this.state.cars.map(car => {
          const selected =
            this.state.cars.map(c => c.isSelected).indexOf(false) > -1;
          return {name: car.name, isSelected: selected};
        });

    this.setState(
      {
        cars: newCars,
      },
      () => {
        const selectedCars = this.state.cars
          .filter(car => car.isSelected)
          .map(car => car.name);

        Users.getSeveralCarsMotorizations(selectedCars).then(motorizations => {
          const newMotorizations = motorizations.map(motorization => {
            return {
              carCode: motorization.carCode,
              motorizationType: motorization.motorizationType,
              periodicityCode: motorization.periodicityCode,
              motorization: motorization.motorization,
              isTutorialized: motorization.isTutorialized,
              isSelected: false,
            };
          });

          const newMotorizationsState = this.mergeByProperty(
            this.state.motorizations,
            newMotorizations,
            'motorization',
          );

          this.setState({
            motorizations: newMotorizationsState,
          });
        });
      },
    );
  }

  handleMotorizationCheck(motorizationName, all = false) {
    const newMotorizations = !all
      ? this.state.motorizations.map(motorization => {
          if (motorization.motorization === motorizationName) {
            return {
              carCode: motorization.carCode,
              motorizationType: motorization.motorizationType,
              motorization: motorization.motorization,
              periodicityCode: motorization.periodicityCode,
              isTutorialized: motorization.isTutorialized,
              isSelected: !motorization.isSelected,
            };
          } else {
            return motorization;
          }
        })
      : this.state.motorizations.map(motorization => {
          const isSelected =
            this.state.motorizations.map(m => m.isSelected).indexOf(false) > -1;
          return {
            carCode: motorization.carCode,
            motorizationType: motorization.motorizationType,
            motorization: motorization.motorization,
            periodicityCode: motorization.periodicityCode,
            isTutorialized: motorization.isTutorialized,
            isSelected: isSelected,
          };
        });

    this.setState({
      motorizations: newMotorizations,
    });
  }

  handleAllUsersCheck() {
    this.setState({
      areAllUsersSelected: !this.state.areAllUsersSelected,
    });
  }

  validateChoices() {
    const selectedMotorizations = this.state.motorizations.filter(
      motorization => motorization.isSelected,
    );

    MotorizationDataService.setData(selectedMotorizations);
    MotorizationDataService.setAreAllUsersSelected(
      this.state.areAllUsersSelected,
    );

    history.push('/notifications-step-2');
  }

  render() {
    let validateButton;

    if (
      (this.state.motorizations !== 'undefined' &&
        this.state.motorizations.length !== 0) ||
      this.state.areAllUsersSelected === true
    ) {
      validateButton = <Button onClick={this.validateChoices}>Submit</Button>;
    } else {
      validateButton = null;
    }

    let areCarsSet = this.state.cars.length !== 0;
    let areMotorizationsSet = this.state.motorizations.length !== 0;

    return (
      <div style={{margin: 50}}>
        <div>
          <Checkbox
            onChange={() => this.handleAllUsersCheck()}
            defaultChecked={false}
          />
          <b>All users</b>
        </div>
        Brands:
        <div>
          <div>
            <Checkbox
              onChange={() => {
                this.state.brands.map(brand =>
                  this.handleBrandCheck(brand.name),
                );
              }}
              checked={
                this.state.brands.filter(b => b.isSelected).length ===
                this.state.brands.length
              }
            />
            <b>Select all brands</b>
          </div>
          {this.state.brands.map(brand => (
            <div key={brand.name}>
              <Checkbox
                key={brand.name}
                onChange={() => this.handleBrandCheck(brand.name)}
                checked={brand.isSelected}
              />
              <b>{brand.name}</b>
            </div>
          ))}
        </div>
        {areCarsSet ? 'Cars : ' : ''}
        <div>
          {areCarsSet && (
            <div>
              <Checkbox
                onChange={() => this.handleCarCheck('', true)}
                checked={
                  this.state.cars.filter(b => b.isSelected).length ===
                  this.state.cars.length
                }
              />
              <b>Select all cars</b>
            </div>
          )}
          {this.state.cars.map(car => (
            <div key={car.name}>
              <Checkbox
                key={car.name}
                onChange={() => this.handleCarCheck(car.name)}
                checked={car.isSelected}
              />
              <b>{car.name}</b>
            </div>
          ))}
        </div>
        {areMotorizationsSet ? 'Motorizations : ' : ''}
        <div>
          {areMotorizationsSet && (
            <div>
              <Checkbox
                onChange={() => {
                  this.handleMotorizationCheck('', true);
                }}
                checked={
                  this.state.motorizations.filter(b => b.isSelected).length ===
                  this.state.motorizations.length
                }
              />
              <b>Select all motorizations</b>
            </div>
          )}
          {this.state.motorizations.map(motorization => (
            <div key={motorization.motorization}>
              <Checkbox
                key={motorization.motorization}
                onChange={() =>
                  this.handleMotorizationCheck(motorization.motorization)
                }
                checked={motorization.isSelected}
              />
              <b>
                {motorization.motorization} ({motorization.carCode})
              </b>
            </div>
          ))}
        </div>
        {validateButton}
      </div>
    );
  }
}

export default Notifications;
