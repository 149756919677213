import {createTheme} from '@material-ui/core/styles';

const primary = '#29AFAF';

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: 'white',
    },
    secondary: {
      main: '#FB6262',
      // light: '#0066ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    tertiary: {
      main: '#5868a3',
      // light: '#0066ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  overrides: {
    MuiButton: {
      root: {
        color: 'white',
        backgroundColor: primary,
      },
    },
  },
});

export default theme;
