import React from 'react';
import {Link} from 'react-router-dom';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

let that;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {},
      templateElements: [],
    };
    that = this;
  }

  componentDidMount() {
    const code = this.props.match.params.code;

    HttpHelper.get(ServerRoutes.templates + '/' + code.replace(/_/g, '.')).then(
      template => {
        const templateElements = [
          {
            name: 'names',
            route: '/operationForm/name/template/',
            preview: () => {
              try {
                return JSON.parse(template.names).youtube.en;
              } catch (e) {
                return 'not set';
              }
            },
          },
          {
            name: 'tools',
            route: '/operationForm/tools/template/',
            preview: () => JSON.parse(template.tools).length + ' linked tools',
          },
          {
            name: 'car parts',
            route: '/operationForm/carParts/template/',
            preview: () =>
              JSON.parse(template.parts).length + ' linked car parts',
          },
          {
            name: 'useful videos',
            route: '/operationForm/usefulVideos/template/',
            preview: () =>
              JSON.parse(template.usefulVideos).length + ' linked videos',
          },
          {
            name: 'info',
            route: '/operationForm/info/template/',
            preview: () => {
              try {
                return JSON.parse(template.info).youtube.en;
              } catch (e) {
                return 'not set';
              }
            },
          },
          {
            name: 'warning',
            route: '/operationForm/warning/template/',
            preview: () => {
              try {
                return JSON.parse(template.warning).youtube.en;
              } catch (e) {
                return 'not set';
              }
            },
          },
          {
            name: 'tags',
            route: '/operationForm/tags/template/',
            preview: () => JSON.parse(template.tags).length + ' linked tags',
          },
          {
            name: 'icon',
            route: '/operationForm/icon/template/',
            preview: () => template.operationIcon,
          },
          {
            name: 'time and difficulty',
            route: '/operationForm/difficulty/template/',
            preview: () =>
              'difficulty:' + template.difficulty + ' time: ' + template.time,
          },
          {
            name: 'categories',
            route: '/operationForm/categories/template/',
            preview: () =>
              'video cat: ' +
              template.videoCategory +
              ' outgoing cat: ' +
              template.outgoingCategory,
          },
          {
            name: 'Chapters',
            route: '/operationForm/chapters/template/',
            preview: () =>
              (template.chapters ? JSON.parse(template.chapters).length : 0) +
              ' saved chapters',
          },
          {
            name: 'Tightening values',
            route: '/operationForm/tighteningValues/template/',
            preview: () =>
              (template.tighteningValues
                ? JSON.parse(template.tighteningValues).length
                : 0) + ' tightening values',
          },
        ];
        that.setState({template, templateElements});
      },
    );
  }

  render() {
    return (
      <div style={{width: '100%', maxWidth: 950, margin: 'auto'}}>
        <h1>Template {this.state.template.code}</h1>
        <Divider />
        <div style={{marginTop: 20}}>
          <GridList cellHeight={160} cols={3}>
            {this.state.templateElements.map(elem => {
              return (
                <GridListTile key={elem.name} cols={1}>
                  <Paper
                    style={{height: '100%', background: '#29afaf', padding: 5}}>
                    <Typography component="p">{elem.preview()}</Typography>
                  </Paper>
                  <GridListTileBar
                    title={elem.name}
                    actionIcon={
                      <Link to={elem.route + this.props.match.params.code}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    }
                  />
                </GridListTile>
              );
            })}
          </GridList>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Template);
