import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ComputeStatus from './ComputeStatus';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SelectGeneralVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generalVideos: [],
      drafts: [],
    };
  }

  componentDidMount() {
    HttpHelper.get(ServerRoutes.findCarDrafts('99')).then(drafts => {
      this.setState({generalVideos: drafts});
    });
  }

  render() {
    return (
      <div>
        <Link
          to={'/generalOperations/create/generalVideo'}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button>Add general video</Button>
        </Link>

        <List component="nav">
          {this.state.generalVideos.map(video => {
            return (
              <div key={video.code}>
                <ListItem>
                  <ListItemText
                    inset
                    primary={
                      video.code +
                      ' app status: ' +
                      ComputeStatus.computeAppStatus(
                        video.lastModifiedDate,
                        video.lastPublishedDate,
                      )
                    }
                  />

                  <Link
                    to={'/drafts/' + video.code}
                    style={{textDecoration: 'none'}}>
                    <Button>Modify</Button>
                  </Link>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>
    );
  }
}

SelectGeneralVideos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectGeneralVideos);
