import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import Routes from '../routing/ServerRoutes';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class ListCarDrafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carCode: this.props.carCode,
      carName: this.props.carName,
      tutorializedDrafts: [],
    };
  }

  componentWillMount() {
    HttpHelper.get(Routes.findCarDrafts(this.state.carCode)).then(drafts => {
      const tutorializedDrafts = drafts.sort((a, b) =>
        ListCarDrafts.sortDraft(a, b),
      );

      this.setState({
        tutorializedDrafts,
      });
    });
  }

  static sortDraft(draftA, draftB) {
    const parsedDraftCodeA = Number(draftA.code.slice(-2));
    const parsedDraftCodeB = Number(draftB.code.slice(-2));

    return parsedDraftCodeA > parsedDraftCodeB;
  }

  static computeAppStatus(lastModifiedDate, lastPublishedDate) {
    if (lastPublishedDate === null) {
      return 'Not published';
    } else {
      const lastModifiedTimestamp = moment(lastModifiedDate).unix();
      const lastPublishedTimestamp = moment(lastPublishedDate).unix();

      if (lastPublishedTimestamp < lastModifiedTimestamp) {
        return 'Published but not up to date with draft';
      } else {
        return 'Published and up to date';
      }
    }
  }

  render() {
    return (
      <div>
        <div
          style={{marginLeft: '20px', display: 'flex', alignItems: 'center'}}>
          {this.state.tutorializedDrafts.length > 0 && (
            <h1 style={{marginRight: '20px'}}>Drafts</h1>
          )}

          <Link to={'/generateOperation/' + this.state.carName}>
            <Button style={{width: 250}}>Create a new draft</Button>
          </Link>

          <Link to={'/duplicateDraft/' + this.state.carName}>
            <Button style={{width: 250, marginLeft: 20}}>
              Duplicate a draft
            </Button>
          </Link>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Application status</TableCell>
                <TableCell align="right">Youtube status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tutorializedDrafts.map(draft => (
                <TableRow key={draft.code}>
                  <TableCell component="th" scope="row">
                    <Link
                      to={'/drafts/' + draft.code.replace(/\./g, '_')}
                      style={{textDecoration: 'none'}}>
                      <span style={{width: '400px', display: 'inline-block'}}>
                        {draft.code.slice(-5)}{' '}
                        {JSON.parse(draft.names).application.en === ''
                          ? 'Draft without name'
                          : JSON.parse(draft.names).application.en}
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    <span style={{width: '350px', display: 'inline-block'}}>
                      {ListCarDrafts.computeAppStatus(
                        draft.lastModifiedDate,
                        draft.lastPublishedDate,
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default ListCarDrafts;
