import React, {Component} from 'react';

class PrivacyPolicy extends Component {
  render() {
    return (
      <html style={{margin: 50}}>
        <h1>Privacy policy and in-product privacy</h1>

        <p>
          The only purpose of this dashboard is to provide a way to publish
          upload and update new videos on the Youtube channels faster than by
          the Youtube dashboard.
        </p>
        <p>
          Only users of Youtube channel "Ma clé de 12" and its international
          versions "UseTool En" and "UseTool Es" are authorized and able to
          access this dashboard. Indeed, no account creation is possible and
          thus it won't be possible for any other users to access this dashboard
          in the future.
        </p>
        <p>
          However, to comply with the Google recommendations, we are going to
          disclose here all the data used by this dashboard.
        </p>
        <p>
          No personal information is stored on the database of the dashboard.
          The sole data stored are : -One and only one credential to access the
          dashboard. -Drafts of the later published on Youtube data i.e.
          descriptions, video links and tags.
        </p>
      </html>
    );
  }
}

export default PrivacyPolicy;
