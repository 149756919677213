import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {withStyles} from '@material-ui/core/styles';
import ServerRoutes from '../routing/ServerRoutes';
import {toastr} from 'react-redux-toastr';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';

const styles = theme => ({
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  addButton: {
    backgroundColor: theme.palette.tertiary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 850,
  },
  formFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    justifyContent: 'center',
  },
  firstRowInput: {
    marginRight: 20,
    width: 250,
  },
});

export const AddPeriodicities = withStyles(styles)(
  ({carName, classes, addPeriodicitiesToState}) => {
    const [isCreating, setIsCreating] = useState(false);
    const [motorizations, setMotorizations] = useState([]);
    const [carCode, setCarCode] = React.useState('');

    useEffect(() => {
      async function initData() {
        const motorizations = await HttpHelper.get(
          ServerRoutes.findMotorizationsByCarName(carName),
        );
        setMotorizations(motorizations);

        const carInfo = await HttpHelper.get(ServerRoutes.findCarInfo(carName));
        console.log('carInfo', carInfo);
        console.log('carInfo', carInfo.code);
        setCarCode(carInfo?.car.code);
      }

      initData();
    }, []);

    const {control, handleSubmit, watch, setValue} = useForm({});
    const formOperationCode = watch('operationCode');
    const formMotorizations = watch('motorizations');

    React.useEffect(() => {
      if (!formMotorizations?.length && formOperationCode?.length >= 5) {
        const motorizationType = formOperationCode.split('.')[0];
        if (motorizationType === '00') {
          setValue(
            'motorizations',
            motorizations.map(m => ({
              value: m.periodicityCode,
              label: m.motorization + ' - ' + m.motorizationType,
            })),
          );
        } else if (motorizationType === '01') {
          setValue(
            'motorizations',
            motorizations
              .filter(m => m.motorizationType === 'diesel')
              .map(m => ({
                value: m.periodicityCode,
                label: m.motorization + ' - ' + m.motorizationType,
              })),
          );
        } else if (motorizationType === '02') {
          setValue(
            'motorizations',
            motorizations
              .filter(m => m.motorizationType === 'essence')
              .map(m => ({
                value: m.periodicityCode,
                label: m.motorization + ' - ' + m.motorizationType,
              })),
          );
        }
      }
    }, [formOperationCode]);

    const onSubmit = data => {
      const newPeriodicities = data.motorizations.map(motorization => ({
        operationCode: carCode + '.' + data.operationCode,
        month: data.monthPeriodicity,
        mileageInKm: data.mileageInKm,
        periodicityCode: motorization.value,
      }));
      Promise.all(
        newPeriodicities.map(newPeriodicity =>
          HttpHelper.post(
            ServerRoutes.periodicities,
            JSON.stringify(newPeriodicity),
          ),
        ),
      ).then(responses => {
        const conflictStatus = 409;
        const notAcceptableStatus = 406;
        const statuses = responses.map(r => r.status);

        if (statuses.find(s => s === conflictStatus)) {
          toastr.error(
            'Conflict',
            'A combo operation code / motorization already exists',
          );
        } else if (statuses.find(s => s === notAcceptableStatus)) {
          toastr.error(
            'Missing operation',
            'At least one operation code was not found',
          );
        } else {
          toastr.success('Success', 'Periodicities have been added');
          setInterval(() => window.location.reload(), 1000);
        }
      });
      // setIsCreating(false);
      // addPeriodicitiesToState(newPeriodicities);
    };

    return (
      <>
        {!isCreating ? (
          <div className={classes.addButtonContainer}>
            <Button
              onClick={() => setIsCreating(!isCreating)}
              className={classes.addButton}>
              Add periodicities
            </Button>
          </div>
        ) : (
          <></>
        )}
        {isCreating ? (
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <div className={classes.formFirstRow}>
                <Controller
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Operation code (xx.yy)"
                      className={classes.firstRowInput}
                    />
                  )}
                  name={'operationCode'}
                  control={control}
                />
                <Controller
                  render={({field}) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Month periodicity"
                      className={classes.firstRowInput}
                    />
                  )}
                  name={'monthPeriodicity'}
                  control={control}
                />
                <Controller
                  render={({field}) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Mileage (in Km) periodicity"
                      className={classes.firstRowInput}
                    />
                  )}
                  name={'mileageInKm'}
                  control={control}
                />
              </div>
              <Controller
                render={({field}) => (
                  <Select
                    {...field}
                    options={motorizations.map(m => ({
                      value: m.periodicityCode,
                      label: m.motorization + ' - ' + m.motorizationType,
                    }))}
                    isMulti
                    placeholder="Motorizations"
                  />
                )}
                name={'motorizations'}
                control={control}
              />
              <Button type="submit" style={{marginTop: 15}}>
                Submit
              </Button>
            </form>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  },
);
