import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import history from '../routing/history';
import {toastr} from 'react-redux-toastr';

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

let that;

class GenerateOperation extends React.Component {
  state = {
    selectedMotorizationTypes: [],
    operationCode: '',
    templates: [],
    selectedTemplate: '',
    carName: '',
  };

  handleChange = name => event => {
    if (!event.target.checked) {
      const newList = JSON.parse(
        JSON.stringify(this.state.selectedMotorizationTypes),
      );
      newList.splice(this.state.selectedMotorizationTypes.indexOf(name), 1);
      this.setState({selectedMotorizationTypes: newList});
    } else {
      const newList = JSON.parse(
        JSON.stringify(this.state.selectedMotorizationTypes),
      );
      newList.push(name);
      this.setState({selectedMotorizationTypes: newList});
    }
  };

  changeCode(e) {
    this.setState({operationCode: e.target.value});
    if (
      this.state.templates.filter(t => t.value === e.target.value).length > 0
    ) {
      const selected = this.state.templates.filter(
        t => t.value === e.target.value,
      )[0];
      this.setState({selectedTemplate: selected});
    }
  }

  componentDidMount() {
    that = this;
    const carName = this.props.match.params.carName;
    HttpHelper.get(ServerRoutes.templates).then(templates => {
      this.setState({
        templates: templates.map(t => {
          return {label: t.code, value: t.code};
        }),
        carName: carName,
      });
    });
  }

  submit(e) {
    e.preventDefault();

    if (
      that.state.carName.length &&
      that.state.selectedTemplate.value &&
      that.state.selectedMotorizationTypes.length
    ) {
      const code =
        that.state.operationCode.length > 0
          ? that.state.operationCode
          : that.state.selectedTemplate.value;

      HttpHelper.post(
        ServerRoutes.generateOperation(
          that.state.carName,
          that.state.selectedTemplate.value,
          code,
        ),
        JSON.stringify(that.state.selectedMotorizationTypes),
      ).then(response => {
        const conflictStatus = 409;

        if (response.status === conflictStatus) {
          toastr.error('Error', 'This operation code already exists');
        } else if (!response.ok) {
          toastr.error('Error', 'Please retry later');
        } else {
          response.json().then(result => {
            history.push('/drafts/' + result.code.replace(/\./g, '_'));
            window.location.href = '/drafts/' + result.code.replace(/\./g, '_');
          });
        }
      });
    }
  }

  render() {
    const {classes} = this.props;
    const motorizationTypes = ['essence', 'diesel'];

    return (
      <form
        style={{flexDirection: 'column', margin: 20}}
        onSubmit={this.submit}>
        <FormGroup row>
          {motorizationTypes.map(motorization => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      this.state.selectedMotorizationTypes.indexOf(
                        motorization,
                      ) > -1
                    }
                    onChange={this.handleChange(motorization)}
                    value={motorization}
                  />
                }
                label={motorization}
              />
            );
          })}
        </FormGroup>

        <TextField
          id="outlined-name"
          label={'operation code'}
          value={this.state.code}
          className={classes.textField}
          onChange={e => this.changeCode(e)}
          margin="normal"
          variant="outlined"
          inputProps={{maxLength: 2}}
        />
        <Select
          options={this.state.templates}
          value={this.state.selectedTemplate}
          onChange={value => {
            this.setState({selectedTemplate: value});
          }}
          placeholder="Choose template"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            disabled={
              this.state.selectedTemplate === '' ||
              this.state.operationCode === '' ||
              this.state.selectedMotorizationTypes === []
            }
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

GenerateOperation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenerateOperation);
