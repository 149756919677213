import MenuList from '@material-ui/core/MenuList/MenuList';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import React from 'react';
import {Link} from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Notifications from '@material-ui/icons/Notifications';
import Car from '@material-ui/icons/TimeToLeave';
import Advertising from '@material-ui/icons/BusinessCenter';
import Edit from '@material-ui/icons/Edit';
import VideoCam from '@material-ui/icons/VideoCall';
import Folder from '@material-ui/icons/Folder';
import Money from '@material-ui/icons/AttachMoney';
import Build from '@material-ui/icons/Build';
import {Paper} from '@material-ui/core';

export default class NavigationMenu extends React.Component {
  render() {
    return (
      <Paper>
        <MenuList>
          <MenuItem onClick={this.props.closeMenu}>
            <Link to="/sheets" style={{textDecoration: 'none', color: 'black'}}>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              Mister Auto sheets
            </Link>
          </MenuItem>

          <Link
            to="/general-data"
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              General data
            </MenuItem>
          </Link>

          <Link
            to="/notifications"
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Notifications />
              </ListItemIcon>
              Notifications
            </MenuItem>
          </Link>

          <Link
            to={'/add-a-car'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Car />
              </ListItemIcon>
              Add a car
            </MenuItem>
          </Link>

          <Link to={'/cars'} style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Car />
              </ListItemIcon>
              Cars
            </MenuItem>
          </Link>

          <Link
            to={'/useful-videos'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <VideoCam />
              </ListItemIcon>
              Useful videos
            </MenuItem>
          </Link>

          <Link
            to={'/general-videos'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <VideoCam />
              </ListItemIcon>
              General videos
            </MenuItem>
          </Link>

          <Link
            to={'/advertising'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Advertising />
              </ListItemIcon>
              Change the advertising
            </MenuItem>
          </Link>

          <Link
            to={'/templates'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Templates
            </MenuItem>
          </Link>

          <Link
            to={'/set-hours-prices'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Money />
              </ListItemIcon>
              Hour prices by country
            </MenuItem>
          </Link>

          <Link to={'/tools'} style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Build />
              </ListItemIcon>
              Tools
            </MenuItem>
          </Link>

          <Link
            to={'/carParts'}
            style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Car />
              </ListItemIcon>
              CarParts
            </MenuItem>
          </Link>

          <Link to={'/tags'} style={{textDecoration: 'none', color: 'black'}}>
            <MenuItem onClick={this.props.closeMenu}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Tags
            </MenuItem>
          </Link>
        </MenuList>
      </Paper>
    );
  }
}
