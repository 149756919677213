import React from 'react';
import {Link} from 'react-router-dom';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PreviewOperation from './PreviewOperation';
import uuidv1 from 'uuid';
import YoutubeHelper from '../youtube/YoutubeHelper';
import VideoHelper from './VideoHelper';
import Dialog from '@material-ui/core/Dialog';
import _ from 'lodash';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {regenerateDraftTitlesFromTemplate} from './regenerateDraftTitlesFromTemplate';
import {CircularProgress} from '@material-ui/core';
import prepareMASheet from '../misterAutoSheets/prepareMASheet';
import {toastr} from 'react-redux-toastr';

let that;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});

class Draft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      escapedCode: this.props.match.params.code,
      notEscapedCode: this.props.match.params.code.replace(/_/g, '.'),
      draft: {},
      draftElements: [],
      isLoading: true,
      openChannel: false,
      selectStatus: false,
    };
    that = this;
  }

  componentDidMount() {
    this.initDraft(this.state.notEscapedCode);
    this.getCurrentChannel();
  }

  initDraft(code) {
    HttpHelper.get(ServerRoutes.drafts + '/' + code).then(draft => {
      const draftElements = [
        {
          name: 'youtube description',
          route: '/operationForm/youtube-description/',
          preview: () =>
            draft.youtubeDescription.en === ''
              ? 'Not set'
              : draft.youtubeDescription.en.substring(0, 10),
        },
        {
          name: 'names',
          route: '/operationForm/name/draft/',
          preview: () => {
            try {
              return JSON.parse(draft.names).youtube.en;
            } catch (e) {
              return 'not set';
            }
          },
        },
        {
          name: 'videos links',
          route: '/operationForm/videos/draft/',
          preview: () => {
            try {
              return JSON.parse(draft.videoLinks).youtube.en;
            } catch (e) {
              return 'not set';
            }
          },
        },
        {
          name: 'tools',
          route: '/operationForm/tools/draft/',
          preview: () =>
            draft && draft.tools
              ? JSON.parse(draft.tools).length + ' linked tools'
              : '0 linked tools',
        },
        {
          name: 'car parts',
          route: '/operationForm/carParts/draft/',
          preview: () =>
            draft && draft.parts
              ? JSON.parse(draft.parts).length + ' linked car parts'
              : '0 linked car parts',
        },
        {
          name: 'useful videos',
          route: '/operationForm/usefulVideos/draft/',
          preview: () =>
            draft && draft.usefulVideos
              ? JSON.parse(draft.usefulVideos).length + ' linked videos'
              : '0 linked videos',
        },
        {
          name: 'info',
          route: '/operationForm/info/draft/',
          preview: () => {
            try {
              return JSON.parse(draft.info).youtube.en;
            } catch (e) {
              return 'not set';
            }
          },
        },
        {
          name: 'warning',
          route: '/operationForm/warning/draft/',
          preview: () => {
            try {
              return JSON.parse(draft.warning).youtube.en;
            } catch (e) {
              return 'not set';
            }
          },
        },
        {
          name: 'tags',
          route: '/operationForm/tags/draft/',
          preview: () =>
            draft && draft.tags
              ? JSON.parse(draft.tags).length + ' linked tags'
              : '0 linked tags',
        },
        {
          name: 'icon',
          route: '/operationForm/icon/draft/',
          preview: () => (draft ? draft.operationIcon : ''),
        },
        {
          name: 'time and difficulty',
          route: '/operationForm/difficulty/draft/',
          preview: () =>
            'difficulty:' + draft?.difficulty + ' time: ' + draft.time,
        },
        {
          name: 'categories',
          route: '/operationForm/categories/draft/',
          preview: () =>
            'video cat: ' +
            draft.videoCategory +
            ' outgoing cat: ' +
            draft.outgoingCategory,
        },
        {
          name: 'timestamps',
          route: '/operationForm/timestamps/draft/',
          preview: () =>
            draft.timestamps ? JSON.parse(draft.timestamps).length : 'Not set',
        },
        {
          name: 'thumbnails',
          route: '/operationForm/thumbnails/',
          preview: () => (draft.thumbnails ? 'Thumbnails set' : 'Not set'),
        },
        {
          name: 'Chapters',
          route: '/operationForm/chapters/draft/',
          preview: () =>
            (draft.chapters ? JSON.parse(draft.chapters).length : 0) +
            ' saved chapters',
        },
        {
          name: 'Tightening values',
          route: '/operationForm/tighteningValues/draft/',
          preview: () =>
            (draft.tighteningValues
              ? JSON.parse(draft.tighteningValues).length
              : 0) + ' tightening values',
        },
        {
          name: 'Motorization',
          route: '/operationForm/motorization/draft/',
          preview: () => draft.motorization || '',
        },
      ];
      that.setState({draft, draftElements});
      this.setState({isLoading: false});
    });
  }

  publish() {
    HttpHelper.post(
      ServerRoutes.publishOperations(this.state.notEscapedCode),
      '',
    ).then(() => {
      this.initDraft(this.state.notEscapedCode);
    });
  }
  doUpdateYoutube = () => {};
  updateYoutube(lang) {
    this.setState({openChannel: true});
    this.doUpdateYoutube = status =>
      YoutubeHelper.update(this.state.draft, lang, status).then(newDraft => {
        VideoHelper.setRoute(ServerRoutes.drafts);
        VideoHelper.setTemplate(newDraft);
        VideoHelper.saveWithoutRedirect(newDraft).then(() => {
          this.initDraft(this.state.notEscapedCode);
        });
      });
  }
  getCurrentChannel() {
    let getCurrentChannelInterval = setInterval(() => {
      if (window.gapi && window.gapi.auth2) {
        window.gapi.load('client:auth2', () => {
          clearInterval(getCurrentChannelInterval);
          YoutubeHelper.getCurrentChannel().then(channelTitle => {
            this.setState({currentChannel: channelTitle});
          });
        });
      }
    }, 1000);
  }

  onPressGenerate = async () => {
    try {
      this.setState({isLoading: true});
      await prepareMASheet(this.state.draft.code);
      toastr.success('Success', 'Sheet generated');
    } catch (e) {
      console.log('e', e);

      toastr.error('Error', 'Please retry later');
    } finally {
      this.setState({isLoading: false});
    }
  };

  render() {
    const status = () => {
      if (!this.state.draft || !this.state.draft.lastPublishedDate) {
        return 'Not published';
      } else if (
        this.state.draft.lastPublishedDate &&
        this.state.draft.lastPublishedDate < this.state.draft.lastModifiedDate
      ) {
        return 'Not up to date';
      } else {
        return 'Up to date';
      }
    };

    return (
      <div
        style={{
          width: '100%',
          maxWidth: 1100,
          margin: 'auto',
          marginBottom: 20,
        }}>
        <h1 style={{textAlign: 'center'}}>Draft {this.state.notEscapedCode}</h1>

        <div
          style={{
            display: 'flex',
            fontSize: 14,
            fontWeight: 'bold',
            justifyContent: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
            }}>
            Application status:
            <br />
            <span
              style={{
                margin: 5,
                color: status === 'Up to date' ? 'green' : 'grey',
              }}>
              {status()}
            </span>
            <br />
            {status() !== 'Up to date' && (
              <Button onClick={() => this.publish()}>Publish</Button>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 20,
          }}>
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <PreviewOperation draft={that.state.draft} />
              <Button
                style={{width: 300, backgroundColor: '#5868a3'}}
                onClick={() =>
                  regenerateDraftTitlesFromTemplate(that.state.draft.code)
                }>
                Regenerate titles from template
              </Button>

              <Button
                onClick={this.onPressGenerate}
                style={{width: 300, backgroundColor: '#5868a3'}}>
                Generate Sheet
              </Button>
            </>
          )}
        </div>

        <div style={{marginTop: 20, marginBottom: 50}}>
          <GridList cellHeight={140} cols={4}>
            {this.state.draftElements.map(elem => {
              return (
                <GridListTile cols={1} key={uuidv1()}>
                  <Paper
                    style={{height: '100%', background: '#29afaf', padding: 5}}>
                    <Typography component="p">{elem.preview()}</Typography>
                  </Paper>

                  <GridListTileBar
                    title={_.startCase(_.toLower(elem.name))}
                    actionIcon={
                      <Link to={elem.route + this.state.escapedCode}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    }
                  />
                </GridListTile>
              );
            })}
          </GridList>
        </div>

        <Dialog open={this.state.openChannel}>
          {this.state.currentChannel ? (
            <div>
              <b>
                You will post your video on the channel:{' '}
                {this.state.currentChannel}
              </b>
              <Button
                onClick={() =>
                  YoutubeHelper.connect(() => {
                    this.getCurrentChannel();
                  })
                }>
                Change channel
              </Button>
              <Button
                onClick={() => {
                  this.setState({openChannel: false, selectStatus: true});
                }}>
                Continue
              </Button>
            </div>
          ) : (
            <Button
              onClick={() =>
                YoutubeHelper.connect(() => this.getCurrentChannel())
              }>
              Connect to youtube
            </Button>
          )}
        </Dialog>

        <Dialog open={this.state.selectStatus}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Video status</FormLabel>
            <RadioGroup
              aria-label="Video status"
              name="gender1"
              value={this.state.status}
              onChange={event => {
                this.setState({selectStatus: false});
                this.doUpdateYoutube(event.target.value);
              }}>
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="public"
              />
              <FormControlLabel
                value="unlisted"
                control={<Radio />}
                label="unlisted"
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="private"
              />
            </RadioGroup>
          </FormControl>
        </Dialog>
      </div>
    );
  }
}

Draft.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Draft);
