import React from 'react';
import {Link} from 'react-router-dom';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DuplicateTemplate from './DuplicateTemplate';
import Button from '@material-ui/core/Button';

let that;

export default class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
    };
    that = this;
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates() {
    HttpHelper.get(ServerRoutes.templates).then(templates => {
      that.setState({templates});
    });
  }

  render() {
    return (
      <div>
        <Link
          to={'/createOperation/template'}
          onClick={() => VideoHelper.setRoute(ServerRoutes.templates)}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button style={{width: '250px'}}>Create new template</Button>
        </Link>
        {this.state.templates.map(template => {
          return (
            <List component="nav">
              <ListItem button>
                <ListItemText primary={template.code} />
                <Link
                  to={'/template/' + template.code.replace(/\./g, '_')}
                  onClick={() => VideoHelper.setRoute(ServerRoutes.templates)}
                  style={{textDecoration: 'none', marginRight: 10}}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{width: '250px'}}>
                    Modify
                  </Button>
                </Link>
                <DuplicateTemplate
                  submit={() => this.getTemplates()}
                  code={template.code}
                />
              </ListItem>
            </List>
          );
        })}
      </div>
    );
  }
}
