import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import FullPageSpinner from '../util/FullPageSpinner';
import Select from 'react-select';
import HttpHelper from '../routing/HttpHelper';

const TighteningValues = props => {
  const code = props.match.params.code.replace(/_/g, '.');
  const type = props.match.params.type;

  const [template, setTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [carPartOptions, setCarPartOptions] = React.useState([]);

  if (type === 'template') {
    VideoHelper.setRoute(ServerRoutes.templates);
  } else {
    VideoHelper.setRoute(ServerRoutes.drafts);
  }

  const {control, handleSubmit, reset} = useForm({});
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'tighteningValues',
  });

  useEffect(() => {
    if (template?.tighteningValues && template.tighteningValues.length > 0) {
      reset({tighteningValues: JSON.parse(template.tighteningValues)});
    }
  }, [reset, template]);

  React.useEffect(() => {
    (async () => {
      const parts = await HttpHelper.get(ServerRoutes.carParts);
      const savedTemplate = await VideoHelper.getTemplate(code);
      setTemplate(savedTemplate);
      setIsLoading(false);

      const templateParts = JSON.parse(savedTemplate.parts).map(carPart => {
        const carPartObject = parts.filter(t => t.code === carPart)[0];
        return {
          label: carPartObject.code + ' - ' + carPartObject.englishName,
          value: carPartObject.code,
        };
      });
      setCarPartOptions([
        ...templateParts,
        ...parts
          .map(t => ({label: t.code + ' - ' + t.englishName, value: t.code}))
          .filter(t => !templateParts.map(t => t.value).includes(t.value)),
      ]);
    })();
  }, [code]);

  const onSubmit = data => {
    const updatedTemplate = {
      ...template,
      tighteningValues: JSON.stringify(data.tighteningValues),
    };
    VideoHelper.setTemplate(updatedTemplate);
    VideoHelper.save();
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <ul>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <Controller
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      options={carPartOptions}
                      defaultValue={carPartOptions[0]}
                      value={carPartOptions.filter(
                        t => t.value === field.value,
                      )}
                      onChange={carPart => field.onChange(carPart.value)}
                      placeholder="Select carPart"
                    />
                  )}
                  name={`tighteningValues.${index}.carPart`}
                  control={control}
                />
                Tightening value (newton-metre)
                <Controller
                  render={({field}) => (
                    <input {...field} type="number" step=".01" required />
                  )}
                  name={`tighteningValues.${index}.value`}
                  control={control}
                />
                Timing in seconds (optional)
                <Controller
                  render={({field}) => <input {...field} type="number" />}
                  name={`tighteningValues.${index}.timing`}
                  control={control}
                />
                <Button style={{margin: 10}} onClick={() => remove(index)}>
                  Delete
                </Button>
              </li>
            );
          })}
        </ul>

        <section style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            style={{margin: 10}}
            onClick={() => {
              append({value: undefined, timing: undefined});
            }}>
            Add
          </Button>

          <Button style={{margin: 10}} onClick={() => reset()}>
            reset
          </Button>
        </section>

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default TighteningValues;
