import HttpHelper from '../routing/HttpHelper';
import {toastr} from 'react-redux-toastr';

class Translation {
  constructor(en = '', fr = '', es = '', it = '', de = '', pl = '') {
    this.en = en;
    this.fr = fr;
    this.es = es;
    this.it = it;
    this.de = de;
    this.pl = pl;
  }
}

function oldTranslationsToNew(translation) {
  const en = translation.en ? translation.en : '';
  const it = translation.it ? translation.it : '';
  const de = translation.de ? translation.de : '';
  const es = translation.es ? translation.es : '';
  const fr = translation.fr ? translation.fr : '';
  const pl = translation.pl ? translation.pl : '';
  return new Translation(en, fr, es, it, de, pl);
}

function getEmptyOperation() {
  return {
    code: '',
    names: JSON.stringify({
      youtube: new Translation(),
      application: new Translation(),
    }),
    links: '[]',
    tools: '[]',
    parts: '[]',
    usefulVideos: '[]',
    tags: '[]',
    operationIcon: '',
    difficulty: 0,
    time: 0,
    chapters: '[]',
    tighteningValues: '[]',
  };
}

function getEmptyDraft() {
  return {
    code: '',
    names: JSON.stringify({
      youtube: new Translation(),
      application: new Translation(),
    }),
    info: JSON.stringify({
      youtube: new Translation(),
      application: new Translation(),
    }),
    warning: JSON.stringify({
      youtube: new Translation(),
      application: new Translation(),
    }),
    links: '[]',
    tools: '[]',
    parts: '[]',
    usefulVideos: '[]',
    tags: '[]',
    operationIcon: '',
    difficulty: 0,
    time: 0,
  };
}

let currentOperation = getEmptyOperation();
let updateRoute;

export default {
  setTemplate: operation => (currentOperation = operation),
  getCurrentTemplate: () => currentOperation,
  newTemplate: () => (currentOperation = getEmptyOperation()),
  newdraft: () => (currentOperation = getEmptyDraft()),
  getTemplate: code => {
    return HttpHelper.get(updateRoute + '/' + code.replace(/_/g, '.')).then(
      template => {
        currentOperation = template;
        return template;
      },
    );
  },
  newTranslation: (en = '', fr = '', es = '', it = '', de = '', pl = '') =>
    new Translation(en, fr, es, it, de, pl),
  oldTranslationsToNew: translation => oldTranslationsToNew(translation),
  setRoute: route => (updateRoute = route),
  save: () => {
    if (!currentOperation.youtubeDescription) {
      currentOperation.youtubeDescription = JSON.parse(
        '{"en": "", "es": "", "fr": "", "it": "", "de": "", "pl": ""}',
      );
    }
    if (currentOperation.lastModifiedDate) {
      currentOperation.lastModifiedDate = new Date(
        currentOperation.lastModifiedDate,
      );
    }
    if (currentOperation.lastPublishedDate) {
      currentOperation.lastPublishedDate = new Date(
        currentOperation.lastPublishedDate,
      );
    }

    return HttpHelper.post(updateRoute, JSON.stringify(currentOperation))
      .then(r => {
        toastr.success('Success', 'Update OK');
        return r;
      })
      .catch(error => {
        console.log(error);
        toastr.error('Error', 'Please retry later');
      });
  },
  saveWithoutRedirect: () => {
    if (!currentOperation.youtubeDescription) {
      currentOperation.youtubeDescription = JSON.parse(
        '{"en": "", "es": "", "fr": "", "it": "", "de": "", "pl": ""}',
      );
    }
    if (currentOperation.lastModifiedDate) {
      currentOperation.lastModifiedDate = new Date(
        currentOperation.lastModifiedDate,
      );
    }
    if (currentOperation.lastPublishedDate) {
      currentOperation.lastPublishedDate = new Date(
        currentOperation.lastPublishedDate,
      );
    }
    return HttpHelper.post(updateRoute, JSON.stringify(currentOperation));
  },
};
