import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowSize from './useWindowSize';

export default () => {
  const windowSize = useWindowSize();

  return (
    <div
      style={{
        width: '100%',
        height: windowSize.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CircularProgress />
    </div>
  );
};
