import VideoHelper from '../videos/VideoHelper';
import {toastr} from 'react-redux-toastr';
import MediaUploader from './MediaUploader';

let youtubeToken;
let apiKey = 'AIzaSyAw-TMydCxFqc4bLaKWP9RVlZfZl2Jeyi8';
let callback = () => {};

function initToken() {
  let initTokenInterval = setInterval(() => {
    if (window.gapi && window.gapi.auth2) {
      window.gapi.load('client:auth2', () => {
        clearInterval(initTokenInterval);
        youtubeToken = window.gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getAuthResponse().access_token;
      });
    }
  }, 1500);
}
initToken();

function updateThumbnails(image, videoId) {
  return fetch(
    'https://www.googleapis.com/upload/youtube/v3/thumbnails/set?key=' +
      apiKey +
      '&videoId=' +
      videoId,
    {
      method: 'POST',
      body: image,
      headers: {
        type: 'image/*',
        Authorization: 'Bearer ' + youtubeToken,
      },
    },
  ).then(resp => {
    if (!resp.ok) {
      callback = () => updateThumbnails(image, videoId);
      connect();
    } else {
      return resp.json();
    }
  });
}

function update(draft, lang, status) {
  const videoId = JSON.parse(draft.videoLinks).youtube[lang].replace(
    'https://youtu.be/',
    '',
  );
  const translatedTags = JSON.parse(draft.tags).map(
    tag => JSON.parse(tag.names)[lang],
  );
  const metadata = {
    snippet: {
      title: JSON.parse(draft.names).youtube[lang],
      description: draft.youtubeDescription[lang],
      categoryId: 2,
      defaultLanguage: lang,
      tags: translatedTags,
    },
    status: {
      privacyStatus: status,
    },
    id: videoId,
  };
  return window.gapi.client
    .request({
      method: 'PUT',
      path: '/youtube/v3/videos',
      params: {part: 'snippet,status'},
      body: metadata,
    })
    .then(
      resp => {
        if (resp.status !== 200) {
          toastr.error('Error', 'Please retry later');
        }
      },
      error => {
        toastr.error('Error', 'Please retry later');
        console.log(error);
      },
    );
}

function getCurrentChannel() {
  return window.gapi.client
    .request({
      method: 'GET',
      path: '/youtube/v3/channels',
      params: {mine: 'true', part: 'snippet,contentDetails,statistics'},
    })
    .then(
      channels => {
        return channels.result.items[0].snippet.title;
      },
      error => {},
    );
}

function getPlaylists() {
  return window.gapi.client
    .request({
      method: 'GET',
      path: '/youtube/v3/playlists',
      params: {mine: 'true', maxResults: '50', part: 'snippet'},
    })
    .then(
      playlists => {
        return playlists.result.items.map(playlist => {
          return {title: playlist.snippet.title, id: playlist.id};
        });
      },
      error => {
        console.log(error);
      },
    );
}

function insertInPlaylist(playlistId, videoId) {
  return window.gapi.client
    .request({
      method: 'POST',
      path: '/youtube/v3/playlistItems',
      params: {part: 'snippet'},
      body: {
        snippet: {
          playlistId,
          resourceId: {kind: 'youtube#video', videoId},
        },
      },
    })
    .then(
      playlists => {
        return playlists;
      },
      error => {
        console.log(error);
      },
    );
}

function connect() {
  let GoogleAuth = window.gapi.auth2.getAuthInstance();
  GoogleAuth.signOut();
  GoogleAuth.isSignedIn.listen(() => {
    youtubeToken = window.gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse().access_token;
    window.gapi.client.setToken(
      window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse(),
    );
    callback();
  });
  GoogleAuth.signIn();
}

export default {
  postVideo: (video, metadata, playlistId, onProgress) => {
    if (
      window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse().access_token
    ) {
      youtubeToken = window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse().access_token;
    }
    return new Promise(resolve => {
      var uploader = new MediaUploader({
        baseUrl: 'https://www.googleapis.com/upload/youtube/v3/videos',
        file: video,
        token: youtubeToken,
        metadata,
        params: {
          part: Object.keys(metadata).join(','),
        },
        onError(data) {
          var message = data;
          // Assuming the error is raised by the YouTube API, data will be
          // a JSON string with error.message set. That may not be the
          // only time onError will be raised, though.
          try {
            var errorResponse = JSON.parse(data);
            message = errorResponse.error.message;
          } finally {
            alert(message);
          }
        },
        onProgress(data) {
          var currentTime = Date.now();
          var bytesUploaded = data.loaded;
          var totalBytes = data.total;
          // The times are in millis, so we need to divide by 1000 to get seconds.
          var percentageComplete = (bytesUploaded * 100) / totalBytes;
          console.log(percentageComplete);
          onProgress(percentageComplete);
        },
        onComplete: function(data) {
          var uploadResponse = JSON.parse(data);
          this.videoId = uploadResponse.id;
          insertInPlaylist(playlistId, this.videoId);
          console.log(this.videoId);
          resolve(uploadResponse);

          var pollForVideoStatus = () =>
            window.gapi.client.request({
              path: '/youtube/v3/videos',
              params: {
                part: 'status,player',
                id: this.videoId,
              },
              callback: function(response) {
                if (response.error) {
                  // The status polling failed.
                  console.log(response.error.message);
                  setTimeout(pollForVideoStatus.bind(this), 60 * 1000);
                } else {
                  var uploadStatus = response.items[0].status.uploadStatus;
                  console.log(uploadStatus);
                  switch (uploadStatus) {
                    // This is a non-final status, so we need to poll again.
                    case 'uploaded':
                      setTimeout(pollForVideoStatus.bind(this), 60 * 1000);
                      break;
                    // The video was successfully transcoded and is available.
                    case 'processed':
                      break;
                    // All other statuses indicate a permanent transcoding failure.
                    default:
                      break;
                  }
                }
              }.bind(this),
            });
          pollForVideoStatus();
        }.bind(this),
      });
      // This won't correspond to the *exact* start of the upload, but it should be close enough.
      this.uploadStartTime = Date.now();
      uploader.upload();
    });
  },
  connect: connectCallback => {
    callback = connectCallback;
    connect();
  },
  updateThumbnails: (image, videoId) => updateThumbnails(image, videoId),
  getCurrentChannel: () => getCurrentChannel(),
  getPlaylists: () => getPlaylists(),
  update: (draft, lang, status) => update(draft, lang, status),
};
