import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class CreateOperation extends React.Component {
  componentDidMount() {
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    }
    VideoHelper.newTemplate();
    this.template = VideoHelper.getCurrentTemplate();
  }

  render() {
    const {classes} = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          VideoHelper.save();
        }}
        autoComplete="off">
        <TextField
          id="outlined-name"
          label="Operation Code"
          className={classes.textField}
          onChange={e => {
            this.template.code = e.target.value;
            VideoHelper.setTemplate(this.template);
          }}
          margin="normal"
          variant="outlined"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

CreateOperation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateOperation);
