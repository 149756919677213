import React, {Component} from 'react';

class ToolImage extends Component {
  state = {
    image: (
      <img
        onError={() => this.onError()}
        src={
          'https://s3.eu-west-3.amazonaws.com/testmc12/' +
          this.props.code +
          '.png'
        }
        alt={'tool'}
      />
    ),
  };

  onError() {
    const image = () => {
      try {
        return (
          <img
            src={require('../images/tools/' + this.props.code + '.png')}
            alt={'tool'}
          />
        );
      } catch (err) {
        return <h3>No image</h3>;
      }
    };
    this.setState({image: image()});
  }

  render() {
    return this.state.image;
  }
}

export default ToolImage;
