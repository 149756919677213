const token = window.localStorage.getItem('token');

export default {
  get: route => {
    return fetch(route, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
    })
      .then(async response => {
        if (response.ok) {
          const json = response.json();
          return json;
        } else {
          console.log(response);
        }
      })
      .catch(error => console.log(error));
  },
  post: (route, body = '') => {
    return fetch(route, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
      body,
    }).catch(error => console.log(error));
  },
  update: (route, body = '') => {
    return fetch(route, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
      body,
    });
  },
  delete: route => {
    return fetch(route, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
    });
  },
};
