import Advertising from '../advertising/advertising';
import NotificationsStep2 from '../notifications/notificationsStep2';
import SignInForm from '../auth/signIn';
import Notifications from '../notifications/notifications';
import signOut from '../auth/signout';
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Cars from '../cars/Cars';
import CarDashboard from '../cars/CarDashboard';
import Templates from '../videos/Templates';
import CreateOperation from '../videos/CreateOperation';
import OperationName from '../videos/OperationName';
import SelectTools from '../videos/SelectTools';
import SelectCarParts from '../videos/SelectCarParts';
import SelectUsefulVideos from '../videos/SelectUsefulVideos';
import OperationInfo from '../videos/OperationInfo';
import OperationWarning from '../videos/OperationWarning';
import SelectTags from '../videos/SelectTags';
import AddOperationIcon from '../videos/AddOperationIcon';
import SetCategories from '../videos/SetCategories';
import DifficultyAndTime from '../videos/DifficultyAndTime';
import Template from '../videos/Template';
import AddCar from '../cars/AddCar';
import GenerateOperation from '../videos/GenerateOperation';
import Draft from '../videos/Draft';
import AddVideoLinks from '../videos/AddVideoLinks';
import CreateNewGeneralOrUsefulVideo from '../videos/CreateNewGeneralOrUsefullVideo';
import UsefulVideos from '../usefulVideos/UsefulVideos';
import GeneralVideos from '../videos/GeneralVideos';
import SetHourPrices from '../hourPrices/SetHourPrices';
import Timestamps from '../videos/Timestamps';
import Tools from '../Tools/Tools';
import AddAndModifyTool from '../Tools/AddAndModifyTool';
import CarParts from '../carParts/CarParts';
import AddAndModifyCarPart from '../carParts/AddAndModifyCarPart';
import GeneralData from '../generalData/GeneralData';
import AddOperationThumbnails from '../videos/AddOperationThumbnails';
import Tags from '../generalData/Tags';
import YoutubeDescription from '../youtube/YoutubeDescription';
import PrivacyPolicy from '../privacyPolicy/privacyPolicy';
import UsefulVideo from '../usefulVideos/UsefulVideo';
import Chapters from '../videos/Chapters';
import GenerateMASheet from '../misterAutoSheets/GenerateMASheet';
import DuplicateDraft from '../videos/DuplicateDraft';
import TighteningValues from '../videos/TighteningValues';
import {DraftMotorization} from '../videos/DraftMotorization';

export default class Routing extends Component {
  static logout(e) {
    signOut(e);
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={SignInForm} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/notifications-step-2" component={NotificationsStep2} />
        <Route path="/cars" component={Cars} />
        <Route path="/carDashboard/:carName" component={CarDashboard} />
        <Route path="/add-a-car/:brandName?" component={AddCar} />
        <Route path="/advertising" component={Advertising} />
        <Route path="/useful-videos" component={UsefulVideos} />
        <Route path="/useful-video/:code" component={UsefulVideo} />
        <Route path="/general-videos" component={GeneralVideos} />
        <Route path="/templates" component={Templates} />
        <Route path="/createOperation/:type" component={CreateOperation} />
        <Route
          path="/operationForm/name/:type/:code"
          component={OperationName}
        />
        <Route
          path="/operationForm/tools/:type/:code"
          component={SelectTools}
        />
        <Route
          path="/operationForm/carParts/:type/:code"
          component={SelectCarParts}
        />
        <Route
          path="/operationForm/usefulVideos/:type/:code"
          component={SelectUsefulVideos}
        />
        <Route
          path="/operationForm/info/:type/:code"
          component={OperationInfo}
        />
        <Route
          path="/operationForm/warning/:type/:code"
          component={OperationWarning}
        />
        <Route path="/operationForm/tags/:type/:code" component={SelectTags} />
        <Route
          path="/operationForm/icon/:type/:code"
          component={AddOperationIcon}
        />
        <Route
          path="/operationForm/categories/:type/:code"
          component={SetCategories}
        />
        <Route
          path="/operationForm/difficulty/:type/:code"
          component={DifficultyAndTime}
        />
        <Route
          path="/operationForm/videos/:type/:code"
          component={AddVideoLinks}
        />
        <Route
          path="/operationForm/timestamps/:type/:code"
          component={Timestamps}
        />
        <Route
          path="/operationForm/thumbnails/:code"
          component={AddOperationThumbnails}
        />
        <Route
          path="/operationForm/youtube-description/:code"
          component={YoutubeDescription}
        />
        <Route
          path="/operationForm/chapters/:type/:code"
          component={Chapters}
        />
        <Route
          path="/operationForm/tighteningValues/:type/:code"
          component={TighteningValues}
        />
        <Route
          path="/operationForm/motorization/:type/:code"
          component={DraftMotorization}
        />
        <Route path="/template/:code" component={Template} />
        <Route path="/drafts/:code" component={Draft} />
        <Route
          path="/generateOperation/:carName"
          component={GenerateOperation}
        />
        <Route path="/duplicateDraft/:carName">
          {/*<div>dlkjsdlkfj</div>*/}
          <DuplicateDraft />
        </Route>
        <Route
          path="/generalOperations/create/:type"
          component={CreateNewGeneralOrUsefulVideo}
        />
        <Route path="/set-hours-prices" component={SetHourPrices} />
        <Route path="/tools" component={Tools} />
        <Route path="/tool/:code" component={AddAndModifyTool} />
        <Route path="/tool" component={AddAndModifyTool} />
        <Route path="/carPart/:code" component={AddAndModifyCarPart} />
        <Route path="/carPart" component={AddAndModifyCarPart} />
        <Route path="/carParts" component={CarParts} />
        <Route path="/general-data" component={GeneralData} />
        <Route path="/sheets" component={GenerateMASheet} />
        <Route path="/tags" component={Tags} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
      </Switch>
    );
  }
}
