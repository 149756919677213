import React from 'react';
import PropTypes from 'prop-types';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateTag from '../videos/CreateTag';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';

class Tags extends React.Component {
  state = {tags: [], open: false, tagToModify: undefined};

  componentDidMount() {
    this.initTags();
  }

  initTags() {
    HttpHelper.get(ServerRoutes.tags).then(tags => {
      const options = tags.map(tag => {
        return {label: JSON.parse(tag.names).en, value: tag.id, tag};
      });
      this.setState({tags: options});
    });
  }

  deleteTag(tagId) {
    HttpHelper.delete(ServerRoutes.tags + '/' + tagId).then(() => {
      this.setState({tags: this.state.tags.filter(t => t.value !== tagId)});
    });
  }

  handleClose = () => {
    this.setState({open: false, tagToModify: undefined});
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  render() {
    const {fullScreen} = this.props;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleClickOpen()}
            style={{width: '250px'}}>
            New tag
          </Button>
        </div>
        {this.state.tags.map(tag => {
          return (
            <ListItem key={tag.value}>
              <ListItemText inset primary={tag.label} />
              <DeleteIcon onClick={() => this.deleteTag(tag.value)} />
              <Edit
                onClick={() => {
                  this.setState({tagToModify: tag.tag});
                  this.handleClickOpen();
                }}
              />
            </ListItem>
          );
        })}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'Create tag'}</DialogTitle>
          <DialogContent>
            <CreateTag
              tag={this.state.tagToModify}
              onSubmit={() => {
                this.handleClose();
                this.initTags();
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(Tags);
