import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import Select from 'react-select';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SelectCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outgoingCategories: [],
      videoCategories: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);

    HttpHelper.get(ServerRoutes.videoCategories).then(videoCategories => {
      HttpHelper.get(ServerRoutes.outgoingCategories).then(
        outgoingCategories => {
          const options = videoCategories.map(videoCategory => {
            return {
              label:
                videoCategory.code + ' - ' + videoCategory.englishTranslation,
              value: videoCategory.code,
            };
          });
          const outgoingOptions = outgoingCategories.map(outgoingCategory => {
            return {
              label:
                outgoingCategory.code +
                ' - ' +
                outgoingCategory.categoryEnglishTranslation,
              value: outgoingCategory.code,
            };
          });
          this.setState({
            videoCategories: options,
            outgoingCategories: outgoingOptions,
          });

          VideoHelper.getTemplate(code).then(template => {
            this.template = template;
            if (template.videoCategory && template.videoCategory.length) {
              const selected = videoCategories.filter(
                c => c.code === template.videoCategory,
              )[0];
              this.setState({
                selectedVideoCategory: {
                  label: selected.code + ' - ' + selected.englishTranslation,
                  value: selected.code,
                },
              });
            }
            if (template.outgoingCategory && template.outgoingCategory.length) {
              const selected = outgoingCategories.filter(
                c => c.code === template.outgoingCategory,
              )[0];
              this.setState({
                selectedOutgoingCategory: {
                  label:
                    selected.code + ' - ' + selected.categoryEnglishTranslation,
                  value: selected.code,
                },
              });
            }
          });
        },
      );
    });
  }

  submit() {
    if (
      this.state.selectedVideoCategory &&
      this.state.selectedVideoCategory.value
    ) {
      this.template.videoCategory = this.state.selectedVideoCategory.value;
    }
    if (
      this.state.selectedOutgoingCategory &&
      this.state.selectedOutgoingCategory.value
    ) {
      this.template.outgoingCategory = this.state.selectedOutgoingCategory.value;
    }
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    return (
      <form
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        autoComplete="off">
        <div style={{marginBottom: 10}}>
          <Select
            options={this.state.videoCategories}
            value={this.state.selectedVideoCategory}
            onChange={value => {
              this.setState({selectedVideoCategory: value});
            }}
            placeholder="Add video category"
          />
        </div>
        <Select
          options={this.state.outgoingCategories}
          value={this.state.selectedOutgoingCategory}
          onChange={value => {
            this.setState({selectedOutgoingCategory: value});
          }}
          placeholder="Add outgoing category"
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

SelectCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectCategories);
