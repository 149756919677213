import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import FullPageSpinner from '../util/FullPageSpinner';
import Input from '@material-ui/core/Input/Input';
import {Divider} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const Chapters = props => {
  const code = props.match.params.code.replace(/_/g, '.');
  const type = props.match.params.type;

  const [template, setTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  if (type === 'template') {
    VideoHelper.setRoute(ServerRoutes.templates);
  } else {
    VideoHelper.setRoute(ServerRoutes.drafts);
  }

  useEffect(() => {
    async function initData() {
      VideoHelper.getTemplate(code)
        .then(t => {
          return setTemplate(t);
        })
        .catch(e => console.log('e', e))
        .finally(() => setIsLoading(false));
    }
    initData();
  }, [code]);

  const {register, control, handleSubmit, reset} = useForm({
    defaultValues: {
      chapters: [
        {
          timestamp: '0:00',
          titles: {
            en: 'Intro',
            fr: 'Intro',
            es: 'Introducción',
            it: 'Introduzione',
            de: 'Einführung',
            pl: 'Wstęp',
          },
        },
      ],
    },
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'chapters',
  });

  useEffect(() => {
    if (template?.chapters) {
      reset({chapters: JSON.parse(template?.chapters)});
    }
  }, [reset, template]);

  const onSubmit = data => {
    const updatedTemplate = {
      ...template,
      chapters: JSON.stringify(data.chapters),
    };

    VideoHelper.setTemplate(updatedTemplate);
    VideoHelper.save();
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <ul>
          {fields.map((item, index) => {
            return (
              <div key={item.id} style={{marginBottom: 20}}>
                <div
                  style={{
                    display: 'flex',
                    // justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Controller
                    render={({field}) => (
                      <TextField
                        {...field}
                        style={{marginBottom: 5, marginLeft: -50, width: 100}}
                        variant="outlined"
                        label={'timestamp'}
                      />
                    )}
                    name={`chapters.${index}.timestamp`}
                    control={control}
                  />
                </div>
                <div>
                  en:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.en`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                  fr:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.fr`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                  es:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.es`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                </div>
                <div>
                  it:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.it`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                  de:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.de`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                  pl:{' '}
                  <Input
                    {...register(`chapters.${index}.titles.pl`)}
                    style={{margin: 10, minWidth: 350}}
                  />
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button style={{margin: 10}} onClick={() => remove(index)}>
                    Delete
                  </Button>
                </div>
                <Divider />
              </div>
            );
          })}
        </ul>

        <section style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            style={{margin: 10}}
            onClick={() => {
              append({title: '', timestamp: ''});
            }}>
            Add
          </Button>

          <Button
            style={{margin: 10}}
            onClick={() =>
              reset({
                chapters: [{title: 'Intro', timestamp: '0:00'}],
              })
            }>
            reset
          </Button>
        </section>

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default Chapters;
