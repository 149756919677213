import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Controller, useForm} from 'react-hook-form';
import VideoHelper from './VideoHelper';
import ServerRoutes from '../routing/ServerRoutes';
import FullPageSpinner from '../util/FullPageSpinner';

export const DraftMotorization = props => {
  const code = props.match.params.code.replace(/_/g, '.');
  const type = props.match.params.type;

  const [template, setTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  if (type === 'template') {
    VideoHelper.setRoute(ServerRoutes.templates);
  } else {
    VideoHelper.setRoute(ServerRoutes.drafts);
  }

  const {control, handleSubmit, reset} = useForm({});

  useEffect(() => {
    if (template?.motorization) {
      reset({motorization: template.motorization});
    }
  }, [reset, template]);

  useEffect(() => {
    (async () => {
      const savedTemplate = await VideoHelper.getTemplate(code);

      setTemplate(savedTemplate);
      setIsLoading(false);
    })();
  }, [code]);

  const onSubmit = data => {
    const updatedTemplate = {
      ...template,
      motorization: data.motorization,
    };
    VideoHelper.setTemplate(updatedTemplate);
    VideoHelper.save();
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Controller
          render={({field}) => (
            <input {...field} placeholder={'motorization'} />
          )}
          name={'motorization'}
          control={control}
        />
        <Button type="submit" style={{marginTop: 30}}>
          Submit
        </Button>
      </form>
    </div>
  );
};
