import React from 'react';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DisplayVariables from './TemplateHelper';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class OperationWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtube: VideoHelper.newTranslation(),
      application: VideoHelper.newTranslation(),
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);
    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      console.log(
        VideoHelper.oldTranslationsToNew(
          JSON.parse(template.warning).application,
        ),
      );
      if (template.warning && template.warning !== null) {
        const warning = JSON.parse(template.warning);
        this.setState({
          youtube: VideoHelper.oldTranslationsToNew(warning.youtube),
          application: VideoHelper.oldTranslationsToNew(warning.application),
        });
      }
    });
  }

  updateYoutube(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.youtube));
    updated[key] = value;
    this.setState({youtube: updated});
  }

  updateApplication(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.application));
    updated[key] = value;
    this.setState({application: updated});
  }

  submit() {
    const warning = {
      youtube: this.state.youtube,
      application: this.state.application,
    };
    this.template.warning = JSON.stringify(warning);
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    const {classes} = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        autoComplete="off">
        <h1>Youtube warning</h1>
        <div style={{flexDirection: 'row'}}>
          {Object.keys(this.state.youtube).map(key => {
            return (
              <div style={{float: 'left'}}>
                <TextField
                  key={key}
                  style={{width: '350px'}}
                  multiline
                  rows="4"
                  id="outlined-name"
                  label={key}
                  value={this.state.youtube[key]}
                  className={classes.textField}
                  onChange={e => {
                    this.updateYoutube(key, e.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                {this.props.match.params.type === 'template' && (
                  <DisplayVariables
                    value={this.state.youtube[key]}
                    onChange={value => this.updateYoutube(key, value)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <h1>App warning</h1>
        <div style={{flexDirection: 'row'}}>
          {Object.keys(this.state.application).map(key => {
            return (
              <div style={{float: 'left'}}>
                <TextField
                  multiline
                  style={{width: '350px'}}
                  rows="4"
                  key={key}
                  id="outlined-name"
                  label={key}
                  value={this.state.application[key]}
                  className={classes.textField}
                  onChange={e => {
                    this.updateApplication(key, e.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                {this.props.match.params.type === 'template' && (
                  <DisplayVariables
                    value={this.state.application[key]}
                    onChange={value => this.updateApplication(key, value)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

OperationWarning.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationWarning);
