import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const variables = [
  '{{brand}}',
  '{{model}}',
  '{{MOTOR1D}}',
  '{{MOTOR1G}}',
  '{{link}}',
];

class DisplayVariables extends React.Component {
  render() {
    const display = this.props.value[this.props.value.length - 1] === '{';
    if (!display) {
      return null;
    }
    return (
      <Paper style={{position: 'absolute', zIndex: 9}} elevation={1}>
        <Typography component="p">Variables</Typography>
        {variables.map(variable => {
          return (
            <ListItem
              button
              onClick={() =>
                this.props.onChange(this.props.value.slice(0, -1) + variable)
              }>
              <ListItemText primary={variable} />
            </ListItem>
          );
        })}
      </Paper>
    );
  }
}

export default DisplayVariables;
