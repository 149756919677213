import React from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import VideoHelper from './VideoHelper';
import {useParams} from 'react-router-dom';
import Input from '@material-ui/core/Input/Input';

const DuplicateDraft = props => {
  let params = useParams();

  const [duplicatedCode, setDuplicatedCode] = React.useState();

  const duplicate = async () => {
    const carName = params.carName;
    const newCar = await HttpHelper.get(ServerRoutes.findCarInfo(carName));

    const draft = await HttpHelper.get(
      ServerRoutes.drafts + '/' + duplicatedCode,
    );
    const draftTitles = JSON.parse(draft.names);
    const oldCarCode = duplicatedCode.split('.')[0];
    const oldCar = await HttpHelper.get(ServerRoutes.findByCode(oldCarCode));
    const newDraftCode =
      newCar.car.code +
      '.' +
      duplicatedCode.split('.')[1] +
      '.' +
      duplicatedCode.split('.')[2];

    const newCarName = newCar.car.name;
    const newCarBrand = newCar.car.brand;

    const newDraft = {
      ...draft,
      code: newDraftCode,
      youtubeDescription: _.mapValues(draft.youtubeDescription, desc =>
        desc
          .replaceAll(oldCar.name, newCarName)
          .replaceAll(oldCar.brand, newCarBrand),
      ),
      names: JSON.stringify({
        youtube: _.mapValues(draftTitles.youtube, title =>
          title
            .replaceAll(oldCar.name, newCarName)
            .replaceAll(oldCar.brand, newCarBrand),
        ),
        application: _.mapValues(draftTitles.application, title =>
          title
            .replaceAll(oldCar.name, newCarName)
            .replaceAll(oldCar.brand, newCarBrand),
        ),
      }),
    };

    VideoHelper.setRoute(ServerRoutes.drafts);
    VideoHelper.setTemplate(newDraft);
    await VideoHelper.save();
  };

  const onChangeDraftCodeToDuplicate = e => {
    setDuplicatedCode(e.target.value);
  };

  return (
    <div
      style={{
        marginTop: 100,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Input
        placeholder="Code of the draft to duplicate"
        value={duplicatedCode}
        name="duplicatedCode"
        onChange={onChangeDraftCodeToDuplicate}
        style={{
          width: 300,
        }}
      />

      <Button
        onClick={duplicate}
        style={{
          marginLeft: 20,
          width: 300,
        }}>
        Duplicate from draft
      </Button>
    </div>
  );
};

export default DuplicateDraft;
