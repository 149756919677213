import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import ClientRoutes from '../routing/ClientRoutes';
import BrandsSearch from './BrandsSearch';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

class AddCar extends Component {
  step1 = {step: 1, name: 'Step 1: brand'};
  step2 = {step: 2, name: 'Step 2: car name'};

  constructor(props) {
    super(props);

    if (this.props.match.params.brandName !== undefined) {
      this.state = {
        actualStep: this.step2,
        brandName: this.props.match.params.brandName,
        newCarName: '',
        carCode: '',
      };
    } else {
      this.state = {
        actualStep: this.step1,
        brandName: '',
        areBrandsReadyForSearch: false,
        newCarName: '',
        carCode: '',
      };
    }

    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleCarNameChange = this.handleCarNameChange.bind(this);
    this.handleCarCodeChange = this.handleCarCodeChange.bind(this);
  }

  componentDidMount() {
    HttpHelper.get(ServerRoutes.brands).then(brands => {
      this.setState({
        brands,
        areBrandsReadyForSearch: true,
      });
    });
  }

  chooseBrand(brand) {
    this.setState({
      brandName: brand,
      actualStep: this.step2,
    });
  }

  addNewCarName(carName) {
    HttpHelper.post(
      ServerRoutes.addCar(this.state.brandName, carName, this.state.carCode),
    ).then(() => {
      this.props.history.push(ClientRoutes.carDashboard(carName));
    });
  }

  handleBrandChange(event) {
    this.setState({brandName: event.target.value});
  }

  handleCarNameChange(event) {
    this.setState({newCarName: event.target.value});
  }

  handleCarCodeChange(event) {
    this.setState({carCode: event.target.value});
  }

  render() {
    const brandSearch = this.state.areBrandsReadyForSearch ? (
      <div key="searchBrand" style={{width: '60%', marginBottom: 40}}>
        <BrandsSearch
          brands={this.state.brands}
          onSelectBrand={value => this.chooseBrand(value)}
        />
      </div>
    ) : null;

    const addBrand = (
      <form
        key="addBrandForm"
        style={{
          width: '60%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        <Input
          placeholder="New brand"
          value={this.state.brandName}
          onChange={this.handleBrandChange}
        />
        <Button
          type="button"
          onClick={() => this.chooseBrand(this.state.brandName)}
          style={{marginLeft: 20}}>
          Add a new brand
        </Button>
      </form>
    );

    const step2 = (
      <form
        style={{
          width: '60%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        <Input
          placeholder="New car name"
          value={this.state.newCarName}
          onChange={this.handleCarNameChange}
        />

        <Input
          placeholder="Car code"
          value={this.state.carCode}
          onChange={this.handleCarCodeChange}
          style={{marginLeft: 30}}
        />

        <Button
          type="button"
          onClick={() => this.addNewCarName(this.state.newCarName)}
          style={{marginLeft: 30}}>
          Submit
        </Button>
      </form>
    );

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <h1>{this.state.actualStep.name}</h1>
        {this.state.actualStep.step === 1 ? [brandSearch, addBrand] : step2}
      </div>
    );
  }
}

export default AddCar;
