function getUsers(offset, numberToReturn) {
  return '/api/users?offset=' + offset + '&numberToReturn=' + numberToReturn;
}

function addCar(brand, car, carCode) {
  return '/api/cars?brand=' + brand + '&car=' + car + '&carCode=' + carCode;
}

function updateCarName(carName, carCode) {
  return '/api/carsName?carName=' + carName + '&carCode=' + carCode;
}

function updateEnglishCarName(englishCarName, carCode) {
  return (
    '/api/englishCarsName?englishCarName=' +
    englishCarName +
    '&carCode=' +
    carCode
  );
}

function findCarInfo(carName) {
  return '/api/carsBackOfficeInfo?carName=' + carName;
}

function findByCode(code) {
  return '/api/carsByCode?code=' + code;
}

function updateIsCarPublishedOnApp(carName, isCarPublishedOnApp) {
  return (
    '/api/cars/isCarPublishedOnApp?carName=' +
    carName +
    '&isCarPublishedOnApp=' +
    isCarPublishedOnApp
  );
}

function findMotorizationPeriodicities(periodicityCode) {
  return '/api/periodicities?periodicityCode=' + periodicityCode;
}

function findCarDrafts(carCode) {
  return '/api/car/drafts?carCode=' + carCode;
}

function deletePeriodicities(periodicityCode, operationCode) {
  return (
    '/api/periodicities?periodicityCode=' +
    periodicityCode +
    '&operationCode=' +
    operationCode
  );
}

function findOperations(operationCode) {
  return '/api/operations?code=' + operationCode;
}

function findOperationCarParts(operationCode) {
  return '/api/operations/carParts?code=' + operationCode;
}

function findOperationTools(operationCode) {
  return '/api/operations/tools?code=' + operationCode;
}

const ServerRoutes = {
  getUsers,
  addCar,
  updateCarName,
  updateEnglishCarName,
  findCarInfo,
  updateIsCarPublishedOnApp,
  findMotorizationPeriodicities,
  findCarDrafts,
  deletePeriodicities,
  findByCode,
  findOperations,
  findOperationCarParts,
  findOperationTools,
  periodicities: '/api/periodicities',
  brands: '/api/brands',
  motorizations: '/api/motorizations',
  findMotorizationsByCarName: carName => '/api/motorizations?car=' + carName,
  cars: '/api/cars',
  templates: '/api/templates',
  drafts: '/api/drafts',
  usefulDrafts: '/api/useful/drafts',
  generalDrafts: '/api/general/drafts',
  tools: '/api/tools',
  carParts: '/api/carParts',
  usefulVideos: '/api/usefulVideos',
  generalVideos: '/api/generalVideos',
  tags: '/api/tags',
  links: '/api/links',
  files: '/api/files',
  videoCategories: '/api/video/categories',
  outgoingCategories: '/api/categories/all',
  hourPrices: '/api/hourPrices',
  generalData: '/api/generalData',
  affiliationLinks: '/api/cars/affiliationLinks',
  generateOperation: (carName, templateCode, operationCode) => {
    return (
      '/api/drafts/generate/' +
      carName +
      '/' +
      templateCode +
      '/' +
      operationCode
    );
  },
  duplicateTemplate: (code, newCode) => {
    return '/api/templates/duplicate/' + code + '/' + newCode;
  },
  publishOperations: draftCode => {
    return '/api/drafts/' + draftCode + '/publish';
  },
  shortenUrls: '/api/shortenUrls',
  customizeTags: '/api/customizeTags',
};

export default ServerRoutes;
