import React, {Component} from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import CartPartImage from '../carParts/CarPartImage';
import ToolImage from '../Tools/ToolImage';
import uuidv1 from 'uuid';
import {emojify} from 'react-emojione';

class PreviewOperation extends Component {
  state = {
    draft: this.props.draft,
    videoLinks: JSON.parse(this.props.draft.videoLinks),
    names: JSON.parse(this.props.draft.names),
    info: JSON.parse(this.props.draft.info),
    warnings: JSON.parse(this.props.draft.warning),
    modalOpen: false,
    platformTabValue: 0,
    languageTabValue: 0,
    carParts: [],
    tools: [],
    tags: [],
    tightening_values: JSON.parse(this.props.draft.tightening_values || '[]'),
  };

  componentDidMount() {
    this.initTags();

    HttpHelper.get(ServerRoutes.carParts).then(serverCarParts => {
      let carParts = [];

      for (let i = 0; i < JSON.parse(this.props.draft.parts).length; i++) {
        const carPartCode = JSON.parse(this.props.draft.parts)[i];
        const maybeCarPart = serverCarParts.filter(t => t.code === carPartCode);

        maybeCarPart.length > 0 &&
          carParts.push({
            carPart: maybeCarPart[0],
            image: <CartPartImage code={carPartCode} />,
          });
      }
      this.setState({carParts});
    });

    HttpHelper.get(ServerRoutes.tools).then(serverTools => {
      let tools = [];

      for (let i = 0; i < JSON.parse(this.props.draft.tools).length; i++) {
        const toolCode = JSON.parse(this.props.draft.tools)[i];
        const maybeTool = serverTools.filter(t => t.code === toolCode);

        maybeTool.length > 0 &&
          tools.push({
            tool: maybeTool[0],
            image: <ToolImage code={toolCode} />,
          });
      }
      this.setState({tools});
    });
  }

  initTags() {
    if (this.state.draft.tags) {
      HttpHelper.get(ServerRoutes.tags).then(allTags => {
        const tags = allTags.filter(t =>
          JSON.parse(this.state.draft.tags).includes(t.id),
        );

        this.setState({tags});
      });
    }
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  changeModalOpenState = () => {
    this.setState({modalOpen: !this.state.modalOpen});
  };

  changePlatformTab(platformTabValue) {
    this.setState({platformTabValue});
  }

  changeLanguageTab(languageTabValue) {
    this.setState({languageTabValue});
  }

  static returnEmbedLink(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    try {
      const id = match[2];

      return 'https://www.youtube.com/embed/' + id;
    } catch (error) {
      return 'https://www.youtube.com/embed/' + url;
    }
  }

  static isVideoLinkPresent(videoLinks, language) {
    if (videoLinks === null) {
      return false;
    } else {
      return videoLinks.application[language] !== '';
    }
  }

  applicationPreview(language) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {PreviewOperation.isVideoLinkPresent(
          this.state.videoLinks,
          language,
        ) ? (
          <iframe
            width="533"
            height="300"
            title="Youtube video"
            src={PreviewOperation.returnEmbedLink(
              this.state.videoLinks.application[language],
            )}
          />
        ) : (
          <h3>No video links for this language</h3>
        )}
        <div
          style={{
            backgroundColor: '#223D55',
            color: 'white',
            width: '533px',
            padding: '10px',
          }}>
          <div style={{fontSize: '25', fontWeight: 'bold'}}>
            {this.state.names.application[language]}
          </div>

          <div>
            <span>{this.state.draft.time} min</span>

            <span style={{marginLeft: '20px'}}>
              difficulty: {this.state.draft.difficulty}
            </span>
          </div>
        </div>
        <div>
          <b>Car parts:</b>
          <div style={{display: 'flex'}}>
            {this.state.carParts.map(carPart => (
              <div
                key={carPart.carPart.code}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {carPart.image}
                {carPart.carPart.englishName}
              </div>
            ))}
          </div>
        </div>
        <div>
          <b>Tools:</b>
          <div style={{display: 'flex'}}>
            {!this.state.isLoading &&
              this.state.tools.map(tool => (
                <div
                  key={tool.tool.code}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  {tool.image}
                  {tool.tool.englishName}
                </div>
              ))}
          </div>
        </div>
        <div style={{color: '#FB6262'}}>
          <b>Warnings:</b>
          <ul>
            {this.state.warnings.application[language].split('\n').map(w => (
              <li key={uuidv1()}>{w}</li>
            ))}
          </ul>
        </div>
        <div style={{color: '#223D55'}}>
          <b>Info:</b>
          <ul>
            {this.state.info.application[language].split('\n').map(i => (
              <li key={uuidv1()}>{i}</li>
            ))}
          </ul>
        </div>
        + {JSON.parse(this.state.draft.usefulVideos).length} useful videos
      </div>
    );
  }

  youtubePreview(language) {
    return (
      <div>
        <h2 style={{textAlign: 'center'}}>
          {JSON.parse(this.state.draft.names).youtube[language] === ''
            ? 'No title for this language'
            : JSON.parse(this.state.draft.names).youtube[language]}
        </h2>
        <br />
        <b>
          Tags :
          {this.state.tags.length === 0
            ? ' No tags selected'
            : this.state.tags.map(tag => {
                return (
                  <span style={{marginRight: 10}}>
                    {JSON.parse(tag.names)[language]}
                  </span>
                );
              })}
        </b>
        <br />
        <pre style={{whiteSpace: 'pre-wrap'}}>
          {emojify(this.state.draft.youtubeDescription[language])}
        </pre>
      </div>
    );
  }

  render() {
    const modal = (
      <Dialog fullScreen open={this.state.modalOpen}>
        <AppBar style={{position: 'relative'}}>
          <Tabs
            value={this.state.platformTabValue}
            centered
            style={{width: '100%'}}>
            <Tab
              onClick={() => this.changePlatformTab(0)}
              label="Application"
            />
            <Tab onClick={() => this.changePlatformTab(1)} label="Youtube" />
            <IconButton
              style={{position: 'absolute', right: 0}}
              onClick={this.changeModalOpenState}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Tabs>

          <Tabs
            value={this.state.languageTabValue}
            centered
            style={{width: '100%'}}>
            <Tab onClick={() => this.changeLanguageTab(0)} label="English" />
            <Tab onClick={() => this.changeLanguageTab(1)} label="French" />
            <Tab onClick={() => this.changeLanguageTab(2)} label="Spanish" />
          </Tabs>
        </AppBar>

        {this.state.platformTabValue === 0 && (
          <div style={{marginTop: '20px'}}>
            {this.state.languageTabValue === 0 && this.applicationPreview('en')}
            {this.state.languageTabValue === 1 && this.applicationPreview('fr')}
            {this.state.languageTabValue === 2 && this.applicationPreview('es')}
          </div>
        )}

        {this.state.platformTabValue === 1 && (
          <div style={{marginLeft: '20px', marginRight: '20px'}}>
            {this.state.languageTabValue === 0 && this.youtubePreview('en')}
            {this.state.languageTabValue === 1 && this.youtubePreview('fr')}
            {this.state.languageTabValue === 2 && this.youtubePreview('es')}
          </div>
        )}
      </Dialog>
    );

    return (
      <div>
        {modal}

        <Button
          onClick={() => this.changeModalOpenState()}
          style={{width: 300, backgroundColor: '#5868a3'}}>
          Preview
        </Button>
      </div>
    );
  }
}

export default PreviewOperation;
