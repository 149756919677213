import concatenateDescription from './YoutubeDescriptionConcatainer';
import ServerRoutes from '../routing/ServerRoutes';
import HttpHelper from '../routing/HttpHelper';
import getBitlyLinksFromCarPartsCode from '../bitly/getBitlyLinksFromCarPartsCode';

export default async function generateDescription(draft) {
  const carCode = draft.code.split('.')[0];

  const car = await HttpHelper.get(ServerRoutes.findByCode(carCode));

  const tools = await HttpHelper.get(ServerRoutes.tools);

  const draftTools = tools.filter(t =>
    JSON.parse(draft.tools).includes(t.code),
  );
  const operationFrenchName = JSON.parse(draft.names).application.fr;

  const {bitLinks, customLinks} = await getBitlyLinksFromCarPartsCode(
    carCode,
    JSON.parse(draft.parts),
    operationFrenchName,
  );

  const generalData = await HttpHelper.get(ServerRoutes.generalData);

  const allCarParts = await HttpHelper.get(ServerRoutes.carParts);
  const draftCarParts = allCarParts.filter(t =>
    JSON.parse(draft.parts).includes(t.code),
  );

  return Promise.all(
    JSON.parse(draft.usefulVideos).map(code => {
      if (code.startsWith('00')) {
        return HttpHelper.get(ServerRoutes.findOperations(code));
      } else {
        return HttpHelper.get(ServerRoutes.drafts + '/' + code);
      }
    }),
  ).then(usefulVideos => {
    return {
      descs: concatenateDescription(
        draft,
        generalData,
        draftTools,
        draftCarParts,
        usefulVideos,
        car,
        bitLinks,
        allCarParts,
      ),
      customLinks,
      numberOfNotFoundLinks: bitLinks.filter(l => l.error).length,
    };
  });
}
