import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import prepareMASheet from './prepareMASheet';
import Input from '@material-ui/core/Input/Input';
import {toastr} from 'react-redux-toastr';
import {CircularProgress} from '@material-ui/core';

const GenerateMASheet = () => {
  // const [operationCode, setOperationCode] = useState('');
  const [operationCode, setOperationCode] = useState('02.01.01');
  const [customBitlys, setCustomBitlys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onPressGenerate = async () => {
    try {
      setIsLoading(true);
      const customLinks = await prepareMASheet(operationCode);
      setCustomBitlys(customLinks);
      toastr.success('Success', 'Sheet generated');
    } catch (e) {
      toastr.error('Error', 'Please retry later');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Input
          placeholder="Operation code"
          value={operationCode}
          name="carName"
          onChange={v => setOperationCode(v.target.value)}
          style={{width: '250px', marginTop: 50}}
        />

        {customBitlys.map(l => (
          <Text>{l}</Text>
        ))}

        {isLoading ? (
          <CircularProgress color="primary" style={{marginTop: 50}} />
        ) : (
          <Button
            variant="contained"
            onClick={onPressGenerate}
            color="primary"
            style={{width: '250px', marginTop: 50}}>
            Generate Sheet
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenerateMASheet;
