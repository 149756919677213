import React from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import history from '../routing/history';
import VideoHelper from '../videos/VideoHelper';
import Dropzone from 'react-dropzone';
import {toastr} from 'react-redux-toastr';

class CarPart {
  constructor(
    code = '',
    frenchName = '',
    englishName = '',
    spanishName = '',
    italianName = '',
    germanName = '',
    polishName = '',
  ) {
    this.code = code;
    this.frenchName = frenchName;
    this.englishName = englishName;
    this.spanishName = spanishName;
    this.italianName = italianName;
    this.germanName = germanName;
    this.polishName = polishName;
  }
}

let that;

class AddAndModifyCarPart extends React.Component {
  state = {
    carPart: new CarPart(),
    image: <h3>No image</h3>,
  };

  componentDidMount() {
    that = this;
    const code = this.props.match.params.code;

    HttpHelper.get(ServerRoutes.carParts).then(carParts => {
      const maybeCarPart = carParts.filter(t => t.code === code);
      maybeCarPart.length > 0 &&
        this.setState({
          carPart: maybeCarPart[0],
          image: (
            <img
              onError={() => this.onError()}
              src={
                'https://s3.eu-west-3.amazonaws.com/testmc12/' +
                maybeCarPart[0].code +
                '.png'
              }
              alt={'carPart'}
            />
          ),
        });
    });
  }

  onError() {
    const image = () => {
      try {
        return (
          <img
            src={require('../images/carParts/' +
              this.state.carPart.code +
              '.png')}
            alt={'carPart'}
          />
        );
      } catch (err) {
        return <h3>No image</h3>;
      }
    };
    this.setState({image: image()});
  }

  submit() {
    HttpHelper.post(
      ServerRoutes.carParts,
      JSON.stringify(this.state.carPart),
    ).then(() => {
      history.push('/carParts');
      window.location.href = '/carParts';
    });
  }

  onDrop(acceptedFiles) {
    const file = acceptedFiles[0];

    if (
      (file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg') &&
      that.state.carPart.code.trim().length > 0
    ) {
      const token = window.localStorage.getItem('token');

      const data = new FormData();
      data.append('picture', file, that.state.carPart.code + '.png');
      fetch(ServerRoutes.files, {
        method: 'POST',
        body: data,
        headers: {
          'X-Auth-Token': token,
        },
      }).then(response => {
        if (response.ok) {
          const img =
            'https://s3.eu-west-3.amazonaws.com/testmc12/' +
            that.state.carPart.code +
            '.png';
          that.setState({image: <img src={img} alt={'carPart'} />});
          that.template.operationIcon = img;
          VideoHelper.setTemplate(that.template);
          VideoHelper.save();
        } else {
          toastr.error('An error occurred', 'Amazon error');
        }
      });
    } else {
      toastr.error('An error occurred', 'Wrong file type');
    }
  }

  render() {
    return (
      <div style={{margin: 20}}>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}>
          {this.state.image}
          {Object.keys(this.state.carPart).map(key => {
            return (
              <TextField
                key={key}
                id="outlined-name"
                label={key}
                value={this.state.carPart[key]}
                onChange={e => {
                  const updated = this.state.carPart;
                  updated[key] = e.target.value;
                  this.setState({carPart: updated});
                }}
                margin="normal"
                variant="outlined"
              />
            );
          })}
          <Dropzone
            onDrop={files => this.onDrop(files)}
            disabled={this.state.carPart.code.trim().length === 0}>
            {this.state.carPart.code.trim().length === 0 ? (
              <div>Enter a carPart code before upload an image</div>
            ) : (
              <div>
                Drop the carPart image here, or click to search in your file
                system
              </div>
            )}
          </Dropzone>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 50,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default AddAndModifyCarPart;
