import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Input from '@material-ui/core/Input/Input';
import Modify from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button/Button';
import {toastr} from 'react-redux-toastr';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import PeriodicityDashboard from '../periodicities/PeriodicityDashboard';
import {List, ListItem} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {AddMotorizations} from './AddMotorizations';
import {AddPeriodicities} from '../periodicities/AddPeriodicities';

class MotorizationDashboard extends Component {
  state = {
    carName: this.props.carName,
    motorizations: this.props.motorizations,
    updateMotorizationModalOpen: false,
    updatedMotorizationUUID: '',
    updatedMotorizationName: '',
    updatedMotorizationType: 'diesel',
    isUpdatedMotorizationTutorialized: 'yes',
  };

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleUpdateMotorizationModalClose = () => {
    this.setState({updateMotorizationModalOpen: false});
  };

  openUpdateMotorizationModal(uuid, name, type, isTutorialized) {
    this.setState({
      updateMotorizationModalOpen: true,
      updatedMotorizationUUID: uuid,
      updatedMotorizationName: name,
      updatedMotorizationType: type,
      isUpdatedMotorizationTutorialized: isTutorialized ? 'yes' : 'no',
    });
  }

  updateMotorization(uuid, name, type, isTutorialized) {
    const isTutorializedBool = isTutorialized === 'yes';
    const motorization = {
      uuid,
      carCode: this.state.carName,
      motorizationType: type,
      motorization: name,
      periodicityCode: 0,
      isTutorialized: isTutorializedBool,
    };

    HttpHelper.update(
      ServerRoutes.motorizations,
      JSON.stringify(motorization),
    ).then(response => {
      if (response.ok) {
        toastr.success('Success', 'Motorization has been updated');

        const newMotorizations = this.state.motorizations.map(el =>
          el.uuid === uuid ? motorization : el,
        );

        this.setState({motorizations: newMotorizations});

        this.handleUpdateMotorizationModalClose();
      } else {
        toastr.error('Error', 'An error occurred');
      }
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <div>
        <AddMotorizations
          carName={this.state.carName}
          addMotorizationsToState={newMotorizations =>
            this.setState({
              motorizations: this.state.motorizations.concat(newMotorizations),
            })
          }
        />

        <AddPeriodicities carName={this.state.carName} />

        <div style={{marginTop: '60px'}}>
          <List>
            {this.state.motorizations.map(motorization => (
              <>
                <ListItem selected>
                  {motorization.motorization} {motorization.motorizationType}
                  {motorization.isTutorialized
                    ? ' tutorialized'
                    : ' not tutorialized'}
                  <Modify
                    style={{marginLeft: '10px'}}
                    onClick={() =>
                      this.openUpdateMotorizationModal(
                        motorization.uuid,
                        motorization.motorization,
                        motorization.motorizationType,
                        motorization.isTutorialized,
                      )
                    }
                  />
                </ListItem>

                <ListItem key={motorization.uuid}>
                  <PeriodicityDashboard motorization={motorization} />
                </ListItem>

                <Divider />
              </>
            ))}
          </List>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.updateMotorizationModalOpen}
            onClose={this.handleClose}>
            <div className={classes.paper}>
              <Typography
                variant="h3"
                id="modal-title"
                style={{textAlign: 'center', marginBottom: '30px'}}>
                Update motorization
              </Typography>

              <div id="simple-modal-description">
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                  <Input
                    placeholder="Motorization name"
                    value={this.state.updatedMotorizationName}
                    name="updatedMotorizationName"
                    onChange={this.handleChange}
                  />

                  <Select
                    value={this.state.updatedMotorizationType}
                    onChange={this.handleChange}
                    name="updatedMotorizationType">
                    <MenuItem value={'diesel'}>Diesel</MenuItem>
                    <MenuItem value={'essence'}>Gasoline</MenuItem>
                  </Select>
                </div>

                <div style={{margin: 20, textAlign: 'center'}}>
                  <FormLabel component="legend">
                    Is motorization tutorialized?
                  </FormLabel>
                  <RadioGroup
                    aria-label="Is motorization tutorialized?"
                    name="isUpdatedMotorizationTutorialized"
                    value={this.state.isUpdatedMotorizationTutorialized}
                    onChange={this.handleChange}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button
                  type="button"
                  onClick={() =>
                    this.updateMotorization(
                      this.state.updatedMotorizationUUID,
                      this.state.updatedMotorizationName,
                      this.state.updatedMotorizationType,
                      this.state.isUpdatedMotorizationTutorialized,
                    )
                  }>
                  Update
                </Button>

                <Button
                  onClick={() => this.handleUpdateMotorizationModalClose()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: 150,
    left: 150,
  },
});

export default withStyles(styles)(MotorizationDashboard);
