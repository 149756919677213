import history from '../routing/history';

export default function signOut(e) {
  e.preventDefault();

  const token = window.localStorage.getItem('token');

  fetch('/api/signOut', {headers: {'X-Auth-Token': token}}).then(response => {
    if (response.ok) {
      window.localStorage.removeItem('token');
      history.push('/');
      window.location = '/';
    } else {
      //THINK TO HANDLE ERROR
    }
  });
}
