import React from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import history from '../routing/history';
import VideoHelper from '../videos/VideoHelper';
import Dropzone from 'react-dropzone';
import {toastr} from 'react-redux-toastr';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tool from './Tool';

class AddAndModifyTool extends React.Component {
  state = {
    tool: new Tool(),
    loading: true,
    image: <h3>No image</h3>,
  };

  componentDidMount() {
    const code = this.props.match.params.code;

    if (code) {
      HttpHelper.get(ServerRoutes.tools).then(tools => {
        const maybeTool = tools.filter(t => t.code === code);
        if (maybeTool.length > 0) {
          const tool = maybeTool[0];

          if (!tool.italianName) {
            tool.italianName = '';
          }
          if (!tool.germanName) {
            tool.germanName = '';
          }
          if (tool.links && tool.links.length) {
            tool.links = JSON.parse(maybeTool[0].links);
          } else {
            tool.links = VideoHelper.newTranslation();
          }

          // if (tool.bitlyLinks && tool.bitlyLinks.length) {
          //   tool.bitlyLinks = JSON.parse(maybeTool[0].bitlyLinks);
          // } else {
          //   tool.bitlyLinks = VideoHelper.newTranslation();
          // }

          this.setState({
            loading: false,
            tool,
            image: (
              <img
                onError={() => this.onError()}
                src={
                  'https://s3.eu-west-3.amazonaws.com/testmc12/' +
                  maybeTool[0].code +
                  '.png'
                }
                alt={'tool'}
              />
            ),
          });
        }
      });
    } else {
      this.setState({loading: false});
    }
  }

  onError() {
    const image = () => {
      try {
        return (
          <img
            src={require('../images/tools/' + this.state.tool.code + '.png')}
            alt={'tool'}
          />
        );
      } catch (err) {
        return <h3>No image</h3>;
      }
    };
    this.setState({image: image()});
  }

  submit() {
    const toPost = this.state.tool;
    toPost.links = JSON.stringify(this.state.tool.links);
    toPost.bitlyLinks = JSON.stringify(this.state.tool.bitlyLinks);

    HttpHelper.post(ServerRoutes.tools, JSON.stringify(toPost))
      .then(response => {
        if (response && response.ok) {
          history.push('/tools');
          window.location.href = '/tools';
          toastr.success('Success', 'Tool modified');
        } else {
          toastr.error(
            'Error',
            'Tool modification has not been taken into account',
          );
        }
      })
      .catch(() =>
        toastr.error(
          'Error',
          'Tool modification has not been taken into account',
        ),
      );
  }

  onDrop(acceptedFiles) {
    const file = acceptedFiles[0];
    if (
      (file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg') &&
      this.state.tool.code.trim().length > 0
    ) {
      const token = window.localStorage.getItem('token');

      const data = new FormData();
      data.append('picture', file, this.state.tool.code + '.png');

      fetch(ServerRoutes.files, {
        method: 'POST',
        body: data,
        headers: {
          'X-Auth-Token': token,
        },
      }).then(response => {
        if (response.ok) {
          const img =
            'https://s3.eu-west-3.amazonaws.com/testmc12/' +
            this.state.tool.code +
            '.png';
          this.setState({image: <img src={img} alt={'tool'} />});
          this.template.operationIcon = img;
          VideoHelper.setTemplate(this.template);
          VideoHelper.save();
        } else {
          toastr.error('Error', 'Image had not been saved on Amazon');
        }
      });
    } else {
      toastr.error('Error', 'Wrong file type');
    }
  }

  render() {
    return this.state.loading ? (
      <LinearProgress />
    ) : (
      <form
        style={{margin: 20}}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}>
        {this.state.image}

        {Object.keys(this.state.tool).map(key => {
          if (
            typeof this.state.tool[key] === 'object' &&
            key !== 'bitlyLinks'
          ) {
            return (
              <div key={key}>
                <h3>{key}</h3>

                {Object.keys(this.state.tool[key]).map(subKey => {
                  return (
                    <TextField
                      key={subKey}
                      id="outlined-name"
                      label={subKey}
                      value={this.state.tool[key][subKey]}
                      onChange={e => {
                        e.preventDefault();
                        const updated = this.state.tool;
                        updated[key][subKey] = e.target.value;
                        this.setState({tool: updated});
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  );
                })}
              </div>
            );
          } else if (key !== 'bitlyLinks') {
            return (
              <TextField
                key={key}
                id="outlined-name"
                label={key}
                value={this.state.tool[key]}
                onChange={e => {
                  e.preventDefault();
                  const updated = this.state.tool;
                  updated[key] = e.target.value;
                  this.setState({tool: updated});
                }}
                margin="normal"
                variant="outlined"
              />
            );
          }
        })}

        <Dropzone
          onDrop={files => this.onDrop(files)}
          disabled={this.state.tool.code.trim().length === 0}>
          {this.state.tool.code.trim().length === 0 ? (
            <div>Enter a tool code before uploading an image</div>
          ) : (
            <div>
              Drop the tool image here, or click to search in your file system
            </div>
          )}
        </Dropzone>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

export default AddAndModifyTool;
