import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import history from '../routing/history';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class countries {
  constructor(
    en = 75,
    fr = 75,
    es = 72,
    b = 75,
    de = 72,
    it = 58,
    pl = 65,
    p = 78,
    nl = 80,
    hr = 65,
    s = 90,
    ag = 50,
    mo = 50,
    ts = 50,
    ar = 45,
    mx = 45,
    cl = 45,
    srb = 55,
    ie = 75,
    scotland = 75,
    wales = 75,
    other = 70,
  ) {
    this.en = en;
    this.fr = fr;
    this.es = es;
    this.b = b;
    this.de = de;
    this.it = it;
    this.pl = pl;
    this.p = p;
    this.nl = nl;
    this.hr = hr;
    this.s = s;
    this.ag = ag;
    this.mo = mo;
    this.ts = ts;
    this.ar = ar;
    this.mx = mx;
    this.cl = cl;
    this.srb = srb;
    this.ie = ie;
    this.scotland = scotland;
    this.wales = wales;
    this.other = other;
  }
}

class SetHourPrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hourPrices: {prices: new countries()},
      autocomplete: null,
    };
  }

  componentDidMount() {
    HttpHelper.get(ServerRoutes.hourPrices).then(prices => {
      if (prices.length > 0) {
        const parsed = JSON.parse(prices[0].prices);
        prices[0].prices = parsed;
        this.setState({hourPrices: prices[0]});
      }
    });
  }

  updatePrice(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.hourPrices));
    updated.prices[key] = value;
    this.setState({hourPrices: updated});
  }

  submit() {
    const toUpdate = JSON.parse(JSON.stringify(this.state.hourPrices));
    toUpdate.prices = JSON.stringify(toUpdate.prices);
    HttpHelper.post(ServerRoutes.hourPrices, JSON.stringify(toUpdate)).then(
      response => {
        if (response.ok) {
          history.push('/everything-worked-well');
          window.location.href = '/everything-worked-well';
        }
      },
    );
  }

  render() {
    const {classes} = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        autoComplete="off">
        <h1>Hour prices by country</h1>
        <div style={{flexDirection: 'row'}}>
          {Object.keys(this.state.hourPrices.prices).map(key => {
            return (
              <div style={{float: 'left'}}>
                <TextField
                  key={key}
                  id="outlined-name"
                  label={key}
                  value={this.state.hourPrices.prices[key]}
                  className={classes.textField}
                  onChange={e => {
                    this.updatePrice(key, e.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

SetHourPrices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SetHourPrices);
