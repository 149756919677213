import React, {Component} from 'react';

import Dropzone from 'react-dropzone';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';

let that;

class AddOperationIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };
  }

  componentDidMount() {
    that = this;
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;

    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);

    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      if (template.operationIcon && template.operationIcon !== null) {
        this.setState({image: template.operationIcon});
      }
    });
  }

  static onDrop(acceptedFiles) {
    const file = acceptedFiles[0];
    if (
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpeg'
    ) {
      const token = window.localStorage.getItem('token');

      const data = new FormData();
      data.append('picture', file, that.props.match.params.code + '.jpg');
      fetch(ServerRoutes.files, {
        method: 'POST',
        body: data,
        headers: {
          'X-Auth-Token': token,
        },
      }).then(response => {
        if (response.ok) {
          const img =
            'https://s3.eu-west-3.amazonaws.com/testmc12/' +
            that.props.match.params.code +
            '.jpg';
          that.setState({image: img});
          that.template.operationIcon = img;
          VideoHelper.setTemplate(that.template);
          VideoHelper.save();
        } else {
          //THINK TO HANDLE ERROR
        }
      });
    } else {
      console.log('bad file type');
      //DONT FORGET TO Handle error
    }
  }

  render() {
    let centerStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return (
      <div className="App" style={centerStyle}>
        <h1>Operation icon</h1>
        {this.state.image !== null && (
          <img src={this.state.image} alt="Operation icon" />
        )}

        <Dropzone onDrop={files => AddOperationIcon.onDrop(files)}>
          <div>
            Drop the operation icon here, or click to search in your file system
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default AddOperationIcon;
