import React, {Component} from 'react';

import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from '../videos/VideoHelper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import PreviewYoutubeDescription from './PreviewYoutubeDescription';
import generateDescription from './GenerateDescription';
import {toastr} from 'react-redux-toastr';

class YoutubeDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      draft: {},
      generalData: {},
      customLinks: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    VideoHelper.setRoute(ServerRoutes.drafts);

    VideoHelper.getTemplate(code)
      .then(draft => {
        draft.youtubeDescription = VideoHelper.oldTranslationsToNew(
          draft.youtubeDescription,
        );
        this.setState({
          isLoading: false,
          draft,
        });
      })

      .catch(e => console.log('e', e));
  }

  update(key, value) {
    this.state.draft.youtubeDescription[key] = value;
    this.setState({});
  }

  static submit(draft) {
    VideoHelper.setTemplate(draft);
    VideoHelper.save();
  }

  static formatLabel(string) {
    return _.upperFirst(_.lowerCase(_.startCase(string)));
  }

  generateDescription(draft) {
    generateDescription(draft)
      .then(descriptions => {
        const newDraft = this.state.draft;
        newDraft.youtubeDescription.en = descriptions.descs.en;
        newDraft.youtubeDescription.fr = descriptions.descs.fr;
        newDraft.youtubeDescription.es = descriptions.descs.es;
        newDraft.youtubeDescription.it = descriptions.descs.it;
        newDraft.youtubeDescription.de = descriptions.descs.de;
        newDraft.youtubeDescription.pl = descriptions.descs.pl;

        this.setState({draft: newDraft});
        this.setState({customLinks: descriptions.customLinks});

        if (descriptions.numberOfNotFoundLinks) {
          toastr.error(
            'Error',
            descriptions.numberOfNotFoundLinks +
              ' Drive affiliation link(s) not found',
          );
        }

        YoutubeDescription.submit(this.state.draft);
      })
      .catch(error => {
        console.log(error);
        toastr.error('Error', 'Please retry later');
      });
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <h2 style={{margin: 5}}>Youtube descriptions</h2>

        {!this.state.isLoading && (
          <>
            <form
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}>
              {Object.keys(this.state.draft.youtubeDescription).map(lang => {
                return (
                  <div
                    key={lang}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <TextField
                      multiline
                      rows="12"
                      id="outlined-name"
                      label={YoutubeDescription.formatLabel(lang)}
                      value={this.state.draft.youtubeDescription[lang]}
                      onChange={e => this.update(lang, e.target.value)}
                      margin="normal"
                      variant="outlined"
                      style={{width: '100%'}}
                    />

                    {this.state.customLinks.map(l => (
                      <div key={l[lang]}>{l[lang]}</div>
                    ))}
                  </div>
                );
              })}
            </form>

            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => YoutubeDescription.submit(this.state.draft)}
                style={{width: '250px', margin: 10}}>
                Submit
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.generateDescription(this.state.draft)}
                style={{width: '250px', margin: 10}}>
                Erase and re-generate
              </Button>
            </div>

            <PreviewYoutubeDescription draft={this.state.draft} />
          </>
        )}
      </div>
    );
  }
}

export default YoutubeDescription;
