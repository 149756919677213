import moment from 'moment';

export default {
  computeYoutubeStatus: (lastModifiedDate, lastPublishedDate) => {
    if (lastPublishedDate === null) {
      return 'Not published';
    } else {
      const lastModifiedTimestamp = moment(lastModifiedDate).unix();
      const lastPublishedTimestamp = moment(lastPublishedDate).unix();

      if (lastPublishedTimestamp < lastModifiedTimestamp) {
        return 'Published but not up to date with draft';
      } else {
        return 'Published and up to date';
      }
    }
  },
  computeAppStatus: (lastModifiedDate, lastPublishedDate) => {
    if (lastPublishedDate === null) {
      return 'Not published';
    } else {
      const lastModifiedTimestamp = moment(lastModifiedDate).unix();
      const lastPublishedTimestamp = moment(lastPublishedDate).unix();

      if (lastPublishedTimestamp < lastModifiedTimestamp) {
        return 'Published but not up to date with draft';
      } else {
        return 'Published and up to date';
      }
    }
  },
};
