import React from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {toastr} from 'react-redux-toastr';
import _ from 'lodash';
import isUrlValid from '../urls/UrlValidation';

class GeneralData extends React.Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.initData();
  }

  initData() {
    HttpHelper.get(ServerRoutes.generalData).then(data => {
      this.setState({
        data,
      });
    });
  }

  submit(toUpdate) {
    HttpHelper.post(ServerRoutes.generalData, JSON.stringify(toUpdate)).then(
      response => {
        if (response.ok) {
          toastr.success('Success', 'Data have been updated');
          this.initData();
        } else {
          toastr.error('Error', 'An error occurred');
        }
      },
    );
  }

  handleSubObjectChange(event, key, subKey) {
    const updated = this.state.data;
    updated[key][subKey] = event.target.value;
    this.setState({data: updated});
  }

  handleSubSubObjectChange(event, key, subKey, subSubKey) {
    const updated = this.state.data;
    updated[key][subKey][subSubKey] = event.target.value;
    this.setState({data: updated});
  }

  static formatLabel(string) {
    return _.upperFirst(_.lowerCase(_.startCase(string)));
  }

  static isUrlFilledAndInvalid(url) {
    return url !== '' && !isUrlValid(url);
  }

  render() {
    return (
      <div>
        <h3 style={{textAlign: 'center'}}>
          The bitly link is the one used in the application. If you want the
          bitly link to be generated automatically, just let the bitly input
          empty.
        </h3>

        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: 50,
          }}>
          {Object.keys(this.state.data).map(key => {
            return (
              <div key={key}>
                {Object.keys(this.state.data[key]).map(subKey => {
                  if (typeof this.state.data[key][subKey] === 'object') {
                    return (
                      <div key={subKey}>
                        {Object.keys(this.state.data[key][subKey]).map(
                          subSubKey => {
                            return (
                              <TextField
                                key={subSubKey}
                                error={GeneralData.isUrlFilledAndInvalid(
                                  this.state.data[key][subKey][subSubKey],
                                )}
                                helperText={
                                  GeneralData.isUrlFilledAndInvalid(
                                    this.state.data[key][subKey][subSubKey],
                                  ) && 'Must be a valid url'
                                }
                                style={{marginRight: '10px'}}
                                id="outlined-name"
                                label={GeneralData.formatLabel(
                                  key + ' ' + subKey + ' ' + subSubKey,
                                )}
                                value={this.state.data[key][subKey][subSubKey]}
                                onChange={e =>
                                  this.handleSubSubObjectChange(
                                    e,
                                    key,
                                    subKey,
                                    subSubKey,
                                  )
                                }
                                margin="normal"
                                variant="outlined"
                              />
                            );
                          },
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <TextField
                        key={subKey}
                        error={GeneralData.isUrlFilledAndInvalid(
                          this.state.data[key][subKey],
                        )}
                        helperText={
                          GeneralData.isUrlFilledAndInvalid(
                            this.state.data[key][subKey],
                          ) && 'Must be a valid url'
                        }
                        style={{marginRight: '10px'}}
                        id="outlined-name"
                        label={GeneralData.formatLabel(key + ' ' + subKey)}
                        value={this.state.data[key][subKey]}
                        onChange={e =>
                          this.handleSubObjectChange(e, key, subKey)
                        }
                        margin="normal"
                        variant="outlined"
                      />
                    );
                  }
                })}
              </div>
            );
          })}

          <Button
            type="button"
            onClick={() => this.submit(this.state.data)}
            style={{}}>
            Update
          </Button>
        </form>
      </div>
    );
  }
}

export default GeneralData;
