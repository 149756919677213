let data = [];
let areAllUsersSelected = false;

const MotorizationDataService = {
  setAreAllUsersSelected: areAllUsersSelectedNewValue =>
    (areAllUsersSelected = areAllUsersSelectedNewValue),
  getAreAllUsersSelected: () => areAllUsersSelected,
  setData: dataToSet => (data = dataToSet),
  getData: () => {
    return data;
  },
};

export default MotorizationDataService;
