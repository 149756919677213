import React from 'react';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import YoutubeHelper from '../youtube/YoutubeHelper';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class OperationVideoLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtube: VideoHelper.newTranslation(),
      application: VideoHelper.newTranslation(),
      autocomplete: null,
      loading: {
        application: VideoHelper.newTranslation(),
        youtube: VideoHelper.newTranslation(),
      },
      completed: {
        application: VideoHelper.newTranslation(),
        youtube: VideoHelper.newTranslation(),
      },
      openChannel: false,
      openPlaylists: false,
      playlists: [],
    };
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);
    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      if (template.videoLinks) {
        const videoLinks = JSON.parse(template.videoLinks);
        this.setState({
          youtube: VideoHelper.oldTranslationsToNew(videoLinks.youtube),
          application: VideoHelper.oldTranslationsToNew(videoLinks.application),
        });
      }
    });

    this.getCurrentChannel();
  }

  getCurrentChannel() {
    let getCurrentChannelInterval = setInterval(() => {
      if (window.gapi && window.gapi.auth2) {
        window.gapi.load('client:auth2', () => {
          clearInterval(getCurrentChannelInterval);
          YoutubeHelper.getCurrentChannel().then(channelTitle => {
            this.setState({currentChannel: channelTitle});
          });
        });
      }
    }, 1000);
  }

  updateYoutube(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.youtube));
    updated[key] = value;
    this.setState({youtube: updated});
  }

  postVideoCallback;

  postNewVideo(video, lang, platforme) {
    this.setState({openChannel: true});
    this.postVideoCallback = playlistId => {
      let loading = JSON.parse(JSON.stringify(this.state.loading));
      loading[platforme][lang] = true;
      let completed = JSON.parse(JSON.stringify(this.state.completed));
      completed[platforme][lang] = 0;

      const onProgress = progression => {
        let completed = JSON.parse(JSON.stringify(this.state.completed));
        completed[platforme][lang] = progression;
        this.setState({completed});
      };

      this.setState({loading, completed});

      let title =
        platforme === 'application'
          ? 'APP.' + this.template.code + '.' + lang
          : JSON.parse(this.template.names).youtube[lang];

      const translatedTags = JSON.parse(this.template.tags).map(
        tag => JSON.parse(tag.names)[lang],
      );
      const metadata = {
        snippet: {
          title,
          description: this.template.youtubeDescription[lang],
          categoryId: 2,
          defaultLanguage: lang,
          tags: translatedTags,
        },
        status: {
          privacyStatus: 'private',
        },
      };
      YoutubeHelper.postVideo(video[0], metadata, playlistId, onProgress).then(
        youtubeResponse => {
          if (platforme === 'application') {
            this.updateApplication(
              lang,
              'https://youtu.be/' + youtubeResponse.id,
            );
          } else {
            this.updateYoutube(lang, 'https://youtu.be/' + youtubeResponse.id);
          }
          let loading = JSON.parse(JSON.stringify(this.state.loading));
          loading[platforme][lang] = false;
          const videoLinks = {
            youtube: this.state.youtube,
            application: this.state.application,
          };
          this.template.videoLinks = JSON.stringify(videoLinks);
          VideoHelper.setTemplate(this.template);
          VideoHelper.saveWithoutRedirect().then(() => {
            this.setState({loading});
          });
        },
      );
    };
  }

  listPlaylists() {
    YoutubeHelper.getPlaylists().then(playlists => {
      this.setState({openPlaylists: true, playlists});
    });
  }

  updateApplication(key, value) {
    let updated = JSON.parse(JSON.stringify(this.state.application));
    updated[key] = value;
    this.setState({application: updated});
  }

  submit() {
    const videoLinks = {
      youtube: this.state.youtube,
      application: this.state.application,
    };
    this.template.videoLinks = JSON.stringify(videoLinks);
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    const {classes} = this.props;

    return (
      <div>
        <div>
          {this.state.currentChannel ? (
            <div>
              <b>Current youtube chanel: {this.state.currentChannel}</b>
              <Button
                onClick={() =>
                  YoutubeHelper.connect(() => this.getCurrentChannel())
                }>
                Change channel
              </Button>
            </div>
          ) : (
            <Button
              onClick={() =>
                YoutubeHelper.connect(() => this.getCurrentChannel())
              }>
              Connect to youtube
            </Button>
          )}
        </div>
        <form
          className={classes.container}
          noValidate
          style={{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
          autoComplete="off">
          <h1>Youtube links</h1>
          <div style={{flexDirection: 'row'}}>
            {Object.keys(this.state.youtube).map(key => {
              return (
                <div
                  key={key}
                  style={{
                    float: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <TextField
                    style={{width: '280px'}}
                    id="outlined-name"
                    label={key}
                    value={this.state.youtube[key]}
                    className={classes.textField}
                    onChange={e => {
                      this.updateYoutube(key, e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              );
            })}
          </div>

          <h1>App links</h1>

          <div style={{flexDirection: 'row'}}>
            {Object.keys(this.state.application).map(key => {
              return (
                <div
                  key={key}
                  style={{
                    float: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <TextField
                    style={{width: '280px'}}
                    id="outlined-videoLink"
                    label={key}
                    value={this.state.application[key]}
                    className={classes.textField}
                    onChange={e => {
                      this.updateApplication(key, e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              margin: 50,
            }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: '250px'}}>
              Submit
            </Button>
          </div>
        </form>

        <Dialog
          open={this.state.openChannel}
          onClose={() => this.listPlaylists()}>
          {this.state.currentChannel ? (
            <div>
              <b>
                You will post your video on the channel:{' '}
                {this.state.currentChannel}
              </b>
              <Button
                onClick={() =>
                  YoutubeHelper.connect(() => {
                    this.getCurrentChannel();
                  })
                }>
                Change channel
              </Button>
              <Button
                onClick={() => {
                  this.setState({openChannel: false});
                  this.listPlaylists();
                }}>
                Continue
              </Button>
            </div>
          ) : (
            <Button
              onClick={() =>
                YoutubeHelper.connect(() => this.getCurrentChannel())
              }>
              Connect to youtube
            </Button>
          )}
        </Dialog>

        <Dialog open={this.state.openPlaylists}>
          <div>
            {this.state.playlists.map(playlist => {
              return (
                <ListItem
                  button
                  onClick={() => {
                    this.setState({openPlaylists: false});
                    this.postVideoCallback(playlist.id);
                  }}>
                  {playlist.title}
                </ListItem>
              );
            })}
          </div>
        </Dialog>
      </div>
    );
  }
}

OperationVideoLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationVideoLink);
