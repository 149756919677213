import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import Routes from '../routing/ServerRoutes';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import CarsSearch from './CarsSearch';
import uuidv1 from 'uuid';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

class Cars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      areCarsSet: false,
      carsGroupedByBrands: [],
      open: true,
    };
  }

  handleClick = brand => {
    if (this.state.open !== brand) {
      this.setState({open: brand});
    } else this.setState({open: false});
  };

  componentDidMount() {
    HttpHelper.get(Routes.cars).then(cars => {
      this.setState({
        cars,
        areCarsSet: true,
        carsGroupedByBrands: groupBy(cars, 'brand'),
      });
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        {this.state.areCarsSet ? (
          <div
            style={{
              marginBottom: 20,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            <div style={{width: '60%'}}>
              <CarsSearch cars={this.state.cars} />
            </div>
          </div>
        ) : null}

        <List component="nav">
          {Object.keys(this.state.carsGroupedByBrands).map(
            carGroupedByBrand => {
              return (
                <div key={uuidv1()}>
                  <ListItem
                    selected={true}
                    onClick={() => this.handleClick(carGroupedByBrand)}>
                    <ListItemText
                      inset
                      primary={capitalize(carGroupedByBrand)}
                    />

                    <Link
                      to={'/add-a-car/' + carGroupedByBrand}
                      style={{textDecoration: 'none'}}>
                      <Button>Add a {carGroupedByBrand} car</Button>
                    </Link>
                  </ListItem>

                  <Collapse
                    in={
                      this.state.open === carGroupedByBrand ||
                      this.state.open === true
                    }
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {this.state.carsGroupedByBrands[carGroupedByBrand].map(
                        car => {
                          return (
                            <Link
                              key={uuidv1()}
                              to={'/carDashboard/' + car.name}
                              style={{textDecoration: 'none', color: 'black'}}>
                              <ListItem button className={classes.nested}>
                                <ListItemText
                                  inset
                                  primary={capitalize(car.name)}
                                />
                              </ListItem>
                            </Link>
                          );
                        },
                      )}
                    </List>
                  </Collapse>
                </div>
              );
            },
          )}
        </List>
      </div>
    );
  }
}

let groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

Cars.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cars);
