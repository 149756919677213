import _ from 'lodash';

function concatenateCarParts(carParts, carPartsBitlyLink, lang) {
  return (
    carParts
      .map(carPart => {
        const name = () => {
          if (lang === 'fr') return carPart.frenchName;
          else if (lang === 'es') return carPart.spanishName;
          else if (lang === 'de') return carPart.germanName;
          else if (lang === 'it') return carPart.italianName;
          else if (lang === 'pl') return carPart.polishName || '';
          else return carPart.englishName;
        };

        const bitlyLink =
          carPartsBitlyLink.find(c => c.carPartCode === carPart.code).links[
            lang
          ] || '';

        return '- ' + _.upperFirst(name()) + ' : ' + bitlyLink;
      })
      .join('\n\n') + '\n'
  );
}

function concatenateTools(tools, lang) {
  return (
    tools
      .map(tool => {
        const links = () => {
          if (tool.links) {
            const parsedLinks = JSON.parse(tool.links)?.[lang];
            if (!parsedLinks) return '';
            else if (lang === 'fr') return ' : ' + parsedLinks;
            else return ': ' + parsedLinks;
          } else {
            return '';
          }
        };

        const name = () => {
          if (lang === 'fr') return tool.frenchName;
          else if (lang === 'es') return tool.spanishName;
          else if (lang === 'de') return tool.germanName;
          else if (lang === 'it') return tool.italianName;
          else if (lang === 'pl') return tool.polishName || '';
          else return tool.englishName;
        };

        return '- ' + _.upperFirst(name()) + links();
      })
      .join('\n') + '\n'
  );
}

function concatenateWarningsOrInfo(warnings, lang, isWarning = true) {
  const parsedWarnings = JSON.parse(warnings);

  if (parsedWarnings.youtube === undefined) {
    return '';
  } else {
    const splitWarnings = () => {
      if (lang === 'fr') return parsedWarnings.youtube.fr.split('\n\n');
      else if (lang === 'es') return parsedWarnings.youtube.es.split('\n\n');
      else if (lang === 'de') {
        if (parsedWarnings.youtube.de && parsedWarnings.youtube.de !== '0') {
          return parsedWarnings.youtube.de.split('\n\n');
        } else {
          return '';
        }
      } else if (lang === 'pl') {
        if (parsedWarnings.youtube.pl && parsedWarnings.youtube.pl !== '0') {
          return parsedWarnings.youtube.pl.split('\n\n');
        } else {
          return '';
        }
      } else if (lang === 'it') {
        if (parsedWarnings.youtube.it && parsedWarnings.youtube.it !== '0') {
          return parsedWarnings.youtube.it.split('\n\n');
        } else {
          return '';
        }
      } else return parsedWarnings.youtube.en.split('\n\n');
    };

    if (splitWarnings().length === 1 && splitWarnings()[0] !== '') {
      return splitWarnings()[0];
    } else if (splitWarnings().length < 2 && isWarning) {
      if (lang === 'fr') {
        return 'Pas de "Warningues" de la Clé nécessaires pour cette opération';
      } else if (lang === 'es') {
        return 'No necesitas advertencias de Usetool para esta operación';
      } else if (lang === 'it') {
        return 'Nessuna raccomandazione necessaria per questa operazione';
      } else if (lang === 'pl') {
        return 'Brak ostrzeżeń Usetoola niezbędnych do wykonania tej operacji';
      } else if (lang === 'de') return 'Keine Warnungen für diese Reparatur';
      else return "No Usetool's warnings necessary to carry out this operation";
    } else if (splitWarnings().length < 2 && !isWarning) {
      if (lang === 'fr') {
        return 'Pas d’infos de la Clé nécessaires pour cette opération';
      } else if (lang === 'es') {
        return 'No necesitas información para esta operación';
      } else if (lang === 'it') {
        return 'Nessuna info di UseTool necessaria per questa operazione';
      } else if (lang === 'de') {
        return 'Für diesen Vorgang werden keine Infos von UseTool benötigt';
      } else if (lang === 'pl') {
        return 'Żadnych konkretnych informacji niezbędnych do przeprowadzenia tej operacji';
      } else {
        return 'No particular piece of information necessary to carry out this operation';
      }
    } else {
      return '- ' + splitWarnings().join('\n\n- ');
    }
  }
}

function concatenateUsefulVideos(usefulVideos, lang) {
  if (usefulVideos.length === 0) {
    if (lang === 'fr') return 'Pas de vidéos utiles pour cette réparation';
    else if (lang === 'es') {
      return 'No hay vídeos que sirvan para esta operación';
    } else if (lang === 'it') return 'Nessun video utile per questa operazione';
    else if (lang === 'de') {
      return 'Keine nützlichen Videos für diese Reparatur';
    } else if (lang === 'pl') {
      return 'Brak odpowiednich filmów związanych z tą operacją';
    } else return 'No relevant videos related to this operation';
  } else {
    return usefulVideos
      .map(usefulVideo => {
        let names;
        let links;
        if (usefulVideo.code.startsWith('00')) {
          names = usefulVideo.names;
          links = usefulVideo.links;
        } else {
          names = JSON.parse(usefulVideo.names).youtube;
          links = JSON.parse(usefulVideo.videoLinks).youtube;
        }
        const name = () => {
          if (lang === 'fr') return names.fr;
          else if (lang === 'es') return names.es;
          else if (lang === 'de') return names.de;
          else if (lang === 'it') return names.it;
          else if (lang === 'pl') return names.pl;
          else return names.en;
        };

        const link = () => {
          if (lang === 'fr') return links.fr;
          else if (lang === 'es') return links.es;
          else if (lang === 'it') return links.it;
          else if (lang === 'de') return links.de;
          else if (lang === 'pl') return links.pl;
          else return links.en;
        };

        return '- ' + name() + ' : ' + link();
      })
      .join('\n\n');
  }
}

export default function concatenateDescription(
  draft,
  generalData,
  draftTools,
  draftCarParts,
  usefulVideos,
  car,
  catPartsBitlyLink,
  allCarParts,
) {
  const liftingTools = draftTools.filter(
    tool =>
      tool.code === 'T46' ||
      tool.code === 'T47' ||
      tool.code === 'T48' ||
      tool.code === 'T0.1',
  );

  const toolsWithoutLiftingTools = draftTools.filter(
    tool =>
      tool.code !== 'T46' &&
      tool.code !== 'T47' &&
      tool.code !== 'T48' &&
      tool.code !== 'T0.1',
  );

  const appText = {
    en:
      'BUY THE AUTO PARTS you need for the operation : 👉 http://bit.ly/cheapAutoparts 👈\n📱 DOWNLOAD THE APP : 🍎 http://bit.ly/MCD12AppStore, 🤖 : http://bit.ly/MCD12PlayStore 📱\n⬇︎⬇︎ 🔥   🔧🔧🔧   🔥⬇︎⬇︎',
    fr:
      "ACHETE LES PIECES nécessaires à l’opération : 👉👉👉 http://bit.ly/piecespascheres 👈👈👈\n 📱 TÉLÉCHARGE L'APP : 🍎 http://bit.ly/MaCléde12iOS, 🤖 : http://bit.ly/MCD12PlayStore 📱\n ⬇︎⬇︎ 🔥   🔧🔧🔧   🔥⬇︎⬇︎",
    es:
      'COMPRA LAS AUTOPARTES necesarias para la operación : 👉 http://bit.ly/autopartesbaratas 👈\n 📱 DESCARGA LA APP : 🍎 http://bit.ly/MCD12AppStore, 🤖 : http://bit.ly/MCD12PlayStore 📱\n ⬇︎⬇︎ 🔥   🔧🔧🔧   🔥⬇︎⬇︎',
    it:
      "COMPRA I COMPONENTI necessari per l'operazione. : 👉 http://bit.ly/RicambiaPrezziImbattibili 👈\n 📱 SCARICA L'APP. : 🍎 http://bit.ly/MCD12AppStore, 🤖 : http://bit.ly/MCD12PlayStore 📱\n ⬇︎⬇︎ 🔥   🔧🔧🔧   🔥⬇︎⬇︎",
    de:
      'KAUFE die notwendigen EINZELTEILE für den Vorgang: 👉 http://bit.ly/AutoteilePreiswert 👈\n 📱 LADE DIE APP HERUNTER: 🍎 http://bit.ly/MCD12AppStore, 🤖 : http://bit.ly/MCD12PlayStore 📱\n ⬇︎⬇︎ 🔥   🔧🔧🔧   🔥⬇︎⬇︎',
    pl:
      '📱 POBIERZ APLIKACJĘ : 🍎 http://bit.ly/MCD12AppStore, 🤖 : http://bit.ly/MCD12PlayStore 📱\n ⬇︎⬇︎ 🔥 🔧🔧🔧 🔥⬇︎⬇︎',
  };

  const findOilQuantityForOilChange = {
    en: '🛢 Find out what oil type and quantity is right for your engine : ',
    fr:
      "🛢 Retrouve la quantité d'huile exacte nécessaire à la réalisation de ta vidange : ",
    es:
      '🛢 Comprueba la cantidad de aceite exacta para la operación de vaciado : ',
    it:
      "🛢 Trova l'esatta quantità di olio necessaria per il tuo cambio dell'olio : ",
    de: '🛢 Finde heraus, wie viel Öl du für den Ölwechsel genau benötigst : ',
    pl: '',
  };

  const findOilQuantityForLevelCheck = {
    en: '🛢 Find out what oil type and quantity is right for your engine: ',
    fr:
      "🛢 Retrouve la quantité d'huile exacte nécessaire à la réalisation de tes niveaux : ",
    es: '🛢 Comprueba la cantidad de aceite exacta : ',
    it: "🛢 Trova l'esatta quantità di olio necessaria per la tua auto : ",
    de: '🛢 Finde heraus, wie viel Öl du für deines wagen genau benötigst : ',
    pl: '',
  };

  const generateOilLinks = lang => {
    const operationCode = draft.code.split('.')[2];
    if (operationCode === '07') {
      return (
        findOilQuantityForOilChange[lang] +
        generalData.oilSelector[lang] +
        '\n\n'
      );
    } else if (operationCode === '10') {
      return (
        findOilQuantityForLevelCheck[lang] +
        generalData.oilSelector[lang] +
        '\n\n'
      );
    } else {
      return '';
    }
  };

  const discoverFirstText = {
    en:
      'Discover a vast array of services that will help you run the maintenance of your car: ' +
      'vehicle user manuals, maintenance planning and mechanics-oriented tutorials!\n' +
      `${JSON.parse(draft.names).application.en} on a ${
        car.brand
      } ${car.englishName || car.name} ${draft.motorization || ''}.`,
    fr:
      "Retrouve une multitude de services qui t'aideront dans la gestion de ta voiture, " +
      "carnet d'entretien, agenda prévisionnel et les tutoriels spécialement adaptés à la pratique " +
      'de la mécanique !\n' +
      `${JSON.parse(draft.names).application.fr} sur ${car.brand} ${
        car.name
      } ${draft.motorization || ''}.`,
    es:
      'Descubre una gran variedad de servicios que te ayudarán en la gestión de tu coche: ¡el ' +
      'manual de mantenimiento, la agenda de proyectos y los tutoriales especialmente adaptados ' +
      'para ejercer la mecánica!\n' +
      `${JSON.parse(draft.names).application.es} en un ${car.brand} ${
        car.name
      } ${draft.motorization || ''}.`,
    it:
      "Scopri una vasta gamma di servizi che ti aiuteranno con la tua auto, il manuale delle istruzioni, l'agenda delle manutenzioni, e i nostri tutorial per le operazioni di meccanica!\n" +
      `${JSON.parse(draft.names).application.it} per ${car.brand} ${
        car.name
      } ${draft.motorization || ''}.`,
    de:
      'Entdecke die zahlreichen Dienste, die dir bei der Wartung deines Wagens nützlich sein werden: Serviceheft, Wartungsplanung und mechanikorientierte Tutorials!\n' +
      `${JSON.parse(draft.names).application.de} ${car.brand} ${
        car.name
      } ${draft.motorization || ''}.`,
    pl:
      'Odkryj szeroką gamę usług, które pomogą Ci w prowadzeniu konserwacji Twojego samochodu: instrukcje obsługi pojazdów, planowanie konserwacji i poradniki dla mechaników!\n' +
      `${JSON.parse(draft.names).application.pl} na ${car.brand} ${
        car.name
      } ${draft.motorization || ''}.`,
  };

  const discoverSecondText = {
    en: 'Discover the world of UseTool EN on ',
    fr: "Découvre l'univers de Ma Clé de 12 sur ",
    es: 'Descubre el universo de UseTool ES en ',
    it: 'Scopri il mondo di UseTool su ',
    de: 'Entdecke die Welt von UseTool auf ',
    pl: 'Odkryj świat UseTool PL na ',
  };

  const retrieveTools = {
    en:
      '🛠📏⚙ FIND ALL THE PARTS AND TOOLS NEEDED AND RECOMMENDED BY USETOOL EN ⚙📏🛠',
    fr:
      '🛠📏⚙ RETROUVE LES PIECES ET OUTILS UTILISES ET CONSEILLES PAR MA CLE DE 12 ⚙📏🛠',
    es:
      '🛠📏⚙ DESCUBRE TODAS LAS AUTOPARTES Y HERRAMIENTAS NECESARIAS RECOMENDADAS POR USETOOL ⚙📏🛠',
    it: '🛠📏⚙ TUTTI I COMPONENTI E GLI ATTREZZI CONSIGLIATI DA USETOOL  ⚙📏🛠',
    de:
      '🛠📏⚙ HIER FINDEST DU DIE EINZELTEILE UND WERKZEUGE, DIE VON USETOOL VERWENDET UND EMPFOHLEN WERDEN ⚙📏🛠',
    pl:
      '🛠📏⚙ ZNAJDŹ WSZYSTKIE CZĘŚCI I NARZĘDZIA POTRZEBNE I ZALECANE PRZEZ USETOOL PL ⚙📏🛠',
  };

  const carParts = {
    en: 'Needed parts: ',
    fr: 'Pièces nécessaires : ',
    es: 'Autopartes necesarias : ',
    it: 'Componenti necessari: ',
    de: 'Benötigte Einzelteile: ',
    pl: 'Potrzebne części:',
  };

  const necessaryTools = {
    en:
      'Needed tools:\n' +
      '-💣🤖🏆The complete toolbox recommended by UseTool EN to repair your ' +
      `${car.englishName || car.name} : ${
        generalData.toolbox.en.bitlyLink
      } 🏆🤖💣`,
    fr:
      'Outils nécessaires :\n' +
      '-💣🤖🏆La caisse à outils complète recommandée par Ma Clé de 12 pour réparer ' +
      `ta ${car.name} : ${generalData.toolbox.fr.bitlyLink} 🏆🤖💣`,
    es:
      'Herramientas necesarias :\n' +
      '-💣🤖🏆La caja de herramientas completa recomendada por UseTool ES para reparar tu ' +
      `${car.name} : ${generalData.toolbox.es.bitlyLink} 🏆🤖💣`,
    it:
      'Attrezzi necessari:\n' +
      '-💣🤖🏆La cassetta degli attrezzi completa consigliata da UseTool per riparare la tua ' +
      `${car.name} : ${generalData.toolbox.it.bitlyLink} 🏆🤖💣`,
    de:
      'Benötigtes Werkzeug :\n' +
      '-💣🤖🏆Die komplette von UseTool empfohlene Werkzeugkiste zur Reparatur deines: ' +
      `${car.name} : ${generalData.toolbox.de.bitlyLink} 🏆🤖💣`,
    pl:
      'Potrzebne narzędzia:\n' +
      `${car.name} : ${generalData.toolbox.de.bitlyLink} 🏆🤖💣`,
  };

  const liftingToolsDescription = {
    en:
      `🔝💪Safely lift your ${car.englishName ||
        car.name} with the hydraulic jack: ` +
      `${generalData.hydraulicJack.en.bitlyLink} and axle stands: ` +
      `${generalData.axleStands.en.bitlyLink} recommended by UseTool EN 💪🔝`,
    fr:
      'Outils de levage nécessaires :\n' +
      `🔝💪Soulève ta ${car.name} en toute sécurité avec le cric et les chandelles recommandés par ` +
      `Ma Clé de 12 : cric : ${generalData.hydraulicJack.fr.bitlyLink} chandelles ` +
      `: ${generalData.axleStands.fr.bitlyLink} 💪🔝`,
    es:
      `🔝💪Levanta tu ${car.name} con seguridad con el gato :  ` +
      `${generalData.hydraulicJack.es.bitlyLink} y las borriquetas :   ` +
      `${generalData.axleStands.es.bitlyLink} recomendadas por UseTool ES 💪🔝`,
    it:
      `🔝💪Solleva la tua ${car.name} in tutta sicurezza con il cric : ` +
      `${generalData.hydraulicJack.it.bitlyLink} e i cavalletti : ` +
      `${generalData.axleStands.it.bitlyLink} consigliati da UseTool : 💪🔝`,
    de:
      `🔝💪 UseTool empfiehlt dir den Wagenheber: ${generalData.hydraulicJack.de.bitlyLink} und die ` +
      `Unterstellböcke: ${generalData.axleStands.de.bitlyLink}, um deinen ${car.name} sicher anzuheben 💪🔝`,
    pl:
      `🔝💪Bezpiecznie podnieś swój ${generalData.hydraulicJack.pl.bitlyLink} za pomocą podnośnika hydraulicznego: ` +
      `${generalData.axleStands.pl.bitlyLink},  ${car.name} 💪🔝`,
  };

  const resources = {
    en: '🚗📢💡 RESOURCES 💡📢🚗',
    fr: '🚗📢💡 RESSOURCES 💡📢🚗',
    es: '🚗📢💡 RECURSOS 💡📢🚗',
    it: '🚗📢💡 RISORSE 💡📢🚗',
    de: '🚗📢💡 RESSOURCEN 💡📢🚗',
    pl: '🚗📢💡 ZASOBY 💡📢🚗',
  };

  const usefulVideosDescription = {
    en: '📽❤️🚨 USEFUL VIDEOS 🚨❤️📽',
    fr: '📽❤️🚨 VIDEOS UTILES 🚨❤️📽',
    es: '📽❤️🚨 VIDEO ÚTIL 🚨❤️📽',
    it: '📽❤️🚨 VIDEO UTILI 🚨❤️📽',
    de: '📽❤️🚨 NÜTZLICHE VIDEOS 🚨❤️📽',
    pl: '📽❤️🚨 PRZYDATNE FILMY 🚨❤️📽',
  };

  const warnings = {
    en: "Usetool's warnings",
    fr: 'Les "Warningues" de la Clé : ',
    es: 'Las advertencias de Usetool',
    it: 'Le raccomandazioni di UseTool',
    de: 'Warnungen von UseTool',
    pl: '🚗📢💡 Ostrzeżenia UseTool 💡📢🚗',
  };

  const info = {
    en: "Usetool's info",
    fr: 'Les Infos de la Clé : ',
    es: 'Las informaciones de Usetool',
    it: 'Le info di UseTool',
    de: 'Die Infos von UseTool',
    pl: '🚗📢💡 Informacje o UseTool 💡📢🚗',
  };

  const musicLinksAndDisclaimer = {
    en:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr\n' +
      'Music promoted by https://www.free-stock-music.com\n' +
      '\n' +
      'Terms and conditions of the offer : https://www.mister-auto.co.uk/operations/1685/application.html\n' +
      '\n' +
      'Disclaimer: This video was created for information purposes and does not guarantee the outcome of actions undertaken.\n' +
      'To ensure that you see the best results, we recommend getting in contact with qualified technicians who will carry out the installation.\n' +
      'This video provides tips and recommendations that can be useful when repairing or changing parts. MCD12 and its partners cannot be held responsible for any physical and material damage that comes from the poor installation or incorrect interpretation of the video content.\n' +
      'This video was created for information purposes and does not guarantee any outcomes. The user is solely responsible for the incorrect use of repair machines, tools and parts, in particular if these may cause physical damage. MCD12 and its partners recommend taking great care and carefully following the safety rules when carrying out the repair or replacement of any parts. Please keep in mind that using poor quality parts compromises the safety of the driver when on the road.' +
      '\n',
    fr:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr\n' +
      'Music promoted by https://www.free-stock-music.com\n' +
      '\n' +
      "Conditions de l'offre Mister-Auto : https://www.mister-auto.com/application/\n" +
      '\n' +
      'Clause de non-responsabilité :\n' +
      'Cette vidéo a été réalisée à but informatif et ne constitue aucune garantie de résultat.\n' +
      "Pour vous assurer d'obtenir le meilleur résultat, nous vous recommandons de prendre contact avec des techniciens qualifiés pour qu'ils se chargent de l'installation.\n" +
      "Cette vidéo présente des astuces et des recommandations pouvant être utiles lors d'une réparation ou d'un changement de pièces. MCD12 et ses partenaires ne peuvent être tenus pour responsables quant aux dommages physiques et matériels résultant d'une mauvaise utilisation ou d'une interprétation erronée de ce contenu vidéo.\n" +
      "Cette vidéo est produite à but informatif et ne garantit en aucun cas le moindre résultat escompté. L'utilisateur est tenu pour seul responsable de toute mauvaise utilisation des appareils de réparation, des outils et des pièces détachées, en particulier si celle-ci l'expose à des dommages physiques. MCD12 et ses partenaires recommandent la plus grande prudence et le respect strict des règles de sécurité lors de la moindre opération de réparation ou de remplacement. Veuillez garder à l'esprit que l'utilisation de pièces de mauvaise qualité compromet la sécurité du conducteur pendant la conduite." +
      '\n',
    es:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr\n' +
      'Music promoted by https://www.free-stock-music.com\n' +
      '\n' +
      'Condiciones generales de la oferta : https://www.mister-auto.es/operaciones/1679/application.html\n' +
      '\n' +
      'Cláusula de exención de responsabilidad: este vídeo ha sido realizado a título informativo y no constituye ninguna garantía de resultado. \n' +
      'Para obtener mejores resultados, le recomendamos contactar con técnicos cualificados para la instalación. \n' +
      'Este vídeo incluye trucos y recomendaciones que pueden ser utilizados durante una reparación o un cambio de piezas. MCD12 y sus socios no son responsables de los posibles daños físicos y materiales que puedan ser consecuencia de un mal uso o de una interpretación errónea del contenido del vídeo. \n' +
      'Este vídeo ha sido realizado a título informativo y no garantiza ningún resultado. El usuario es el único responsable de cualquier mal uso de los aparatos de reparación, herramientas y piezas, en particular si conlleva daños físicos. MCD12 y sus socios recomiendan extremar la prudencia y un estricto seguimiento de las reglas de seguridad durante la mas mínima operación de reparación o recambio. Recuerde que la utilización de piezas de mala calidad compromete la seguridad del conductor.' +
      '\n',
    it:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr\n' +
      'Music promoted by https://www.free-stock-music.com\n' +
      '\n' +
      'Condizioni generali dell’offerta : https://www.mister-auto.it/operazioni/1682/application.html\n' +
      '\n' +
      'Dichiarazione di non responsabilità: questo video è stato realizzato a scopo informativo e non costituisce alcuna garanzia di risultato.\n' +
      "Per ottenere il migliore risultato possibile, vi consigliamo di contattare dei tecnici qualificati perché si occupino dell'installazione.\n" +
      "Questo video presenta dei trucchi e dei consigli che possono essere utili per riparare l'auto o cambiare dei componenti. MCD12 e i suoi associati non possono essere considerati responsabili per danni fisici o materiali causati da un utilizzo errato o di un'interpretazione errata del contenuto del video.\n" +
      "Questo video è stato prodotto a scopo informativo e non garantisce in alcun caso alcun risultato. L'utente viene ritenuto l'unico responsabile per qualunque utilizzo errato delle macchine da lavoro, degli attrezzi e dei singoli componenti, in particolare se tale uso risulta in dei danni fisici. MCD12 e i suoi soci consigliano la massima prudenza e il totale rispetto delle regole di sicurezza per qualsiasi operazione, anche la più semplice, di riparazione o sostituzione. Tenete presente che usare attrezzi di bassa qualità compromette la sicurezza del conducente durante la guida." +
      '\n',
    de:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr\n' +
      'Music promoted by https://www.free-stock-music.com\n' +
      '\n' +
      'Allgemeinen Hinweisen zum Angebot : https://www.mister-auto.de/betrieb/1664/application.html\n' +
      '\n' +
      'Haftungsausschluss:\n' +
      'Dieses Video wurde zu Informationszwecken erstellt und übernimmt keine Garantie für die Ergebnisse.\n' +
      'Um sicherzustellen, dass Sie das beste Ergebnis erzielen, empfehlen wir Ihnen, sich für die Installation an qualifizierte Techniker zu wenden.\n' +
      'Dieses Video gibt Tipps und Empfehlungen, die bei einer Reparatur oder einem Austausch von Teilen nützlich sein können. MCD12 und seine Partner können nicht für physische oder materielle Schäden verantwortlich gemacht werden, die durch eine falsche Verwendung oder Fehlinterpretation dieses Video-Contents entstehen.\n' +
      'Dieses Video wurde zu Informationszwecken produziert und garantiert in keinem Fall für irgendein erwartetes Ergebnis. Der Nutzer trägt die alleinige Verantwortung für jede falsche Verwendung von Reparaturgeräten, Werkzeugen und Ersatzteilen, insbesondere wenn er dabei physischen Schäden ausgesetzt ist. MCD12 und seine Partner empfehlen äußerste Vorsicht und die strikte Einhaltung der Sicherheitsvorschriften bei der Durchführung von Reparatur- oder Austauscharbeiten. Bitte beachten Sie, dass die Verwendung qualitativ minderwertiger Teile die Sicherheit des Fahrers am Steuer beeinträchtigt.' +
      '\n',
    pl:
      'Space Race by tubebackr | https://soundcloud.com/tubebackr' +
      'Muzyka promowana przez https://www.free-stock-music.com' +
      '\n' +
      'Disclaimer: Ten film został stworzony w celach informacyjnych i nie gwarantuje wyników podjętych działań.\n' +
      'Aby zapewnić sobie najlepsze rezultaty, zalecamy skontaktowanie się z wykwalifikowanymi technikami, którzy przeprowadzą instalację.\n' +
      'Ten film zawiera wskazówki i zalecenia, które mogą być przydatne podczas naprawy lub wymiany części. Firma MCD12 i jej partnerzy nie ponoszą odpowiedzialności za jakiekolwiek szkody fizyczne i materialne, które wynikają ze złej instalacji lub nieprawidłowej interpretacji treści filmu.\n' +
      'Ten film został stworzony w celach informacyjnych.' +
      'Ten film został stworzony w celach informacyjnych i nie gwarantuje żadnych rezultatów. Użytkownik ponosi wyłączną odpowiedzialność za nieprawidłowe użycie maszyn, narzędzi i części naprawczych, w szczególności jeśli mogą one spowodować szkody fizyczne. Firma MCD12 i jej partnerzy zalecają zachowanie szczególnej ostrożności i dokładne przestrzeganie zasad bezpieczeństwa podczas wykonywania napraw lub wymiany jakichkolwiek części. Należy pamiętać, że stosowanie części o niskiej jakości zagraża bezpieczeństwu kierowcy podczas jazdy.' +
      '\n',
  };

  const generateChapters = lang => {
    if (!draft.chapters) return '';

    return JSON.parse(draft.chapters)
      .map(chapter => {
        return chapter.timestamp + ' ' + chapter.titles[lang];
      })
      .join('\n');
  };

  const generateTighteningValues = lang => {
    const tighteningValuesTitle = {
      en: '💪 TIGHTENING TORQUES FOR ',
      fr: '💪 COUPLES DE SERRAGE POUR ',
      es: '💪 PARES DE APRIETE PARA ',
      de: '💪 ANZUGSDREHMOMENTE FÜR ',
      it: '💪 COPIE DI SERRAGIO PER ',
      pl: '💪 MOMENTY DOKRĘCANIA ',
    };

    const noTightening = {
      en: 'No tightening torques for this operation',
      fr: 'Pas de couples de serrage pour cette opération',
      es: 'No hay pares de apriete para esta operación',
      de: 'Keine Anzugsdrehmomente für diesen Vorgang',
      it: 'Nessuna coppia di serraggio per questa operazione',
      pl: '',
    };

    const language =
      lang === 'pl'
        ? 'polishName'
        : lang === 'fr'
        ? 'frenchName'
        : lang === 'es'
        ? 'spanishName'
        : lang === 'de'
        ? 'germanName'
        : lang === 'it'
        ? 'italianName'
        : 'englishName';

    const title =
      lang === 'en'
        ? tighteningValuesTitle[lang] +
          `${(
            JSON.parse(draft.names).application[lang] || ''
          ).toUpperCase()} ${car.brand.toUpperCase()} ${car.englishName ||
            car.name} ${draft.motorization || ''}`
        : tighteningValuesTitle[lang] +
          `${(
            JSON.parse(draft.names).application[lang] || ''
          ).toUpperCase()} ${car.brand.toUpperCase()} ${
            car.name
          } ${draft.motorization || ''}`;

    const values =
      draft.tighteningValues && JSON.parse(draft.tighteningValues)?.length
        ? JSON.parse(draft.tighteningValues)
            .map(tighteningValue => {
              return (
                ' - ' +
                _.upperFirst(
                  allCarParts.find(t => t.code === tighteningValue.carPart)?.[
                    language
                  ],
                ) +
                ' : ' +
                tighteningValue.value +
                ' Nm'
              );
            })
            .join('\n')
        : noTightening[lang];

    return title + '\n' + values;
  };

  const translateDescription = lang => {
    return (
      appText[lang] +
      '\n\n' +
      generateOilLinks(lang) +
      retrieveTools[lang] +
      '\n\n' +
      carParts[lang] +
      '\n' +
      concatenateCarParts(draftCarParts, catPartsBitlyLink, lang) +
      '\n' +
      generateTighteningValues(lang) +
      '\n\n' +
      discoverFirstText[lang] +
      '\n\n' +
      discoverSecondText[lang] +
      generalData.website[lang].bitlyLink +
      '\n\n' +
      necessaryTools[lang] +
      '\n\n' +
      concatenateTools(toolsWithoutLiftingTools, lang) +
      '\n' +
      liftingToolsDescription[lang] +
      '\n\n' +
      concatenateTools(liftingTools, lang) +
      '\n' +
      resources[lang] +
      '\n\n' +
      warnings[lang] +
      '\n' +
      concatenateWarningsOrInfo(draft.warning, lang) +
      '\n\n' +
      info[lang] +
      '\n' +
      concatenateWarningsOrInfo(draft.info, lang, false) +
      '\n\n' +
      usefulVideosDescription[lang] +
      '\n' +
      concatenateUsefulVideos(usefulVideos, lang) +
      '\n\n' +
      musicLinksAndDisclaimer[lang] +
      '\n' +
      generateChapters(lang)
    );
  };

  return {
    en: translateDescription('en'),
    fr: translateDescription('fr'),
    es: translateDescription('es'),
    it: translateDescription('it'),
    de: translateDescription('de'),
    pl: translateDescription('pl'),
  };
}
