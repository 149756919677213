import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import client from '../util/fetch';
import {cloudFunctionsURL} from '../util/constants';
import {toastr} from 'react-redux-toastr';
import getBitlyLinksFromCarPartsCode from '../bitly/getBitlyLinksFromCarPartsCode';
import removeEmojisFromString from '../util/removeEmojisFromString';

const extractBackHalf = link => {
  try {
    return link.split('/')[link.split('/').length - 1];
  } catch (e) {
    return '';
  }
};

const extractBackHalfs = links => {
  return {
    en: extractBackHalf(links?.youtube?.en),
    fr: extractBackHalf(links?.youtube?.fr),
    es: extractBackHalf(links?.youtube?.es),
    it: extractBackHalf(links?.youtube?.it),
    de: extractBackHalf(links?.youtube?.de),
  };
};

const removeEmojisFromTitles = titles => {
  return {
    en: removeEmojisFromString(titles.youtube.en),
    fr: removeEmojisFromString(titles.youtube.fr),
    es: removeEmojisFromString(titles.youtube.es),
    it: removeEmojisFromString(titles.youtube.ie),
    de: removeEmojisFromString(titles.youtube.de),
  };
};

// 0.34 = 20€ de l'heure par minute et on arrondit à 5 près
const computeSavings = durationInMinutes => {
  return durationInMinutes < 60
    ? 20
    : Math.ceil((durationInMinutes * 0.34) / 5) * 5;
};

const prepareMASheet = async operationCode => {
  if (!operationCode || !operationCode.length >= 2) return;

  const draft = await HttpHelper.get(ServerRoutes.drafts + '/' + operationCode);

  const {
    bitLinks: carPartsBitlyLink,
    customLinks,
  } = await getBitlyLinksFromCarPartsCode(
    operationCode.split('.')[0],
    JSON.parse(draft?.parts),
    JSON.parse(draft.names).youtube.fr,
  );

  const formattedCarParts = carPartsBitlyLink.map(carPartBitlyLink => ({
    frenchName: carPartBitlyLink.carPartFrenchName,
    frenchLink: carPartBitlyLink.links.fr,
    englishName: carPartBitlyLink.carPartEnglishName,
    englishLink: carPartBitlyLink.links.en,
    spanishName: carPartBitlyLink.carPartSpanishName,
    spanishLink: carPartBitlyLink.links.es,
    germanName: carPartBitlyLink.carPartGermanName,
    germanLink: carPartBitlyLink.links.de,
    italianName: carPartBitlyLink.carPartItalianName,
    italianLink: carPartBitlyLink.links.it,
  }));

  const cars = await HttpHelper.get(ServerRoutes.cars);
  const car = cars.find(c => c.code === operationCode.split('.')[0]);

  const allTools = await HttpHelper.get(ServerRoutes.tools);

  const tools = JSON.parse(draft?.tools).map(toolCode =>
    allTools.find(t => t.code === toolCode),
  );

  const sheet = {
    title: operationCode,
    carBrand: car.brand,
    carName: car.name,
    videoLinks: extractBackHalfs(JSON.parse(draft.videoLinks)),
    titles: removeEmojisFromTitles(JSON.parse(draft.names)),
    difficulty: draft.difficulty.toString() || '0',
    durationInMinutes: draft.time.toString() || '0',
    savingInEuro: computeSavings(draft.time),
    tools,
    carParts: formattedCarParts,
  };

  if (sheet.carParts.length > 8) {
    toastr.error('Error', 'Too much car parts');
    return;
  }
  if (sheet.tools.length > 13) {
    toastr.error('Error', 'Too much tools');
    return;
  }

  await client(cloudFunctionsURL() + 'generateMisterAutoSheet', {
    body: sheet,
  });
  return customLinks;
};

export default prepareMASheet;
