import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import Button from '@material-ui/core/Button';

export const FindToolsInText = ({allTools, addToSelectedTools}) => {
  const [searchText, setSearchText] = useState('');
  const [foundTools, setFoundTools] = useState([]);

  const onSubmit = () => {
    let tempFoundTools = [];
    for (const tool of allTools) {
      if (searchText.toLowerCase().indexOf(tool.frenchName.toLowerCase()) > 0) {
        if (
          searchText.toLowerCase().indexOf(tool.englishName.toLowerCase()) > 0
        ) {
          tempFoundTools.push(tool);
        }
      }
    }
    setFoundTools(tempFoundTools);
    addToSelectedTools(
      tempFoundTools.map(tool => ({
        label: tool.code + ' - ' + tool.frenchName,
        value: tool.code,
      })),
    );
  };

  return (
    <div>
      {foundTools?.length ? 'Found tools in text :' : ''}
      {foundTools.map(t => (
        <div key={t.code}>{t.frenchName}</div>
      ))}
      <TextField
        multiline
        rows="12"
        label={'Paste french and english texts here...'}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        margin="normal"
        variant="outlined"
        style={{width: '100%'}}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 20,
        }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmit}
          style={{width: '250px'}}>
          Search tools in text
        </Button>
      </div>
    </div>
  );
};
