import React from 'react';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class DifficultyAndTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      difficulty: 0,
      time: 0,
    };
    this.changeDifficulty = this.changeDifficulty.bind(this);
  }

  changeDifficulty(e) {
    let value;
    if (e.target.value > 5) value = 5;
    else if (e.target.value <= 0) value = 0;
    else value = e.target.value;
    this.template.difficulty = value;
    this.setState({difficulty: value});
  }

  changeTime(e) {
    this.template.time = e.target.value;
    this.setState({time: e.target.value});
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');
    const type = this.props.match.params.type;
    if (type === 'template') {
      VideoHelper.setRoute(ServerRoutes.templates);
    } else VideoHelper.setRoute(ServerRoutes.drafts);
    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      if (template.difficulty && template.difficulty !== null) {
        this.setState({difficulty: template.difficulty});
      }
      if (template.time && template.time !== null) {
        this.setState({time: template.time});
      }
    });
  }

  submit() {
    VideoHelper.setTemplate(this.template);
    VideoHelper.save();
  }

  render() {
    const {classes} = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
        style={{
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
        autoComplete="off">
        <div style={{flexDirection: 'row'}}>
          <TextField
            id="outlined-name"
            label={'Difficulty'}
            type="number"
            value={this.state.difficulty}
            className={classes.textField}
            onChange={e => this.changeDifficulty(e)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-name"
            label={'Duration in minutes'}
            type="number"
            value={this.state.time}
            className={classes.textField}
            onChange={e => this.changeTime(e)}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 50,
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{width: '250px'}}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

DifficultyAndTime.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DifficultyAndTime);
