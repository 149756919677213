// const isDev = true;
const isDev = false;

export const cloudFunctionsURL = () => {
  if (isDev) {
    return 'http://localhost:5001/mc12-352220/us-central1/';
  } else {
    console.log('Prod cloud functions called');
    return 'https://us-central1-mc12-352220.cloudfunctions.net/';
  }
};
