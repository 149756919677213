import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import history from '../routing/history';

class Advertising extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      link: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const token = window.localStorage.getItem('token');

    fetch('/api/advertising', {
      method: 'POST',
      body: JSON.stringify(this.state.image),
      headers: {
        'X-Auth-Token': token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          history.push('/everything-worked-well');
        } else {
          //THINK TO HANDLE ERROR
        }
      })
      .catch(error => console.log(error));
  }

  render() {
    let centerStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return (
      <div className="App">
        <h1 style={centerStyle}>Publicité de l'application</h1>

        <form onSubmit={this.onSubmit}>
          <label>
            Lien de l'image :
            <input
              name="image"
              type="text"
              value={this.state.image}
              onChange={e => this.setState({image: e.target.value})}
            />
          </label>
          {/*<br />*/}
          {/*<label>*/}
          {/*Url sur laquelle l'utilisateur est renvoyé : */}
          {/*<input*/}
          {/*name="link"*/}
          {/*type="text"*/}
          {/*value={this.state.link}*/}
          {/*onChange={e => this.setState({ link: e.target.value })}/>*/}
          {/*</label>*/}

          <input type="submit" value="Submit" />
        </form>

        <Link to={'/home'}>Retour à l'accueil</Link>
      </div>
    );
  }
}

export default Advertising;
