import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import Button from '@material-ui/core/Button';
import AppBarMenu from './AppBarMenu';
import signOut from '../auth/signout';

export default class MyAppBar extends React.Component {
  static logout(e) {
    signOut(e);
  }

  render() {
    return (
      <AppBar position="static" style={{marginBottom: 15}}>
        <Toolbar>
          <AppBarMenu aria-label="Menu" />

          <Typography
            variant="subtitle1"
            color="inherit"
            style={{flexGrow: 1, fontWeight: 'bold'}}>
            Ma clé de 12
          </Typography>

          <Button color="inherit" onClick={MyAppBar.logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}
