import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ServerRoutes from '../routing/ServerRoutes';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HttpHelper from '../routing/HttpHelper';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class DuplicateTemplate extends React.Component {
  state = {
    newCode: '',
    open: false,
  };

  submit() {
    HttpHelper.post(
      ServerRoutes.duplicateTemplate(this.props.code, this.state.newCode),
    ).then(() => {
      this.setState({open: false});
      this.props.submit();
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.setState({open: true})}
          style={{width: '250px'}}>
          duplicate
        </Button>
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({open: false})}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'Duplicate'}</DialogTitle>
          <DialogContent>
            <form
              className={classes.container}
              noValidate
              style={{
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
              }}
              onSubmit={e => {
                this.submit();
                e.preventDefault();
              }}
              autoComplete="off">
              <TextField
                id="outlined-name"
                label="Operation Code"
                className={classes.textField}
                onChange={e => {
                  this.state.newCode = e.target.value;
                }}
                margin="normal"
                variant="outlined"
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 50,
                }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{width: '250px'}}>
                  Submit
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DuplicateTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DuplicateTemplate);
