import React, {Component} from 'react';
import HttpHelper from '../routing/HttpHelper';
import ServerRoutes from '../routing/ServerRoutes';
import Button from '@material-ui/core/Button/Button';
import {toastr} from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

class PeriodicityDashboard extends Component {
  state = {
    motorization: this.props.motorization,
    periodicities: [],
    operationCodeOfPeriodicityBeingUpdated: null,
    updatedMonthPeriodicity: null,
    updatedMileagePeriodicity: null,
  };

  componentDidMount() {
    HttpHelper.get(
      ServerRoutes.findMotorizationPeriodicities(
        this.state.motorization.periodicityCode,
      ),
    ).then(periodicities => {
      this.setState({
        periodicities,
      });
    });
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  startUpdatePeriodicity(operationCode, actualMonth, actualMileage) {
    this.setState({
      operationCodeOfPeriodicityBeingUpdated: operationCode,
      updatedMonthPeriodicity: actualMonth,
      updatedMileagePeriodicity: actualMileage,
    });
  }

  deletePeriodicity(periodicityCode, operationCode) {
    HttpHelper.delete(
      ServerRoutes.deletePeriodicities(periodicityCode, operationCode),
    ).then(response => {
      if (response.ok) {
        toastr.success('Success', 'Periodicity has been deleted');
        setInterval(() => window.location.reload(), 1000);
      } else {
        toastr.error('An error occurred', 'Periodicity has not been deleted');
      }
    });
  }

  updatePeriodicity() {
    const updatedPeriodicity = {
      operationCode: this.state.operationCodeOfPeriodicityBeingUpdated,
      month: this.state.updatedMonthPeriodicity,
      mileageInKm: this.state.updatedMileagePeriodicity,
      periodicityCode: this.state.motorization.periodicityCode,
    };

    HttpHelper.update(
      ServerRoutes.periodicities,
      JSON.stringify(updatedPeriodicity),
    ).then(response => {
      if (response.ok) {
        toastr.success('Success', 'Periodicity has been updated');
        setInterval(() => window.location.reload(), 1000);
      } else {
        toastr.error('An error occurred', 'Periodicity has not been updated');
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.periodicities?.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Operation code</TableCell>
                  <TableCell align="right">Month periodicity</TableCell>
                  <TableCell align="right">Mileage periodicity</TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.periodicities.map(periodicity => (
                  <TableRow key={periodicity.operationCode}>
                    <TableCell component="th" scope="row">
                      {periodicity.operationCode}
                    </TableCell>
                    <TableCell align="right">
                      {this.state.operationCodeOfPeriodicityBeingUpdated !==
                        periodicity.operationCode && periodicity.month}

                      {this.state.operationCodeOfPeriodicityBeingUpdated ===
                      periodicity.operationCode ? (
                        <div>
                          <TextField
                            label="Month periodicity"
                            value={this.state.updatedMonthPeriodicity}
                            name="updatedMonthPeriodicity"
                            onChange={this.handleChange}
                            type="number"
                            style={{marginRight: '20px'}}
                          />
                          <TextField
                            label="Mileage (in Km) periodicity"
                            value={this.state.updatedMileagePeriodicity}
                            name="updatedMileagePeriodicity"
                            onChange={this.handleChange}
                            type="number"
                            style={{marginRight: '20px'}}
                          />

                          <Button
                            type="button"
                            onClick={() => this.updatePeriodicity()}>
                            Update
                          </Button>
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      {this.state.operationCodeOfPeriodicityBeingUpdated !==
                        periodicity.operationCode && periodicity.mileageInKm}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        type="button"
                        onClick={() =>
                          this.startUpdatePeriodicity(
                            periodicity.operationCode,
                            periodicity.month,
                            periodicity.mileageInKm,
                          )
                        }>
                        Update
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        type="button"
                        onClick={() =>
                          this.deletePeriodicity(
                            periodicity.periodicityCode,
                            periodicity.operationCode,
                          )
                        }>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default PeriodicityDashboard;
