import React, {Component} from 'react';

import Dropzone from 'react-dropzone';
import ServerRoutes from '../routing/ServerRoutes';
import VideoHelper from './VideoHelper';
import Button from '@material-ui/core/Button';
import YoutubeHelper from '../youtube/YoutubeHelper';
import Dialog from '@material-ui/core/Dialog';

class AddOperationThumbnails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnails: {
        youtube: VideoHelper.newTranslation(),
        application: VideoHelper.newTranslation(),
      },
      openChannel: false,
      loading: {
        application: VideoHelper.newTranslation(),
        youtube: VideoHelper.newTranslation(),
      },
    };
    this.template = {};
  }

  componentDidMount() {
    const code = this.props.match.params.code.replace(/_/g, '.');

    VideoHelper.setRoute(ServerRoutes.drafts);

    VideoHelper.getTemplate(code).then(template => {
      this.template = template;
      const thumbnails = template.videoLinks
        ? JSON.parse(template.videoLinks)
        : undefined;
      if (thumbnails) {
        Object.keys(thumbnails).map(platform => {
          Object.keys(
            VideoHelper.oldTranslationsToNew(thumbnails[platform]),
          ).map(lang => {
            if (thumbnails[platform][lang].length) {
              thumbnails[platform][lang] =
                thumbnails[platform][lang].replace(
                  'https://youtu.be/',
                  'https://img.youtube.com/vi/',
                ) + '/mqdefault.jpg';
            }
          });
        });
        this.setState({thumbnails: thumbnails});
      }
    });
    this.getCurrentChannel();
  }
  getCurrentChannel() {
    let getCurrentChannelInterval = setInterval(() => {
      if (window.gapi && window.gapi.auth2) {
        window.gapi.load('client:auth2', () => {
          clearInterval(getCurrentChannelInterval);
          YoutubeHelper.getCurrentChannel().then(channelTitle => {
            this.setState({currentChannel: channelTitle});
          });
        });
      }
    }, 1500);
  }

  postVideoCallback = () => {};

  onDrop(acceptedFiles, platform, lang) {
    const file = acceptedFiles[0];
    if (
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpeg'
    ) {
      const name =
        this.props.match.params.code + '_' + platform + '_' + lang + '.jpg';
      const data = new FormData();
      data.append('picture', file, name);
      const videoId = this.state.thumbnails[platform][lang]
        .replace('https://img.youtube.com/vi/', '')
        .replace('/mqdefault.jpg', '');
      this.postVideoCallback = () => {
        let loading = JSON.parse(JSON.stringify(this.state.loading));
        loading[platform][lang] = true;
        this.setState({loading: loading});
        YoutubeHelper.updateThumbnails(data, videoId).then(() => {
          let loading = JSON.parse(JSON.stringify(this.state.loading));
          loading[platform][lang] = false;
          this.setState({loading: loading});
        });
      };
      this.setState({openChannel: true});
    } else {
      console.log('bad file type');
      //DONT FORGET TO Handle error
    }
  }

  render() {
    return (
      <div>
        <h1>Operation thumbnails</h1>
        {Object.keys(this.state.thumbnails).map(platform => (
          <div style={{width: '100%', flexDirection: 'column'}}>
            <h3>{platform}</h3>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              {Object.keys(this.state.thumbnails[platform]).map(lang => {
                return (
                  <div
                    style={{float: 'left', flexDirection: 'column', flex: 1}}>
                    <h5>{lang}</h5>
                    {this.state.thumbnails[platform][lang].length > 0 ? (
                      <div>
                        <img
                          src={this.state.thumbnails[platform][lang]}
                          alt="Operation thumbnails"
                        />
                        {this.state.loading[platform][lang] ? (
                          <div>Upload in progress...</div>
                        ) : (
                          <Dropzone
                            onDrop={files =>
                              this.onDrop(files, platform, lang)
                            }>
                            <div>
                              Drop the operation thumbnails here, or click to
                              search in your file system
                            </div>
                          </Dropzone>
                        )}
                      </div>
                    ) : (
                      <b>You have to upload video before add thumbnail</b>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        <Dialog open={this.state.openChannel}>
          {this.state.currentChannel ? (
            <div>
              <b>
                You will post your video on the channel:{' '}
                {this.state.currentChannel}
              </b>
              <Button
                onClick={() =>
                  YoutubeHelper.connect(() => {
                    this.getCurrentChannel();
                  })
                }>
                Change channel
              </Button>
              <Button
                onClick={() => {
                  this.setState({openChannel: false});
                  this.postVideoCallback();
                }}>
                Continue
              </Button>
            </div>
          ) : (
            <Button
              onClick={() =>
                YoutubeHelper.connect(() => this.postVideoCallback())
              }>
              Connect to youtube
            </Button>
          )}
        </Dialog>
      </div>
    );
  }
}

export default AddOperationThumbnails;
