import ServerRoutes from '../routing/ServerRoutes';

function getUsers(offset, numberToReturn) {
  const endPoint = ServerRoutes.getUsers(offset, numberToReturn);
  const token = window.localStorage.getItem('token');

  return fetch(endPoint, {headers: {'X-Auth-Token': token}}).then(parseJSON);
}

function getBrands() {
  const endPoint = '/api/brands';
  const token = window.localStorage.getItem('token');

  return fetch(endPoint, {headers: {'X-Auth-Token': token}}).then(parseJSON);
}

function getCars(brands) {
  const endPoint =
    '/api/brands/cars?brands=' + brands.map(b => b.name).join('&brands=');
  const token = window.localStorage.getItem('token');

  return fetch(endPoint, {headers: {'X-Auth-Token': token}}).then(parseJSON);
}

function getMotorizations(carName) {
  const endPoint = '/api/motorizations?car=' + carName;
  const token = window.localStorage.getItem('token');

  return fetch(endPoint, {headers: {'X-Auth-Token': token}}).then(parseJSON);
}

function getSeveralCarsMotorizations(carCodes) {
  const endPoint =
    '/api/cars/motorizations?carCodes=' + carCodes.join('&carCodes=');

  const token = window.localStorage.getItem('token');

  return fetch(endPoint, {headers: {'X-Auth-Token': token}}).then(parseJSON);
}

function parseJSON(response) {
  return response.json();
}

const Users = {
  getUsers,
  getBrands,
  getCars,
  getMotorizations,
  getSeveralCarsMotorizations,
};
export default Users;
