import Popper from '@material-ui/core/Popper/Popper';
import Grow from '@material-ui/core/Grow/Grow';
import Paper from '@material-ui/core/Paper/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import React from 'react';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavigationMenu from './NavigationMenu';

export default class AppBarMenu extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({open: !state.open}));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({open: false});
  };

  render() {
    const {open} = this.state;

    return (
      <div style={{display: 'flex'}}>
        <IconButton
          style={{marginLeft: -12, marginRight: 20}}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}>
          <MenuIcon style={{color: 'white'}} />
        </IconButton>

        <Popper
          open={open}
          style={{zIndex: 9}}
          anchorEl={this.anchorEl}
          transition
          disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <ClickAwayListener onClickAway={this.handleClose}>
                <NavigationMenu closeMenu={this.handleClose} />
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}
