import VideoHelper from '../videos/VideoHelper';

export default class Tool {
  constructor(
    code = '',
    frenchName = '',
    englishName = '',
    spanishName = '',
    italianName = '',
    germanName = '',
    polishName = '',
    links = VideoHelper.newTranslation(),
    bitlyLinks = VideoHelper.newTranslation(),
  ) {
    this.code = code;
    this.frenchName = frenchName;
    this.englishName = englishName;
    this.spanishName = spanishName;
    this.italianName = italianName;
    this.germanName = germanName;
    this.polishName = polishName;
    this.links = links;
    this.bitlyLinks = bitlyLinks;
  }
}
